import React, { useContext } from 'react';
import AgentDrawer from '../components/drawer/AgentDrawer';
import ClientDrawer from '../components/drawer/ClientDrawer';
import { LoginContext } from '../context/LoginContext';

const ApellaDrawer = (props) => {
  const loginData = useContext(LoginContext);
  return (
    <div>
      {loginData.agent ? (
        <AgentDrawer audioOnly={props.audioOnly} />
      ) : (
        <ClientDrawer audioOnly={props.audioOnly} />
      )}
    </div>
  );
};
export default ApellaDrawer;
