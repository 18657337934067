import React, { Fragment, useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ApellaContext } from '../context/ApellaContext';
import { LoginContext } from '../context/LoginContext';
import { UrlContext } from '../context/UrlContext';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { sendWhiteboardUrl } from '../helper/getData';
import { useSnackbar } from 'notistack';

import Iframe from 'react-iframe';

const Whiteboard = (props) => {
  const loginData = useContext(LoginContext);
  const urlData = useContext(UrlContext);
  const [openWhiteboardDialog, setopenWhiteboardDialog] = useState(props.showWhiteboardDialog);
  const data = useContext(ApellaContext);
  const [width, setwidth] = useState(0);
  const [left, setleft] = useState(0);
  useEffect(() => {
    if (props.showMenu) {
      setwidth(73);
      setleft(72);
    } else {
      setwidth(0);
      setleft(0);
    }
  }, [props.showMenu]);
  const useStyles = makeStyles((theme) => ({
    content: {
      justifyContent: 'flex-end',
      padding: theme.spacing(3),
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    whiteboard: {
      position: 'absolute',
      top: 64,
      left: left,
      width: `calc(100% - ${width}px)`,
      height: 'calc(100% - 65px)',
    },
    dialogCheck: {
      marginRight: 15,
      marginLeft: 'auto',
    },
  }));
  const classes = useStyles();
  const [dontShowAgainDialoge, setdontShowAgainDialoge] = useState(true);
  const [emailToSend, setemailToSend] = useState(
    loginData.agent ? data.agentData.agentData.email1 : loginData.user.email
  );
  const [emailErrorText, setemailErrorText] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const handleWhiteboardDialog = () => {
    setopenWhiteboardDialog(true);
  };

  const handleSetshowAgainDialoge = (event) => {
    setdontShowAgainDialoge(event.target.checked);
    if (dontShowAgainDialoge) props.setshowWhiteboardDialog(!event.target.checked);
  };

  const sendWhiteboardUrlandCloseDialog = async () => {
    let pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(emailToSend)) {
      setemailErrorText('Bitte geben Sie eine gültige Email ein');
    } else {
      let whiteboardlink = loginData.agent
        ? `${urlData.urlList.whiteboardUrl}?whiteboardid=${data.roomData.roomHash}&username=${data.agentData.agentData.firstName}_${data.agentData.agentData.lastName}`
        : loginData.client
        ? `${urlData.urlList.whiteboardUrl}?whiteboardid=${data.roomData.roomHash}&username=${data.clientData.clientData.firstName}_${data.clientData.clientData.lastName}`
        : `${urlData.urlList.whiteboardUrl}?whiteboardid=${data.roomData.roomHash}&username=${loginData.user.firstName}_${loginData.user.lastName}`;
      try {
        await sendWhiteboardUrl({
          email: emailToSend,
          whiteboardUrl: whiteboardlink,
          companyIdentification: data.companyIdentification,
          stage: data.stage,
          apiToken: data.apiToken,
          agentId: data.agentData.id,
          clientId: data.clientData.id,
          consultationDate: data.roomData.consultationDate,
          urlParam:
            urlData.urlList.apiEndpointBaseurl +
            urlData.urlList.endpoints.sendWhiteboardUrl.endpoint,
        });

        enqueueSnackbar('Eine E-Mail mit dem Whiteboardlink wurde gesendet.', {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar('Der Whiteboardlink konnte nicht verschickt werden', { variant: 'error' });
        console.warn(e);
      }
      if (dontShowAgainDialoge) props.setshowWhiteboardDialog(false);
      setopenWhiteboardDialog(false);
    }
  };

  const saveShowAgainWhiteboardUrlDialogAndClose = () => {
    if (dontShowAgainDialoge) props.handleWhiteboardDialog();
    setopenWhiteboardDialog(false);
  };

  useEffect(() => {
    if (props.showWhiteboardDialog) handleWhiteboardDialog();
  }, []); // eslint-disable-line

  return (
    <ApellaContext.Consumer>
      {(context) => (
        <Fragment>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Dialog
              open={openWhiteboardDialog}
              aria-labelledby="alert-dialog-whiteboard"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-whiteboardTitle">
                {'Whiteboardlink zur Verwendung auf einem externen Gerät'}
              </DialogTitle>
              <DialogContent dividers>
                <DialogContentText id="alert-dialog-description">
                  <Box className={classes.whiteboardLink}>
                    {loginData.agent
                      ? `${urlData.urlList.whiteboardUrl}?whiteboardid=${data.roomData.roomHash}&username=${data.agentData.agentData.firstName}_${data.agentData.agentData.lastName}`
                      : loginData.client
                      ? `${urlData.urlList.whiteboardUrl}?whiteboardid=${data.roomData.roomHash}&username=${data.clientData.clientData.firstName}_${data.clientData.clientData.lastName}`
                      : `${urlData.urlList.whiteboardUrl}?whiteboardid=${data.roomData.roomHash}&username=${loginData.user.firstName}_${loginData.user.lastName}`}
                  </Box>
                </DialogContentText>
              </DialogContent>
              <DialogContent>
                {loginData.agent ? (
                  <TextField
                    required
                    id="whiteboardEmail"
                    label="Email"
                    defaultValue={data.agentData.agentData.email1}
                    onChange={(e) => setemailToSend(e.target.value)}
                    variant="outlined"
                    fullWidth
                    error={emailErrorText}
                    helperText={emailErrorText}
                  />
                ) : (
                  <TextField
                    required
                    id="whiteboardEmail"
                    label="Email"
                    defaultValue={loginData.user.email}
                    onChange={(e) => setemailToSend(e.target.value)}
                    variant="outlined"
                    fullWidth
                    error={emailErrorText}
                    helperText={emailErrorText}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  disableElevation
                  onClick={() => sendWhiteboardUrlandCloseDialog()}
                  variant="contained"
                  color="primary"
                  autoFocus
                >
                  Link per Email zusenden
                </Button>
                <Button
                  disableElevation
                  onClick={() => {
                    saveShowAgainWhiteboardUrlDialogAndClose();
                  }}
                  variant="contained"
                  color="primary"
                  autoFocus
                >
                  Schließen
                </Button>
              </DialogActions>
              <div className={classes.dialogCheck}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={dontShowAgainDialoge}
                      onChange={handleSetshowAgainDialoge}
                      name="showAgainDialoge"
                      color="secondary"
                    />
                  }
                  label="Dialog nicht noch einmal anzeigen"
                  labelPlacement="start"
                ></FormControlLabel>
              </div>
            </Dialog>
            <Iframe
              url={
                loginData.agent
                  ? `${urlData.urlList.whiteboardUrl}?whiteboardid=${data.roomData.roomHash}&username=${data.agentData.agentData.firstName}_${data.agentData.agentData.lastName}`
                  : loginData.client
                  ? `${urlData.urlList.whiteboardUrl}?whiteboardid=${data.roomData.roomHash}&username=${data.clientData.clientData.firstName}_${data.clientData.clientData.lastName}`
                  : `${urlData.urlList.whiteboardUrl}?whiteboardid=${data.roomData.roomHash}&username=${loginData.user.firstName}_${loginData.user.lastName}`
              }
              className={classes.whiteboard}
            ></Iframe>
          </main>
        </Fragment>
      )}
    </ApellaContext.Consumer>
  );
};

export default Whiteboard;
