export const muiTableTextLabels = {
  body: {
    noMatch: 'Keine übereinstimmenden Daten gefunden',
    toolTip: 'Sortieren',
    columnHeaderTooltip: (column) => `Sortierung für ${column.label}`,
  },
  pagination: {
    next: 'Nächste Seite',
    previous: 'Vorherige Seite',
    rowsPerPage: 'Zeilen pro Seite:',
    displayRows: 'von',
  },
  toolbar: {
    search: 'Suchen',
    downloadCsv: 'Download CSV',
    print: 'Drucken',
    viewColumns: 'Auswahl Spalten',
    filterTable: 'Filter Tabelle',
  },
  filter: {
    all: 'Alle',
    title: 'FILTERS',
    reset: 'RESET',
  },
  viewColumns: {
    title: 'Ausgewählte Spalten',
    titleAria: 'Zeige/Verstecke Spalte',
  },
  selectedRows: {
    text: 'Zeile(n) ausgewählt',
    delete: 'Löschen',
    deleteAria: 'Lösche ausgewählte Zeilen',
  },
};
