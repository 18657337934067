import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
} from '@material-ui/core';

const DeleteDocument = (props) => {
  const { deleteDocumentDialog, setdeleteDocumentDialog, handleDelete, documentToDelete } = props;

  return (
    <div>
      <Dialog open={deleteDocumentDialog} onClose={() => setdeleteDocumentDialog(false)}>
        <DialogTitle>Löschen der Datei {documentToDelete.documentName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Wollen Sie die Datei{' '}
            <Typography color="secondary">{documentToDelete.documentName}</Typography> wirklich
            löschen?
          </DialogContentText>
          <DialogActions>
            <Button
              color="secondary"
              autoFocus
              onClick={() => setdeleteDocumentDialog(false)}
              variant="outlined"
            >
              Abbrechen
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleDelete(documentToDelete.documentIndex);
                setdeleteDocumentDialog(false);
              }}
            >
              OK
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DeleteDocument;
