import React, { useEffect, useContext, useReducer, useRef } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';

import Divider from '@material-ui/core/Divider';
import { ApellaContext } from '../../context/ApellaContext';
import { LoginContext } from '../../context/LoginContext';
import { Fragment } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TextField from '@material-ui/core/TextField';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Typography from '@material-ui/core/Typography';

const initialState = {
  chatmessageInput: '',
};
const reducer = (state, action) => {
  if (action.type === 'reset') {
    return initialState;
  }

  const result = { ...state };
  result[action.type] = action.value;
  return result;
};

const buildTime = (timestamp) => {
  let hours = '';
  let minutes = '';
  if (timestamp !== undefined && timestamp !== '' && timestamp !== null) {
    if (timestamp.getHours() + 1 < 10) {
      hours = '0' + timestamp.getHours();
    } else {
      hours = timestamp.getHours();
    }
    if (timestamp.getMinutes() < 10) {
      minutes = '0' + timestamp.getMinutes();
    } else {
      minutes = timestamp.getMinutes();
    }
    return `${hours}:${minutes}`;
  } else {
    return null;
  }
};

const ChatDrawer = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { chatmessageInput } = state; // eslint-disable-line
  const data = useContext(ApellaContext);
  const loginData = useContext(LoginContext);
  const theme = useTheme();
  let drawerWidth = props.chatDrawerwidth;
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    inputRef.current.focus();
    scrollToBottom();
  }, [props.openChatDrawer, props.websocketmessages]);

  const useStyles = makeStyles((theme) => ({
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      display: 'flex',
      width: drawerWidth,
      justifyContent: 'flex-start',
    },

    drawerHeader: {
      top: 0,
      position: 'sticky',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-start',
      backgroundColor: '#fff',
    },
    chatContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    bubble_left: {
      zIndex: -1,
      width: '78%',
      margin: '20px 10px',
      backgroundColor: theme.palette.primary.main,
      padding: '10px 20px 10px 20px',
      textAlign: 'left',
      color: '#fff',
      position: 'relative',
      borderRadius: '30px 0px 30px 0px',
      '&:before': {
        content: "''",
        width: '0px',
        height: '0px',
        position: 'absolute',
        borderColor: theme.palette.primary.main,
        borderLeft: '10px solid ',
        borderRight: '10px solid transparent',
        borderTop: '10px solid ',
        borderBottom: '10px solid transparent',
        right: '-20px',
        top: '10px',
      },
    },
    bubble_right: {
      zIndex: -1,
      width: '78%',
      margin: '20px 10px',
      backgroundColor: theme.palette.secondary.main,
      padding: '10px 20px 10px 20px',
      textAlign: 'left',
      color: '#fff',
      position: 'relative',
      borderRadius: '0px 30px 0px 30px',
      '&:before': {
        content: "''",
        width: '0px',
        height: '0px',
        position: 'absolute',
        borderColor: theme.palette.secondary.main,
        borderLeft: '10px solid transparent',
        borderRight: '10px solid',
        borderTop: '10px solid ',
        borderBottom: '10px solid transparent',
        left: '-20px',
        top: '15px',
        transform: 'rotate(90deg)',
      },
    },
    avatar_right: {
      zIndex: -1,
      marginRight: 10,
      marginLeft: 'auto',
    },
    avatar_left: {
      zIndex: -1,
      marginLeft: 10,
      marginRight: 'auto',
    },
    time_right: {
      zIndex: -1,
      color: '#eee',
      textAlign: 'right',
      /* textShadow: '2px 2px 7px #000000', */
      fontSize: 'x-small',
    },
    time_left: {
      zIndex: -1,
      color: '#eee',
      textAlign: 'left',
      /*  textShadow: '2px 2px 7px #000000', */
      fontSize: 'x-small',
    },
    chatInput: { width: '80%', marginLeft: 10 },
    chatLogo: {
      marginLeft: 10,
      marginRight: 10,
    },
    chatInputContainer: {
      marginTop: 'auto',
      bottom: 10,
      position: 'sticky',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#fff',
    },
    chatmessageContainer: {
      paddingBottom: 30,
    },
  }));
  const classes = useStyles();

  const handleSendMessage = (e) => {
    props.sendChatmessage(state.chatmessageInput);
    dispatch({ type: 'reset' });
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: name, value });
  };

  const akronym = (firstName, lastName) => {
    let firstLetter = firstName.substr(0, 1);
    let lastLetter = lastName.substr(0, 1);
    return firstLetter + lastLetter;
  };

  return (
    <Fragment>
      <CssBaseline />
      <Drawer
        open={props.openChatDrawer}
        anchor="right"
        variant="persistent"
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={props.handleChatDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          <Typography variant={'h6'} className={classes.chatLogo}>
            Chat
          </Typography>
        </div>
        <Divider />

        <div className={classes.chatmessageContainer}>
          {props.websocketmessages.map((message) => (
            <Fragment>
              {loginData.user === undefined && !loginData.agent ? (
                <div className={classes.chatContainer}>
                  <div className={classes.bubble_left}>
                    {message.message}
                    <div className={classes.time_left}>
                      {buildTime(new Date(message.user.timestamp))} Uhr
                    </div>
                  </div>

                  {message.user !== '' &&
                  message.user !== undefined &&
                  message.user.firstName !== '' &&
                  message.user.firstName !== undefined &&
                  message.user.lastName !== '' &&
                  message.user.lastName !== undefined ? (
                    <Avatar src={`${message.user.photo}`} className={classes.avatar_right}>
                      {akronym(message.user.firstName, message.user.lastName)}
                    </Avatar>
                  ) : null}
                </div>
              ) : loginData.agent &&
                data.agentData.agentData.firstName === message.user.firstName &&
                data.agentData.agentData.lastName === message.user.lastName ? (
                <div className={classes.chatContainer}>
                  <div className={classes.bubble_left}>
                    {message.message}
                    <div className={classes.time_left}>
                      {buildTime(new Date(message.user.timestamp))} Uhr
                    </div>
                  </div>

                  {message.user !== '' &&
                  message.user !== undefined &&
                  message.user.firstName !== '' &&
                  message.user.firstName !== undefined &&
                  message.user.lastName !== '' &&
                  message.user.lastName !== undefined ? (
                    <Avatar src={`${message.user.photo}`} className={classes.avatar_right}>
                      {akronym(message.user.firstName, message.user.lastName)}
                    </Avatar>
                  ) : null}
                </div>
              ) : !loginData.agent &&
                loginData.user.firstName === message.user.firstName &&
                loginData.user.lastName === message.user.lastName ? (
                <div className={classes.chatContainer}>
                  <div className={classes.bubble_left}>
                    {message.message}
                    <div className={classes.time_left}>
                      {buildTime(new Date(message.user.timestamp))} Uhr
                    </div>
                  </div>
                  {message.user !== '' &&
                  message.user !== undefined &&
                  message.user.firstName !== '' &&
                  message.user.firstName !== undefined &&
                  message.user.lastName !== '' &&
                  message.user.lastName !== undefined ? (
                    <Avatar src={`${message.user.photo}`} className={classes.avatar_right}>
                      {akronym(message.user.firstName, message.user.lastName)}
                    </Avatar>
                  ) : null}
                </div>
              ) : (
                <div className={classes.chatContainer}>
                  {message.user !== '' &&
                  message.user !== undefined &&
                  message.user.firstName !== '' &&
                  message.user.firstName !== undefined &&
                  message.user.lastName !== '' &&
                  message.user.lastName !== undefined ? (
                    <Avatar src={`${message.user.photo}`} className={classes.avatar_left}>
                      {akronym(message.user.firstName, message.user.lastName)}
                    </Avatar>
                  ) : null}

                  <div className={classes.bubble_right}>
                    <Typography>{message.message}</Typography>
                    <Typography className={classes.time_right}>
                      {buildTime(new Date(message.user.timestamp))} Uhr
                    </Typography>
                  </div>
                </div>
              )}
            </Fragment>
          ))}
        </div>
        <div ref={messagesEndRef} />
        <div className={classes.chatInputContainer}>
          <TextField
            size="small"
            variant="outlined"
            className={classes.chatInput}
            /* multiline */
            name="chatmessageInput"
            value={state.chatmessageInput}
            onChange={onChange}
            placeholder="..."
            id="chatInput"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSendMessage();
              }
            }}
            ref={inputRef}
            autoFocus="true"
          ></TextField>
          <IconButton color="secondary" onClick={handleSendMessage} type="submit">
            <FontAwesomeIcon icon={faPaperPlane}></FontAwesomeIcon>
          </IconButton>
        </div>
      </Drawer>
    </Fragment>
  );
};

export default ChatDrawer;
