import React, { Fragment, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ApellaContext } from '../context/ApellaContext';
import { LoginContext } from '../context/LoginContext';
import { UrlContext } from '../context/UrlContext';
import { StylingContext } from '../context/StylingContext';
import { LoggingContext } from '../context/LoggingContext';
import FileTable from './FileTable';
import DropzoneButton from './DropzoneButton';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { sendDocumentsInformation } from '../helper/getData';
import { saveRoom } from '../helper/saveRoom';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Spinner from 'react-spinkit';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  buton: {
    marginLeft: 10,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
const Unterschrift = (props) => {
  const classes = useStyles();
  const stylingData = useContext(StylingContext);
  const data = useContext(ApellaContext);
  const loginContext = useContext(LoginContext);
  const loggingData = useContext(LoggingContext);
  const urlData = useContext(UrlContext);
  const [openSign, setopenSign] = useState(false);
  const [insignUrl, setinsignUrl] = useState();
  const [cmd, setcmd] = useState();
  const [backdropOpen, setbackdropOpen] = useState(false);

  const handleSave = () => {
    saveRoom(
      data.apiToken,
      data.agentData,
      data.clientData,
      data.roomData,
      data.documents,
      data.url,
      data.insignData,
      data.activeStep,
      data.otpKey,
      data.companyIdentification,
      data.stage,
      data.contextID,
      data.agentConnectionId,
      data.experts,
      data.agendaText,
      stylingData.colors,
      urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.updateRoom.endpoint
    );
  };

  const handleClose = () => {
    setbackdropOpen(false);
    /* contextData.settoggleBackdrop(false); */
  };
  const handleToggle = () => {
    setbackdropOpen(true);
  };

  const handleSign = async (cmd) => {
    handleToggle();
    handleSave();
    if (cmd === 'sign') {
      if (!props.screenshare) props.setscreenshare(true);
      setTimeout(() => {
        props.setscreenshare(false);
      }, 1000);
      if (props.browserVersion !== 'Chrome') {
        props.setopenScreenshareDialogue(true);
      }
      setcmd('Unterschriftenprozess');
      props.send(JSON.stringify({ command: '412c1249-75d3-4d21-96bf-27c5ca272bac' }));
      /* if (!props.openScreenshareDialogue) */ //setopenSign(true);
    } else {
      setcmd('Vorgangsverwaltung');
      loggingData.setSequence({ type: 'transactionManagement' });
    }
    let documentsToSign = data.documents.filter((doc) => doc.toSign === true);
    let documentIds = [];
    documentsToSign.forEach((doc) => {
      if (cmd === 'sign') {
        loggingData.setSequence({ type: 'signDocument', fileName: doc.documentName });
      }
      documentIds.push(doc.documentPath);
    });
    let insignrequestData = await sendDocumentsInformation({
      cmd: cmd,
      agentId: data.agentData.id,
      clientId: data.clientData.id,
      consultationDate: data.roomData.consultationDate,
      documentIds: documentIds,
      agentConnectionId: data.agentConnectionId,
      companyIdentification: data.companyIdentification,
      stage: data.stage,
      apiToken: data.apiToken,
      urlParam: urlData.urlList.insignUrl,
    });
    setinsignUrl(await insignrequestData.url);
    handleClose();
    setopenSign(true);
    //TODO screensharedialog muss geschlossen sein
  };

  const handleSignprocess = () => {
    window.open(insignUrl, '_blank');
    closeSignDialog();
  };

  const closeSignDialog = () => {
    setopenSign(false);
  };

  return (
    <ApellaContext.Consumer>
      {(context) => (
        <Fragment>
          <Backdrop className={classes.backdrop} open={backdropOpen}>
            <Spinner name="line-scale" color={stylingData.colors.color3} />
          </Backdrop>
          <Dialog open={openSign} onClose={closeSignDialog} aria-labelledby="form-sign-dialog">
            <DialogTitle>{cmd} in neuem Tab öffnen</DialogTitle>

            <DialogActions>
              <Button
                disableElevation
                variant="outlined"
                color="secondary"
                onClick={() => {
                  loggingData.setSequence({ type: 'cancelSign' });
                  closeSignDialog();
                }}
              >
                Abbrechen
              </Button>
              <Button
                disableElevation
                onClick={handleSignprocess}
                variant="contained"
                color="primary"
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Grid container>
              <Grid item xl={12} lg={12} md={12}>
                <Typography>
                  Unterschriebene Dokumente werden automatisch ins MSC übertragen!
                </Typography>
              </Grid>
              <Grid item xl={12} lg={12} md={12}>
                <FileTable send={props.send} origin={1} />
              </Grid>
              <Grid container alignItems="center">
                <Grid item>
                  <DropzoneButton send={props.send} />
                </Grid>
                {loginContext.agent ? (
                  <Grid item>
                    <Button
                      disableElevation
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleSign('sign');
                      }}
                    >
                      Dokumente unterschreiben
                    </Button>
                    <Button
                      className={classes.buton}
                      disableElevation
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleSign('sessions');
                      }}
                    >
                      Vorgangsverwaltung
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </main>
        </Fragment>
      )}
    </ApellaContext.Consumer>
  );
};

export default Unterschrift;
