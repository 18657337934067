import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react';
import MUIRichTextEditor from 'mui-rte';
import { convertToHTML } from 'draft-convert';
import { convertFromHTML, ContentState, convertToRaw, convertFromRaw } from 'draft-js';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { ApellaContext } from '../../context/ApellaContext';
import { UrlContext } from '../../context/UrlContext';
import { saveAgendaText } from '../../helper/getData';
import { StylingContext } from '../../context/StylingContext';
import { LoggingContext } from '../../context/LoggingContext';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import Spinner from 'react-spinkit';

const defaultTheme = createMuiTheme();

Object.assign(defaultTheme, {
  overrides: {
    MUIRichTextEditor: {
      root: {
        border: '1px solid #ddd',
      },
      editorContainer: {
        minHeight: '250px',
        overflow: 'auto',
      },
      editor: {
        minHeight: '250px',
        padding: defaultTheme.spacing(1),
        overflow: 'auto',
      },
    },
  },
});

export default function Agenda() {
  const data = useContext(ApellaContext);
  const urlData = useContext(UrlContext);
  const stylingData = useContext(StylingContext);
  const loggingData = useContext(LoggingContext);
  const { enqueueSnackbar } = useSnackbar();
  const editorRef = useRef();
  const [backdropOpen, setbackdropOpen] = useState(false);

  const agendaText = useMemo(() => {
    try {
      const contentHTML = convertFromHTML(data.agendaText);
      const state = ContentState.createFromBlockArray(
        contentHTML.contentBlocks,
        contentHTML.entityMap
      );
      return JSON.stringify(convertToRaw(state));
    } catch (e) {
      console.warn(e);
      return null;
    }
  }, [data.clientData.id, data.agentData.id, data.roomData.consultationDate]); // eslint-disable-line

  const save = (editorText) => {
    setbackdropOpen(true);
    if (data.clientData.id && data.agentData.id && data.roomData.consultationDate) {
      const convertedHTML = convertToHTML({})(convertFromRaw(JSON.parse(editorText)));
      if (convertedHTML !== data.agendaText) {
        loggingData.setSequence({ type: 'agenda', text: convertedHTML });
        if (!data.saveVariable) {
          data.setsaveVariable(true);
          saveAgendaText({
            agentId: data.agentData.id,
            clientId: data.clientData.id,
            consultationDate: data.roomData.consultationDate,
            agendaText: convertedHTML,
            apiToken: data.apiToken,
            urlParam:
              urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.updateAgenda.endpoint,
          })
            .then(() => {
              data.setAgendaText(convertedHTML);
              // enqueueSnackbar("Der Beschreibungstext wurde gespeichert.", {variant: "success"});
            })
            .then(() => data.setsaveVariable(false))
            .catch((e) => {
              console.warn(e);
              enqueueSnackbar('Der Beschreibungstext konnte nicht gespeichert werden.', {
                variant: 'error',
              });
            });
        }
      } else {
      }
    }
    setbackdropOpen(false);
  };

  useEffect(() => {
    if (data.agentData.id && data.clientData.id && data.roomData.consultationDate) {
      return () => {
        // cleanup-funktion wird beim unmounten ausgeführt (ergo: beim verlassen des Agenda-Tabs)
        editorRef.current.save(); // eslint-disable-line
      };
    }
  }, [data.clientData.id, data.agentData.id, data.roomData.consultationDate]);

  return (
    <Fragment>
      <div id="test">
        <Backdrop open={backdropOpen}>
          <Spinner name="line-scale" color={stylingData.colors.color3} />
        </Backdrop>
        <Typography>
          <p>
            Erfassen Sie hier einen Einleitungstext, beispielsweise eine Agenda oder weitere
            Informationen. Dieser Text wird allen Teilnehmern im Startbildschirm angezeigt.
          </p>
        </Typography>
        <MuiThemeProvider theme={defaultTheme}>
          <MUIRichTextEditor
            ref={editorRef}
            defaultValue={agendaText}
            onSave={save}
            controls={[
              'title',
              'bold',
              'italic',
              'underline',
              'strikethrough',
              'highlight',
              'undo',
              'redo',
              'numberList',
              'bulletList',
            ]}
          />
          {/* <Button
            onClick={() => {
              handleScreenshot();
            }}
          >
            Screenshottest
          </Button> */}
        </MuiThemeProvider>
      </div>
    </Fragment>
  );
}
