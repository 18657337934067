import React, { Fragment, useContext, useState, useEffect, useReducer } from 'react';
import { ApellaContext } from '../context/ApellaContext';
import { LoginContext } from '../context/LoginContext';
import { UrlContext } from '../context/UrlContext';
import { StylingContext } from '../context/StylingContext';
import DeleteDocument from './dialogues/DeleteDocument';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Backdrop from '@material-ui/core/Backdrop';
import Spinner from 'react-spinkit';
import MUIDataTable from 'mui-datatables';
import { muiTableTextLabels } from '../translation/Translation';
import { Checkbox } from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function reducer(state, action) {
  return { count: state.count + 1 };
}

const FileTable = (props) => {
  const fileTypes = [
    { biproKategorie: 65537, dokArt: 'Maklervertrag' },
    { biproKategorie: 65538, dokArt: 'Maklervollmacht' },
    { biproKategorie: 65539, dokArt: 'Erstinformation' },
    { biproKategorie: 65540, dokArt: 'Datenschutzeinwilligung' },
    { biproKategorie: 65541, dokArt: 'Personalausweis' },
    { biproKategorie: 65542, dokArt: 'Reisepass' },
    { biproKategorie: 65543, dokArt: 'Führerschein' },
    { biproKategorie: 65544, dokArt: 'Vorsorgevollmacht' },
    { biproKategorie: 65545, dokArt: 'Betreuungsvollmacht' },
    { biproKategorie: 65546, dokArt: 'Patientenverfügung' },
    { biproKategorie: 65547, dokArt: 'Sonstiges Dokument' },
    { biproKategorie: 65590, dokArt: 'Bestandsübertragung' },
    { biproKategorie: 65591, dokArt: 'Fremdvertrag' },
    { biproKategorie: 65592, dokArt: 'Antrag/Deckungsnote' },
    { biproKategorie: 65593, dokArt: 'Allgemeines Dokument' },
  ];

  var existingFilePosition = [];
  var existingFilePositionToUploadToMSC = [];
  var existingClientFilePosition = [];
  const [state, dispatch] = useReducer(reducer, { count: 0 }); // eslint-disable-line
  const [backdropOpen, setbackdropOpen] = useState(false);
  const classes = useStyles();
  const contextData = useContext(ApellaContext);
  const loginContext = useContext(LoginContext);
  const urlData = useContext(UrlContext);
  const stylingData = useContext(StylingContext);
  const [deleteDocumentDialog, setdeleteDocumentDialog] = useState(false);
  const [documentToDelete, setdocumentToDelete] = useState(null);
  const handleChangeDocumentType = (id, event) => {
    contextData.documents[id].biproKategorie = event.target.value;
    updateDocuments();
  };

  const handleClose = () => {
    setbackdropOpen(false);
  };

  const getDownload = async (fileObjName, documentName) => {
    try {
      let response = await fetch(
        urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.downloadFromS3.endpoint,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application.json',
            Authorization: `Bearer ${contextData.apiToken}`,
          },
          body: JSON.stringify({
            agentId: contextData.agentData.id,
            clientId: contextData.clientData.id,
            consultationDate: contextData.roomData.consultationDate,
            document: `${contextData.roomData.roomHash}/${fileObjName}`,
            documentName: documentName,
          }),
        }
      );
      if (response.status === 200) {
        let json = await response.json();
        return json;
      }
    } catch (error) {
      console.log(`Übertragungsfehler: ${error}`);
    }
  };

  const updateDocuments = async () => {
    setbackdropOpen(true);
    try {
      let response = await fetch(
        urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.updateDocuments.endpoint,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application.json',
            Authorization: `Bearer ${contextData.apiToken}`,
          },
          body: JSON.stringify({
            agentId: contextData.agentData.id,
            clientId: contextData.clientData.id,
            consultationDate: contextData.roomData.consultationDate,
            documents: contextData.documents,
          }),
        }
      );

      if (response.status === 200) {
        return await response.json();
      }
    } catch (error) {
      console.log(`Übertragungsfehler: ${error}`);
    } finally {
      setbackdropOpen(false);
    }
  };

  const handleDownload = (index) => {
    getDownload(
      contextData.documents[index].documentPath,
      contextData.documents[index].documentName
    ).then((contextData) => {
      const url = contextData.url;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  useEffect(() => {
    setbackdropOpen(contextData.toggleBackdrop);
  }, [contextData.toggleBackdrop]);

  useEffect(() => {
    let mounted = true;

    if (!contextData.fileUpload || contextData.fileUpload.length === 0) {
      fetch(urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.listFiles.endpoint, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application.json',
          Authorization: `Bearer ${contextData.apiToken}`,
        },
        body: JSON.stringify({
          agentId: contextData.agentData.id,
          clientId: contextData.clientData.id,
          consultationDate: contextData.roomData.consultationDate,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          if (mounted) {
            contextData.setDocuments(json.Item.documents);
          }
        })
        .finally(() => {
          if (mounted) handleClose();
        });
    }

    return () => {
      mounted = false;
    }; /* eslint-disable */
  }, [
    urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.listFiles.endpoint,
    contextData.apiToken,
    contextData.agentData.id,
    contextData.clientData.id,
    contextData.roomData.consultationDate,
    contextData.fileUpload,
    contextData.forceUpdate,
    contextData.forceUpdateTableView,
  ]);

  contextData.documents.forEach((document, index) => {
    if (!document.delete) {
      existingFilePosition.push(index);
    }
    if (!document.delete && document.visibility) {
      existingClientFilePosition.push(index);
    }
  });

  contextData.documents.forEach((document, index) => {
    if (!document.delete && !document.signed) {
      existingFilePositionToUploadToMSC.push(index);
    }
  });

  const handleDelete = (index) => {
    contextData.documents[index].delete = true;
    updateDocuments();
    dispatch();
    if (props.send)
      props.send(
        loginContext.agent
          ? JSON.stringify({ command: '4372079c-59f2-4b0e-8de2-a199d964e9d7' })
          : JSON.stringify({ command: 'ad50f2cf-4e3f-48a3-adea-487d6685b30f' })
      );
  };

  const handleVisibility = (index) => {
    contextData.documents[index].visibility = !contextData.documents[index].visibility;
    updateDocuments();
    dispatch();
    if (props.send)
      props.send(
        loginContext.agent
          ? JSON.stringify({ command: '4372079c-59f2-4b0e-8de2-a199d964e9d7' })
          : JSON.stringify({ command: 'ad50f2cf-4e3f-48a3-adea-487d6685b30f' })
      );
  };

  const handleSign = (index) => {
    contextData.documents[index].toSign = !contextData.documents[index].toSign;
    contextData.documents[index].visibility = !contextData.documents[index].visibility;

    updateDocuments();
    dispatch();
    if (props.send)
      props.send(
        loginContext.agent
          ? JSON.stringify({ command: '4372079c-59f2-4b0e-8de2-a199d964e9d7' })
          : JSON.stringify({ command: 'ad50f2cf-4e3f-48a3-adea-487d6685b30f' })
      );
  };

  const handleUploadToMSC = (index) => {
    contextData.documents[index].toUploadToMSC = !contextData.documents[index].toUploadToMSC;

    updateDocuments();
    dispatch();
  };

  const clientcolums = [
    {
      name: 'dateiname',
      label: 'Dateiname',
    },
    {
      name: 'documentType',
      label: 'Dokumentenart',
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          let dok = fileTypes.find(
            (dok) =>
              dok.biproKategorie ===
              contextData.documents[existingClientFilePosition[dataIndex]].biproKategorie
          );
          if (dok) {
            return dok.dokArt;
          } else return 'Allgemeines Dokument';
          /* return dok.dokArt; */
        },
      },
    },
    {
      name: 'signedDokument',
      label: 'unterschrieben',
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          return contextData.documents[existingClientFilePosition[dataIndex]].signed ? (
            <CheckCircleOutlineIcon color="primary" />
          ) : (
            <BlockIcon color="secondary" />
          );
        },
      },
    },
  ];

  let agentcolumns;
  if (props.askforupload) {
    agentcolumns = [
      {
        name: 'dateiname',
        label: 'Dateiname',
      },
      {
        name: 'documentType',
        label: 'Dokumentenart',
        options: {
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="documentType">Dokumentenart</InputLabel>
                <Select
                  native
                  value={
                    contextData.documents[existingFilePositionToUploadToMSC[dataIndex]]
                      .biproKategorie
                  }
                  onChange={(e) =>
                    handleChangeDocumentType(existingFilePositionToUploadToMSC[dataIndex], e)
                  }
                >
                  <option aria-label="Keine Kategorie" value="" />
                  {fileTypes.map((fileType, index) => {
                    return (
                      <option key={'fileType-' + index} value={fileType.biproKategorie}>
                        {fileType.dokArt}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            );
          },
        },
      },
      {
        name: 'signedDokument',
        label: 'unterschrieben',
        options: {
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return contextData.documents[existingFilePositionToUploadToMSC[dataIndex]].signed ? (
              <CheckCircleOutlineIcon color="primary" />
            ) : (
              <BlockIcon color="secondary" />
            );
          },
        },
      },
      {
        name: 'uploadToMSC',
        label: 'upload ins MSC',
        options: {
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return contextData.documents[existingFilePositionToUploadToMSC[dataIndex]].signed ||
              (contextData.documents[existingFilePositionToUploadToMSC[dataIndex]].toUploadToMSC &&
                contextData.documents[existingFilePositionToUploadToMSC[dataIndex]]
                  .uploadedToMSC) ? (
              <Checkbox defaultChecked disabled></Checkbox>
            ) : (
              <Checkbox
                checked={
                  contextData.documents[existingFilePositionToUploadToMSC[dataIndex]].toUploadToMSC
                }
                onChange={() => {
                  handleUploadToMSC(existingFilePositionToUploadToMSC[dataIndex]);
                }}
              ></Checkbox>
            );
          },
        },
      },
      {
        name: 'Löschen',
        options: {
          width: 20,
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <IconButton
                aria-label="actions"
                onClick={() => {
                  setdeleteDocumentDialog(true);
                  setdocumentToDelete({
                    documentName:
                      contextData.documents[existingFilePositionToUploadToMSC[dataIndex]]
                        .documentName,
                    documentIndex: existingFilePositionToUploadToMSC[dataIndex],
                  });
                }}
                color="secondary"
              >
                <DeleteForeverIcon />
              </IconButton>
            );
          },
        },
      },
    ];
  } else {
    agentcolumns = [
      {
        name: 'dateiname',
        label: 'Dateiname',
      },
      {
        name: 'sichtbarkeit',
        label: 'sichtbar für den Kunden',
        options: {
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <Checkbox
                checked={contextData.documents[existingFilePosition[dataIndex]].visibility}
                onChange={() =>
                  handleVisibility(
                    existingFilePosition[dataIndex],
                    props.send
                      ? props.send(
                          JSON.stringify({ command: '4372079c-59f2-4b0e-8de2-a199d964e9d7' })
                        )
                      : null
                  )
                }
              />
            );
          },
        },
      },
      {
        name: 'documentType',
        label: 'Dokumentenart',
        options: {
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="documentType">Dokumentenart</InputLabel>
                <Select
                  native
                  value={contextData.documents[existingFilePosition[dataIndex]].biproKategorie}
                  onChange={(e) => handleChangeDocumentType(existingFilePosition[dataIndex], e)}
                >
                  <option aria-label="Keine Kategorie" value="" />
                  {fileTypes.map((fileType, index) => {
                    return (
                      <option key={'fileType-' + index} value={fileType.biproKategorie}>
                        {fileType.dokArt}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            );
          },
        },
      },

      {
        name: 'insignDokument',
        label: 'zu unterschreiben',
        options: {
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <Checkbox
                checked={contextData.documents[existingFilePosition[dataIndex]].toSign}
                onChange={() =>
                  handleSign(
                    existingFilePosition[dataIndex],
                    props.send
                      ? props.send(
                          JSON.stringify({ command: '4372079c-59f2-4b0e-8de2-a199d964e9d7' })
                        )
                      : null
                  )
                }
              />
            );
          },
        },
      },
      {
        name: 'signedDokument',
        label: 'unterschrieben',
        options: {
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return contextData.documents[existingFilePosition[dataIndex]].signed ? (
              <CheckCircleOutlineIcon color="primary" />
            ) : (
              <BlockIcon color="secondary" />
            );
          },
        },
      },
      {
        name: 'uploadToMSC',
        label: 'upload ins MSC',
        options: {
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return contextData.documents[existingFilePosition[dataIndex]].signed ||
              (contextData.documents[existingFilePosition[dataIndex]].toUploadToMSC &&
                contextData.documents[existingFilePosition[dataIndex]].uploadedToMSC) ? (
              <Checkbox defaultChecked disabled></Checkbox>
            ) : (
              <Checkbox
                checked={contextData.documents[existingFilePosition[dataIndex]].toUploadToMSC}
                onChange={() => {
                  handleUploadToMSC(existingFilePosition[dataIndex]);
                }}
              ></Checkbox>
            );
          },
        },
      },
      {
        name: 'Löschen',
        options: {
          width: 20,
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <IconButton
                aria-label="actions"
                onClick={() => {
                  setdeleteDocumentDialog(true);
                  setdocumentToDelete({
                    documentName:
                      contextData.documents[existingFilePosition[dataIndex]].documentName,
                    documentIndex: existingFilePosition[dataIndex],
                  });
                }}
                color="secondary"
              >
                <DeleteForeverIcon />
              </IconButton>
            );
          },
        },
      },
    ];
  }

  const options = {
    rowsPerPage: 20,
    rowsPerPageOptions: [5, 10, 20],
    filterType: 'textField',
    caseSensitive: false,
    textLabels: muiTableTextLabels,
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    searchable: false,
    selectableRows: false,
    selectableRowsOnClick: false,
    responsive: 'vertical',
  };

  const tableData =
    loginContext.agent && props.askforupload
      ? contextData.documents
          .filter((document) => !document.delete && !document.signed)
          .map((document, index) => [
            <Link
              href="#"
              onClick={() => handleDownload(existingFilePositionToUploadToMSC[index])}
              underline="hover"
            >
              {document.documentName}
            </Link>,
          ])
      : loginContext.agent
      ? contextData.documents
          .filter((document) => !document.delete)
          .map((document, index) => [
            <Link
              href="#"
              onClick={() => handleDownload(existingFilePosition[index])}
              underline="hover"
            >
              {document.documentName}
            </Link>,
          ])
      : contextData.documents
          .filter((document) => !document.delete && document.visibility)
          .map((document, index) => [
            <Link
              href="#"
              onClick={() => handleDownload(existingClientFilePosition[index])}
              underline="hover"
            >
              {document.documentName}
            </Link>,
          ]);

  return (
    <Fragment>
      {props.origin === 1 ? (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Backdrop className={classes.backdrop} open={backdropOpen}>
            <Spinner name="line-scale" color={stylingData.colors.color3} />
          </Backdrop>
          <Grid container>
            <Grid item xl={12} lg={12} md={12}>
              <MUIDataTable
                title={'Liste der Dokumente'}
                elevation={1}
                data={tableData}
                columns={loginContext.agent ? agentcolumns : clientcolums}
                options={options}
              />
            </Grid>
          </Grid>
        </main>
      ) : (
        <div>
          <Backdrop className={classes.backdrop} open={backdropOpen}>
            <Spinner name="line-scale" color={stylingData.colors.color3} />
          </Backdrop>
          <Grid container>
            <Grid item xl={12} lg={12} md={12}>
              <MUIDataTable
                title={'Liste der Dokumente'}
                data={tableData}
                columns={loginContext.agent ? agentcolumns : clientcolums}
                options={options}
              />
            </Grid>
          </Grid>
        </div>
      )}
      {documentToDelete !== null && documentToDelete !== undefined ? (
        <DeleteDocument
          deleteDocumentDialog={deleteDocumentDialog}
          setdeleteDocumentDialog={setdeleteDocumentDialog}
          handleDelete={handleDelete}
          documentToDelete={documentToDelete}
        />
      ) : null}
    </Fragment>
  );
};

//export default React.memo(FileTable);
export default FileTable;
