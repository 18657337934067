export const saveRoom = async (
  apiToken,
  agentData,
  clientData,
  roomData,
  documents,
  url,
  insignData,
  activeStep,
  otpKey,
  companyIdentification,
  stage,
  contextID,
  agentConnectionId,
  experts,
  agendaText,
  colors,
  URL
) => {
  return fetch(URL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${apiToken}` },
    body: JSON.stringify({
      agentData: agentData,
      clientData: clientData,
      consultationDate: roomData.consultationDate,
      roomData: roomData,
      documents: documents,
      url: url,
      insignData: insignData,
      activeStep: activeStep,
      otpKey: otpKey,
      companyIdentification: companyIdentification,
      stage: stage,
      contextID: contextID,
      agentConnectionId: agentConnectionId,
      experts: experts,
      agendaText: agendaText,
      colors: colors,
    }),
  });
};
