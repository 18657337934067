import React, { useContext, useState, useEffect, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import { ApellaContext } from '../../context/ApellaContext';
import { StylingContext } from '../../context/StylingContext';
import { UrlContext } from '../../context/UrlContext';
import { LoggingContext } from '../../context/LoggingContext';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { MailOutline, Phone } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import AgentDrawer from '../drawer/AgentDrawer';
import { sendEmail } from '../../helper/getData';
import { saveRoom } from '../../helper/saveRoom';
import Backdrop from '@material-ui/core/Backdrop';
import Spinner from 'react-spinkit';

const useStyles = makeStyles((theme) => ({
  containerInPaper: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  avatar: {
    height: '140px',
    width: '140px',
    fontSize: '60px',
  },
  avatarWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    marginLeft: 10,
  },
  expertInputfiled: {
    margin: 5,
  },
  keyvanceCard: {
    display: 'flex',
    width: '80%',
    alignItems: 'center',
    marginBottom: '10%',
  },
  keyvanceLogo: {
    flex: 1,
    margin: 10,
    width: '80%',
  },
  button: { width: '100%' },
}));

export default function UserPanel({ onNewRoom }) {
  const { enqueueSnackbar } = useSnackbar();

  const data = useContext(ApellaContext);
  const urlData = useContext(UrlContext);
  const stylingData = useContext(StylingContext);
  const loggingData = useContext(LoggingContext);
  const classes = useStyles();
  const consultationTitel = 'Ad hoc Beratung';
  const selectedTimeAndDate = new Date();
  const [responseState, setresponseState] = useState(false);
  const [backdropOpen, setbackdropOpen] = useState(false);

  const theTime = (time) => {
    let hours = time.getHours() < 10 ? '0' + time.getHours() : time.getHours();
    let minutes = time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
    return `${hours}:${minutes}`;
  };

  const theDate = (time) => {
    let day = time.getDate() < 10 ? '0' + time.getDate() : time.getDate();
    let month = time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1;
    let year = time.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const emailClick = async () => {
    try {
      await sendEmail({
        receiver: 'client',
        agentId: data.agentData.id,
        clientId: data.clientData.id,
        consultationDate: data.roomData.consultationDate,
        consultationTitle: data.roomData.consultationTitle,
        urlParam: urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.sendEmail.endpoint,
        apiToken: data.apiToken,
        repeating: data.roomData.repeating,
        kindOfRepeating: data.roomData.kindOfRepeating,
        fullDate: data.roomData.fullDate,
        selectedDays: data.roomData.selectedDays,
        companyIdentification: data.companyIdentification,
        stage: data.stage,
      });
      if (
        data.clientData.clientData.email1 !== '' &&
        data.clientData.clientData.email1 !== undefined
      ) {
        await addExpertAndSaveRoom({
          firstName: data.clientData.clientData.firstName,
          lastName: data.clientData.clientData.lastName,
          email: data.clientData.clientData.email1,
        });
        enqueueSnackbar('Eine E-Mail mit dem Einladungslink wurde gesendet.', {
          variant: 'success',
        });
      } else if (
        data.clientData.clientData.email2 !== '' &&
        data.clientData.clientData.email2 !== undefined
      ) {
        await addExpertAndSaveRoom({
          firstName: data.clientData.clientData.firstName,
          lastName: data.clientData.clientData.lastName,
          email: data.clientData.clientData.email2,
        });
        enqueueSnackbar('Eine E-Mail mit dem Einladungslink wurde gesendet.', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(
          'Die E-Mail konnte nicht gesendet werden. Es sind keine Emailadressen hinterlegt.',
          { variant: 'error' }
        );
        console.warn(
          'Die E-Mail konnte nicht gesendet werden. Es sind keine Emailadressen hinterlegt.'
        );
      }
    } catch (e) {
      enqueueSnackbar('Die E-Mail konnte nicht gesendet werden.', { variant: 'error' });
      console.warn(e);
    }
  };

  const addExpertAndSaveRoom = async ({ firstName, lastName, email }) => {
    const newExperts = [
      ...data.experts.filter(
        (e) => e.firstName !== firstName && e.lastName !== lastName && e.email !== email
      ),
      { firstName, lastName, email },
    ];

    await saveRoom(
      data.apiToken,
      data.agentData,
      data.clientData,
      data.roomData,
      data.documents,
      data.url,
      data.insignData,
      data.activeStep,
      data.otpKey,
      data.companyIdentification,
      data.stage,
      data.contextID,
      data.agentConnectionId,
      newExperts,
      data.agendaText,
      stylingData.colors,
      urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.updateRoom.endpoint
    );

    data.setexperts(newExperts);
  };

  const createRoom = async () => {
    setbackdropOpen(true);
    async function fetchData() {
      const response = await fetch(
        urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.deliver.endpoint,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${data.apiToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            agentData: {
              id: data.agentData.id,
              company: data.agentDatacompany,
              agentData: {
                firstName: data.agentData.agentData.firstName,
                lastName: data.agentData.agentData.lastName,
                title: data.agentData.agentData.title,
                photo: data.agentData.agentData.photo,
                email1: data.agentData.agentData.email1,
                email2: data.agentData.agentData.email2,
              },
            },

            clientData: {
              id: data.clientData.id,
              company: data.clientData.company,
              clientData: {
                firstName: data.clientData.clientData.firstName,
                lastName: data.clientData.clientData.lastName,
                title: data.clientData.clientData.title,
                photo: data.clientData.clientData.photo,
                email1: data.clientData.clientData.email1,
                email2: data.clientData.clientData.email2,
                phone1: data.clientData.clientData.phone1,
                phone2: data.clientData.clientData.phone2,
                phone3: data.clientData.clientData.phone3,
              },
              contracts: data.clientData.contracts,
            },
            roomData: {
              roomHash: '',
              roomPassword: '',
              consultationDate: `${theDate(selectedTimeAndDate)}-${theTime(selectedTimeAndDate)}`,
              consultationTime: theTime(selectedTimeAndDate),
              repeating: false,
              kindOfRepeating: 'daily',
              fullDate: selectedTimeAndDate,
              selectedDays: [false, false, false, false, false, false, false],
              workflow: [false, false, false],
              videofile: '',
              lastUpdate: '',
              consultationTitle: consultationTitel,
              processingStatus: '',
              expiryDate: '',
              whiteBoard: '',
              documents: '',
              longURL: '',
              shortURL: '',
              locked: true,
              companyIdentification: data.companyIdentification,
              stage: data.stage,
            },
            colors: stylingData.colors,
            documents: [],
            activeStep: data.activeStep,
            companyIdentification: data.companyIdentification,
            contextID: data.contextID,
            agentConnectionId: data.agentConnectionId,
            experts: [],
            stage: data.stage,
          }),
        }
      );

      const json = await response.json();
      await data.setforceUpdate((prev) => !prev);

      data.setagentData(json.responsedata.agentData);
      data.setclientData(json.responsedata.clientData);
      data.setroomData(json.responsedata.roomData);
      data.setcompanyIdentification(json.responsedata.companyIdentification);
      data.setcontextID(json.responsedata.contextID);
      data.setDocuments(json.responsedata.documents);
      data.setagentConnectionId(json.responsedata.agentConnectionId);
      data.setexperts(json.responsedata.experts);
      data.setAgendaText(json.responsedata.agendaText);
      stylingData.setcolors(json.responsedata.colors);
    }

    await fetchData();
    setresponseState(true);
  };

  useEffect(() => {
    if (responseState) {
      if (data.clientData.id !== 'xxxxx') {
        emailClick();
      }
      setbackdropOpen(false);
      data.setPage(<AgentDrawer />);
      setresponseState(false);
    }
  }, [data.clientData, responseState]); // eslint-disable-line

  return (
    <div style={{ padding: '20px' }}>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <Spinner name="line-scale" color={stylingData.colors.color3} />
      </Backdrop>
      <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
        {data.clientData.id !== 'xxxxx' ? (
          <Fragment>
            <Grid item className={classes.avatarWrapper}>
              <Avatar
                className={classes.avatar}
                src={`${data.clientData.clientData.photo}`}
                alt={`${data.clientData.clientData.firstName} ${data.clientData.clientData.lastName}`}
              >
                {data.clientData.clientData.firstName.substr(0, 1)}
                {data.clientData.clientData.lastName.substr(0, 1)}
              </Avatar>
              <br />
              <Typography variant="h5">
                {data.clientData.clientData.firstName} {data.clientData.clientData.lastName}
              </Typography>
            </Grid>
            <div style={{ marginTop: '20px' }}>
              <Grid item style={{ alignSelf: 'flex-start' }}>
                <Grid container spacing={1}>
                  {data.clientData.clientData.email1 && (
                    <Grid item style={{ display: 'flex' }} xs={12}>
                      <MailOutline style={{ marginRight: 5 }} />
                      <Typography>{data.clientData.clientData.email1}</Typography>
                    </Grid>
                  )}

                  {data.clientData.clientData.email2 && (
                    <Grid item style={{ display: 'flex' }} xs={12}>
                      <MailOutline style={{ marginRight: 5 }} />
                      <Typography>{data.clientData.clientData.email2}</Typography>
                    </Grid>
                  )}

                  {data.clientData.clientData.phone1 && (
                    <Grid item style={{ display: 'flex' }} xs={12}>
                      <Phone style={{ marginRight: 5 }} />
                      <Typography>{data.clientData.clientData.phone1}</Typography>
                    </Grid>
                  )}

                  {data.clientData.clientData.phone2 && (
                    <Grid item style={{ display: 'flex' }} xs={12}>
                      <Phone style={{ marginRight: 5 }} />
                      <Typography>{data.clientData.clientData.phone2}</Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </div>
          </Fragment>
        ) : (
          <Card variant="outlined" className={classes.keyvanceCard}>
            <img
              src={urlData.urlList.logo}
              alt="keyvance_it_logo"
              className={classes.keyvanceLogo}
            />
          </Card>
        )}

        <div style={{ marginTop: '20px' }}>
          <Grid container direction="column" justify="center" alignItems="center" spacing={1}>
            <Grid item xs={12}>
              <Button
                className={classes.button}
                size="large"
                disabled={data.NewRoom}
                disableElevation
                onClick={onNewRoom}
                variant="contained"
                color="secondary"
              >
                Neue Beratung
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.button}
                size="large"
                disabled={data.NewRoom}
                disableElevation
                onClick={() => {
                  createRoom();
                  loggingData.setSequence({
                    type: 'createRoom',
                    agentData: {
                      id: data.agentData.id,
                      firstName: data.agentData.agentData.firstName,
                      lastName: data.agentData.agentData.lastName,
                    },
                    clientData: {
                      id: data.clientData.id,
                      firstName: data.clientData.clientData.firstName,
                      lastName: data.clientData.clientData.lastName,
                    },
                    consultationDate: theDate,
                    consultationTime: theTime,
                    consultationTitle: consultationTitel,
                  });
                }}
                variant="contained"
                color="secondary"
              >
                Ad hoc Beratung
              </Button>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
}
