import React, { useState, useContext, useMemo, useEffect, useReducer } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ApellaContext } from '../../context/ApellaContext';
import { StylingContext } from '../../context/StylingContext';
import { UrlContext } from '../../context/UrlContext';
import { LoggingContext } from '../../context/LoggingContext';
import RoomsList from './RoomsList';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import ScheduleIcon from '@material-ui/icons/Schedule';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { useSnackbar } from 'notistack';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { sendAgentEmail } from '../../helper/getData';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'stretch',
  },
  container: {
    minHeight: '60vh',
    maxHeight: '80vh',
    flexGrow: 1,
  },
  button: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  inputfield: {
    marginTop: theme.spacing(2),
  },
  user: { padding: 24 },
  snackbar: {
    color: 'white',
    background: '#006c52',
  },
}));

const BasisTab = ({ onFetchRooms, showComplete }) => {
  const urlData = useContext(UrlContext);
  const loggingData = useContext(LoggingContext);
  const { enqueueSnackbar } = useSnackbar();
  const [inputError, setinputError] = useState();
  const [selectedDate, handleDateChange] = useState(new Date());
  const [selectedTime, handleTimeChange] = useState(new Date());
  const [repeating, setrepeating] = useState(false);
  const [kindOfRepeating, setkindOfRepeating] = useState('daily');
  const [agentEmail, setagentEmail] = useState(true);

  const handleAgentEmail = () => {
    setagentEmail((pre) => !pre);
  };
  const initialState = [false, false, false, false, false, false, false];
  const reducer = (state, action) => {
    switch (action.type) {
      case 'changeDays':
        state[action.weekday] = !state[action.weekday];
        return state;
      case 'reset':
        state = initialState;
        state[selectedDate.getDay()] = true;
        return state;
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  const theTime = useMemo(
    () =>
      selectedTime
        ? `${
            selectedTime.getHours() < 10 ? '0' + selectedTime.getHours() : selectedTime.getHours()
          }:${
            selectedTime.getMinutes() < 10
              ? '0' + selectedTime.getMinutes()
              : selectedTime.getMinutes()
          }`
        : null,
    [selectedTime]
  );

  const theDate = useMemo(
    () =>
      selectedDate
        ? `${selectedDate.getDate() < 10 ? '0' + selectedDate.getDate() : selectedDate.getDate()}.${
            selectedDate.getMonth() + 1 < 10
              ? '0' + (selectedDate.getMonth() + 1)
              : selectedDate.getMonth() + 1
          }.${selectedDate.getFullYear()}-${theTime}`
        : null,
    [selectedDate, selectedTime] // eslint-disable-line
  );

  const [consultationTitel, setconsultationTitel] = useState();
  const data = useContext(ApellaContext);
  const stylingData = useContext(StylingContext);
  const classes = useStyles();

  const createRoom = async () => {
    if (!selectedDate || isNaN(selectedDate)) {
      setinputError('Beratungsdatum');
      enqueueSnackbar('Bitte geben Sie das Beratungsdatum ein!', { variant: 'error' });
    } else if (!selectedTime || isNaN(selectedTime)) {
      setinputError('Uhrzeit');
      enqueueSnackbar('Bitte geben Sie die Uhrzeit ein!', { variant: 'error' });
    } else if (!consultationTitel) {
      setinputError('Beratungstitel');
      enqueueSnackbar('Bitte geben Sie den Beratungstitel ein!', { variant: 'error' });
    } else {
      // prüfen, on bereits ein Raum für dieses Datum/Uhrzeit existiert!
      if (
        data.roomsList.findIndex(({ roomData: { consultationDate, consultationTime } }) => {
          return consultationDate === theDate && consultationTime === theTime;
        }) !== -1
      ) {
        setinputError('Uhrzeit');
        enqueueSnackbar('Für diesen Zeitpunkt existiert bereits eine Beratung!', {
          variant: 'error',
        });
        return;
      }
      setinputError(false);

      async function fetchData() {
        const response = await fetch(
          urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.deliver.endpoint,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${data.apiToken}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              agentData: {
                id: data.agentData.id,
                company: data.agentDatacompany,
                agentData: {
                  firstName: data.agentData.agentData.firstName,
                  lastName: data.agentData.agentData.lastName,
                  title: data.agentData.agentData.title,
                  photo: data.agentData.agentData.photo,
                  email1: data.agentData.agentData.email1,
                  email2: data.agentData.agentData.email2,
                },
              },

              clientData: {
                id: data.clientData.id,
                company: data.clientData.company,
                clientData: {
                  firstName: data.clientData.clientData.firstName,
                  lastName: data.clientData.clientData.lastName,
                  title: data.clientData.clientData.title,
                  photo: data.clientData.clientData.photo,
                  email1: data.clientData.clientData.email1,
                  email2: data.clientData.clientData.email2,
                  phone1: data.clientData.clientData.phone1,
                  phone2: data.clientData.clientData.phone2,
                  phone3: data.clientData.clientData.phone3,
                },
                contracts: data.clientData.contracts,
              },
              roomData: {
                roomHash: '',
                roomPassword: '',
                consultationDate: theDate,
                consultationTime: theTime,
                repeating: repeating,
                kindOfRepeating: kindOfRepeating,
                fullDate: selectedDate,
                selectedDays: state,
                workflow: [false, false, false],
                videofile: '',
                lastUpdate: '',
                consultationTitle: consultationTitel,
                processingStatus: '',
                expiryDate: '',
                whiteBoard: '',
                documents: '',
                longURL: '',
                shortURL: '',
                locked: true,
                companyIdentification: data.companyIdentification,
                stage: data.stage,
              },
              colors: stylingData.colors,
              documents: [],
              activeStep: data.activeStep,
              companyIdentification: data.companyIdentification,
              stage: data.stage,
              contextID: data.contextID,
              agentConnectionId: data.agentConnectionId,
              experts: data.experts,
            }),
          }
        );

        const json = await response.json();
        await data.setforceUpdate((prev) => !prev);

        data.setagentData(json.responsedata.agentData);
        data.setclientData(json.responsedata.clientData);
        data.setroomData(json.responsedata.roomData);
        data.setcompanyIdentification(json.responsedata.companyIdentification);
        data.setcontextID(json.responsedata.contextID);
        data.setDocuments(json.responsedata.documents);
        data.setagentConnectionId(json.responsedata.agentConnectionId);
        data.setexperts(json.responsedata.experts);
        data.setAgendaText(json.responsedata.agendaText);
        stylingData.setcolors(json.responsedata.colors);
      }

      await fetchData();
      handleClose();
      handleDateChange(new Date());
      handleTimeChange(new Date());
      setconsultationTitel('');
      setagentEmail(true);
      enqueueSnackbar('Ein neuer Beratungsraum wurde erstellt!', { variant: 'success' });

      await onFetchRooms();
      if (agentEmail) {
        await sendAgentEmail({
          agentId: data.agentData.id,
          clientId: data.clientData.id,
          consultationDate: theDate,
          consultationTitle: consultationTitel,
          urlParam:
            urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.sendAgentEmail.endpoint,
          apiToken: data.apiToken,
          repeating: repeating,
          kindOfRepeating: kindOfRepeating,
          fullDate: selectedDate,
          selectedDays: state,
          companyIdentification: data.companyIdentification,
          stage: data.stage,
        });
      }
    }
  };

  const handleClose = () => {
    data.setNewRoom(false);
  };

  const handleChange = (event) => {
    setconsultationTitel(event.target.value);
  };

  const handleRepeating = (event) => {
    setrepeating(event.target.checked);
  };
  const handleKindOfRepeating = (event) => {
    setkindOfRepeating(event.target.value);
  };
  const handleWeekdays = (event) => {
    dispatch({ type: 'changeDays', weekday: event.target.value });
  };

  useEffect(() => {
    dispatch({ type: 'reset' });
    setrepeating(false);
  }, [selectedDate]);

  return (
    <div>
      <Grid container spacing={2}>
        <Dialog
          open={!showComplete && typeof data.NewRoom !== 'undefined' && data.NewRoom}
          aria-labelledby="new-room-title"
          aria-describedby="new-room-description"
        >
          <DialogContent>
            <DialogTitle id="new-room-title">Neue Beratung</DialogTitle>

            <Grid container spacing={2} id="new-room-description">
              <Grid item sm={12} md={4}>
                <KeyboardDatePicker
                  className={classes.inputfield}
                  inputVariant="outlined"
                  ampm={false}
                  label="Beratungsdatum"
                  value={selectedDate}
                  onChange={(date) => handleDateChange(date)}
                  onError={console.log}
                  error={inputError === 'Beratungsdatum'}
                  disablePast
                  format="dd.MM.yyyy"
                  showTodayButton={true}
                  todayLabel="Heute"
                  invalidDateMessage="Das Datum hat ein falsches Format!"
                  minDateMessage="Das Datum muss in der Zukunft liegen!"
                />
              </Grid>

              <Grid item sm={12} md={4}>
                <KeyboardTimePicker
                  className={classes.inputfield}
                  inputVariant="outlined"
                  variant="dialog"
                  error={inputError === 'Uhrzeit'}
                  label="Uhrzeit"
                  clearable
                  ampm={false}
                  value={selectedTime}
                  onChange={(time) => handleTimeChange(time)}
                  keyboardIcon={<ScheduleIcon />}
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <TextField
                  className={classes.inputfield}
                  label="Beratungstitel"
                  helperText="Geben Sie hier den Beratungstitel ein."
                  variant="outlined"
                  error={inputError === 'Beratungstitel'}
                  required
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={12} md={12}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        defaultToggled={false}
                        checked={repeating}
                        onChange={handleRepeating}
                        name="repeatingSwitch"
                        color="primary"
                        checkedIcon={<CheckCircleIcon />}
                      />
                    }
                    label="Serientermin"
                  />
                  {repeating ? (
                    <RadioGroup value={kindOfRepeating} row onChange={handleKindOfRepeating}>
                      <FormControlLabel
                        value="daily"
                        control={<Radio />}
                        onChange={() => dispatch({ type: 'reset' })}
                        label="täglich"
                      />
                      <FormControlLabel
                        value="daily_workingweek"
                        control={<Radio />}
                        label="täglich (Mo-Fr)"
                        onChange={() => dispatch({ type: 'reset' })}
                      />
                      <FormControlLabel value="weekly" control={<Radio />} label="wöchentlich" />
                    </RadioGroup>
                  ) : null}
                  {repeating && kindOfRepeating === 'weekly' ? (
                    <FormGroup row onChange={handleWeekdays}>
                      <FormControlLabel
                        name="Mo"
                        value={1}
                        control={<Checkbox defaultChecked={state[1]} disabled={state[1]} />}
                        label="Mo"
                      />
                      <FormControlLabel
                        name="Di"
                        value={2}
                        control={<Checkbox defaultChecked={state[2]} disabled={state[2]} />}
                        label="Di"
                      />
                      <FormControlLabel
                        name="Mi"
                        value={3}
                        control={<Checkbox defaultChecked={state[3]} disabled={state[3]} />}
                        label="Mi"
                      />
                      <FormControlLabel
                        name="Do"
                        value={4}
                        control={<Checkbox defaultChecked={state[4]} disabled={state[4]} />}
                        label="Do"
                      />
                      <FormControlLabel
                        name="Fr"
                        value={5}
                        control={<Checkbox defaultChecked={state[5]} disabled={state[5]} />}
                        label="Fr"
                      />
                      <FormControlLabel
                        name="Sa"
                        value={6}
                        control={<Checkbox defaultChecked={state[6]} disabled={state[6]} />}
                        label="Sa"
                      />
                      <FormControlLabel
                        name="So"
                        value={0}
                        control={<Checkbox defaultChecked={state[0]} disabled={state[0]} />}
                        label="So"
                      />
                    </FormGroup>
                  ) : null}
                </FormGroup>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={12} md={12}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="calenderentry"
                        defaultChecked={agentEmail}
                        onChange={handleAgentEmail}
                      />
                    }
                    label="Kalendereintrag mir selbst zuschicken"
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <DialogActions>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <Button
                  className={classes.button}
                  size="large"
                  onClick={() => data.setNewRoom(false)}
                >
                  Abbrechen
                </Button>

                <Button
                  disableElevation
                  className={classes.button}
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={() => {
                    createRoom();
                    loggingData.setSequence({
                      type: 'createRoom',
                      agentData: {
                        id: data.agentData.id,
                        firstName: data.agentData.agentData.firstName,
                        lastName: data.agentData.agentData.lastName,
                      },
                      clientData: {
                        id: data.clientData.id,
                        firstName: data.clientData.clientData.firstName,
                        lastName: data.clientData.clientData.lastName,
                      },
                      consultationDate: theDate,
                      consultationTime: theTime,
                      consultationTitle: consultationTitel,
                    });
                  }}
                >
                  Beratungsraum erstellen
                </Button>
              </div>
            </DialogActions>
          </DialogContent>
        </Dialog>

        <Grid item xs={12}>
          <RoomsList onFetchRooms={onFetchRooms} showComplete={showComplete} rows={10} />
        </Grid>
      </Grid>
    </div>
  );
};

export default BasisTab;
