import React, { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ApellaContext } from '../context/ApellaContext';
import FileTable from './FileTable';
import PolicysystemDocuments from './dialogues/PolicysystemDocuments';
import Grid from '@material-ui/core/Grid';
import DocumentButtons from './DocumentButtons';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  buttonContainer: {
    allignItems: 'stretch',
  },
  buttonItems: {
    height: '100%',
  },
}));
const Dokumente = (props) => {
  const classes = useStyles();
  const [openPolicySystemDocumentstable, setopenPolicySystemDocumentstable] = useState(false);

  return (
    <ApellaContext.Consumer>
      {(context) => (
        <Fragment>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Grid container>
              <Grid item xl={12} lg={12} md={12}>
                <FileTable send={props.send} origin={1} />
              </Grid>

              <DocumentButtons
                send={props.send}
                setopenPolicySystemDocumentstable={setopenPolicySystemDocumentstable}
              />
            </Grid>
            {openPolicySystemDocumentstable ? (
              <PolicysystemDocuments
                openPolicySystemDocumentstable={openPolicySystemDocumentstable}
                setopenPolicySystemDocumentstable={setopenPolicySystemDocumentstable}
              />
            ) : null}
          </main>
        </Fragment>
      )}
    </ApellaContext.Consumer>
  );
};

export default Dokumente;
