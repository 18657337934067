import React, { useContext, useState, useEffect, useReducer, Fragment } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableContainer,
  Card,
  CardHeader,
  CardContent,
  Typography,
  IconButton,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@material-ui/core/styles';

import { ApellaContext } from '../../../context/ApellaContext';
import { PollContext } from '../../../context/PollContext';

import { useQuery } from '@apollo/client';
import { listPollResults } from '../../../graphql/queries';
import PollResults from './PollResults';

const useStyles = makeStyles((theme) => ({
  resultTable: {
    cursor: 'pointer',
  },
}));

const PollResultList = ({
  origin,
  showMiniPollresultList,
  setShowMiniPollresultList,
  handleShowPollResultListDialog,
  setCheckboxClicked,
}) => {
  let pageSize = origin === 'mini' ? 3 : 10;
  const classes = useStyles();
  const apellaContextData = useContext(ApellaContext);
  const pollContextData = useContext(PollContext);
  const [nextToken, setNextToken] = useState(null);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);
  const [showPollResultsDialog, setShowPollResultsDialog] = useState(false);
  const [pollResultId, setPollResultId] = useState();
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0); // eslint-disable-line
  const [checkboxArray, setcheckboxArray] = useState({});
  const [resetCheckboxArray, setResetCheckboxArray] = useState({});
  const [disableCheckboxes, setDisableCheckboxes] = useState(new Map());

  const listPollResult = useQuery(listPollResults, {
    fetchPolicy: 'no-cache',
    variables: {
      filter: {
        ownerPollResultsId: { attributeExists: true, contains: apellaContextData.agentData.id },
      },
    },
    onCompleted: (data) => {
      pollContextData.setPollResults(data.listPollResults.items);
      setNextToken(data.listPollResults.nextToken);
      let tempComparePollResultsIndexArray = [];
      pollContextData.setResetResultListCheckboxes(false);
      let tempCheckboxArray = {};
      data.listPollResults.items.map((result) => {
        Object.assign(tempCheckboxArray, { [result.id]: false });

        return tempComparePollResultsIndexArray.push({
          compare: false,
          id: result.id,
        });
      });
      setcheckboxArray(tempCheckboxArray);
      setResetCheckboxArray(tempCheckboxArray);
      pollContextData.setComparePollResultsIndexArray(tempComparePollResultsIndexArray);
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (pollContextData.resetResultListCheckboxes) {
      setcheckboxArray(resetCheckboxArray);
      pollContextData.setResetResultListCheckboxes(false);
      let tempDisableCheckboxes = new Map();
      setDisableCheckboxes(tempDisableCheckboxes);
    }
  }, [pollContextData.resetResultListCheckboxes]); // eslint-disable-line

  useEffect(() => {
    pollContextData.reloadPollResultsList && listPollResult.refetch();
    pollContextData.setReloadPollResultsList(false);
    forceUpdate();
  }, [pollContextData.reloadPollResultsList]); // eslint-disable-line

  useEffect(() => {
    if (nextToken) {
      listPollResult.fetchMore(listPollResults, {
        fetchPolicy: 'no-cache',
        variables: {
          input: { nextToken: nextToken },
          filter: {
            ownerPollResultsId: {
              attributeExists: true,
              contains: apellaContextData.agentData.id,
            },
          },
        },
        onCompleted: (data) => {
          let tempPollResultArray = [...pollContextData.pollResults, ...data.listPollResults.items];
          pollContextData.setPollResults(tempPollResultArray);
        },
      });
      return () => {
        setNextToken(null);
      };
    }
  }, [nextToken]); // eslint-disable-line

  const convertTime = (timeString) => {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      timeZone: 'Europe/Berlin',
    };
    let time = new Date(timeString).toLocaleTimeString('de', options);
    return time;
  };

  useEffect(() => {
    if (pollContextData.pollResults && (Object.keys(checkboxArray).length === 0) !== true) {
      let tempArray = pollContextData.pollResults.map((result, index) => {
        return (
          <Fragment>
            <TableRow
              key={index}
              style={
                disableCheckboxes.has(result.pollPollResultsId)
                  ? { backgroundColor: '#f0f0f0' }
                  : null
              }
            >
              {origin !== 'mini' ? (
                <TableCell padding="checkbox">
                  <Checkbox
                    id={result.id}
                    name={index}
                    onChange={(event) => {
                      handleComparePollResults(event);
                      handelSetpollPollResultsId({
                        pollPollResultsId: result.pollPollResultsId,
                        pollResultId: result.id,
                      });
                    }}
                    checked={checkboxArray[result.id]}
                    disabled={
                      disableCheckboxes.has(result.pollPollResultsId) ||
                      disableCheckboxes.size === 0
                        ? false
                        : true
                    }
                  />
                </TableCell>
              ) : null}
              <TableCell
                className={classes.resultTable}
                onClick={() => {
                  setPollResultId(result.id);
                  setShowPollResultsDialog(true);
                }}
              >
                <Typography variant="body1">{result.question}</Typography>
              </TableCell>
              <TableCell
                onClick={() => {
                  setPollResultId(result.id);
                  setShowPollResultsDialog(true);
                }}
              >
                <Typography variant="body1">{convertTime(result.updatedAt)}</Typography>
              </TableCell>
            </TableRow>
          </Fragment>
        );
      });
      setRows(tempArray);
    }
  }, [pollContextData.pollResults, checkboxArray]); // eslint-disable-line

  const handleComparePollResults = (event) => {
    setcheckboxArray({ ...checkboxArray, [event.target.id]: event.target.checked });
    let tempComparePollResultsIndexArray = pollContextData.comparePollResultsIndexArray;
    tempComparePollResultsIndexArray[event.target.name].compare = event.target.checked;
    pollContextData.setComparePollResultsIndexArray(tempComparePollResultsIndexArray);
    setCheckboxClicked((prev) => !prev);
  };

  const handelSetpollPollResultsId = (param) => {
    //wenn param.pollPollResultsId als Key in Map
    if (disableCheckboxes.has(param.pollPollResultsId)) {
      let tempDisableCheckboxes = disableCheckboxes;
      if (
        //wenn param.pollResultId in Map => Array
        disableCheckboxes
          .get(param.pollPollResultsId)
          .findIndex((element) => element === param.pollResultId) !== -1
      ) {
        // Wenn Map => Array nur ein Element enthät, cleare die Map
        if (tempDisableCheckboxes.get(param.pollPollResultsId).length === 1) {
          tempDisableCheckboxes = new Map();
        } else {
          let index = tempDisableCheckboxes
            .get(param.pollPollResultsId)
            .indexOf(param.pollResultId);
          tempDisableCheckboxes.get(param.pollPollResultsId).splice(index, 1);
        }
        setDisableCheckboxes(tempDisableCheckboxes);
      } else {
        tempDisableCheckboxes.get(param.pollPollResultsId).push(param.pollResultId);
        setDisableCheckboxes(tempDisableCheckboxes);
      }
    } else {
      setDisableCheckboxes(disableCheckboxes.set(param.pollPollResultsId, [param.pollResultId]));
    }
  };

  return (
    <Fragment>
      <Card variant="outlined">
        <CardHeader
          action={
            origin === 'mini' ? (
              <Tooltip title="vergrößern" aria-label="vergroeßern">
                <IconButton onClick={() => handleShowPollResultListDialog()}>
                  <FontAwesomeIcon icon={faExpandArrowsAlt} />
                </IconButton>
              </Tooltip>
            ) : null
          }
          /* title={'Umfrageergebnisse'} */ title={
            <>
              <Typography color="inherit" variant="h5">
                Umfrageergebnisse
              </Typography>
              <Typography color="inherit" variant="subtitle1">
                {origin === 'mini'
                  ? 'Um Ergebnisse zu vergleichen, vergrößern Sie bitte die Tabelle.'
                  : 'Markieren Sie die Ergebnisse, um sie zu vergleichen.'}
              </Typography>
            </>
          }
        />

        <CardContent>
          <TableContainer>
            <Table pageSize={3} size="small">
              <TableHead>
                {origin !== 'mini' ? <TableCell padding="checkbox">Vergleich</TableCell> : null}

                <TableCell>Frage</TableCell>
                <TableCell>Datum</TableCell>
              </TableHead>
              <TableBody>
                {pollContextData.pollResults
                  ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      return row;
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={origin !== 'mini' ? [3, 5, 10, 25, 100] : [3, 5]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            backIconButtonText="Vorherige Seite"
            labelRowsPerPage="Zeilen pro Seite:"
            nextIconButtonText="Nächste Seite"
          />
        </CardContent>
      </Card>
      {showPollResultsDialog && (
        <PollResults
          pollResultId={pollResultId}
          showPollResultsDialog={showPollResultsDialog}
          setShowPollResultsDialog={setShowPollResultsDialog}
        />
      )}
    </Fragment>
  );
};

export default PollResultList;
