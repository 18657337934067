import React, { useState, useEffect, useContext, useRef } from 'react';
import { ApellaContext } from '../context/ApellaContext';
import { StylingContext } from '../context/StylingContext';
import { UrlContext } from '../context/UrlContext';
import { makeStyles } from '@material-ui/core/styles';
import { LoginContext } from '../context/LoginContext';
import { LoggingContext } from '../context/LoggingContext';
import ToggleIcon from 'material-ui-toggle-icon';
import Zoom from '@material-ui/core/Zoom';

const JitsiMeetComponent = (props) => {
  const [numberOfParticipants, setnumberOfParticipants] = useState(0);
  const [dominantSpeaker, setdominantSpeaker] = useState('');
  const [ownJitsiId, setownJitsiId] = useState('');
  const [filmstripEnabled, setfilmstripEnabled] = useState(false); //eslint-disable-line
  const urlData = useContext(UrlContext);
  const stylingData = useContext(StylingContext);
  const loggingData = useContext(LoggingContext);
  const [thisapi, setthisapi] = useState();
  const jitsicontainer = useRef(null);
  const jitsistyleoverlay = useRef(null);
  const [zoomJitsi, setzoomJitsi] = useState(true);
  /* const [screensharer, screensharingtoggler] = useReducer(reducer, props.screenshare); */
  const [miniJitsiSize, setminiJitsiSize] = useState(300); //eslint-disable-line
  const [jitsiContainerPositionTop, setjitsiContainerPositionTop] = useState(
    window.innerHeight - miniJitsiSize - 10
  );
  const [jitsiContainerPositionLeft, setjitsiContainerPositionLeft] = useState(
    window.innerWidth - miniJitsiSize - 10
  );
  const maximizeValue = useRef(props.showMenu);
  const resizeJitsiByMenu = useRef(props.openMenu);
  const [jitsiContainerHeight, setjitsiContainerHeight] = useState(miniJitsiSize);
  const [jitsiContainerWidth, setjitsiContainerWidth] = useState(miniJitsiSize);
  const [jitsiContainerzIndex, setjitsiContainerzIndex] = useState(10000);
  const [jitsiContainerOverlayzIndex, setjitsiContainerOverlayzIndex] = useState(10001);
  const [jitsiContainerBoxShadow, setjitsiContainerBoxShadow] = useState(
    '0px 0px 41px -5px rgba(0,0,0,0.75)'
  );
  /**
   * minimize Minijitsi
   */
  const IconButton = require('@material-ui/core/IconButton').default;
  const ArrowDropDownIcon = require('@material-ui/icons/ArrowDropDown').default;
  const ArrowDropUpIcon = require('@material-ui/icons/ArrowDropUp').default;
  const [dropdownArrow, setdropdownArrow] = useState(false);
  const [minijitsiMinimized, setminijitsiMinimized] = useState(false);
  const handleMinimizeMinijitsi = () => {
    setdropdownArrow((prev) => !prev);
    setminijitsiMinimized((prev) => !prev);
    if (!minijitsiMinimized) setjitsiContainerzIndex(-100);
    else setjitsiContainerzIndex(10000);
  };
  /**
   * MiniJitsimove
   */
  const moveMinijitsi = () => {
    if (props.position === 'br') {
      setjitsiContainerHeight(miniJitsiSize);
      setjitsiContainerWidth(miniJitsiSize);
      setjitsiContainerzIndex(10000);
      setjitsiContainerBoxShadow('0px 0px 41px -5px rgba(0,0,0,0.75)');
      setjitsiContainerPositionTop(window.innerHeight - miniJitsiSize - 10);
      setjitsiContainerPositionLeft(window.innerWidth - miniJitsiSize - 10);
    } else if (
      (loginData.agent && props.openMenu) ||
      (!loginData.agent && props.showMenu && props.openMenu)
    ) {
      setjitsiContainerPositionTop(64);
      setjitsiContainerPositionLeft(240);
      setjitsiContainerHeight(window.innerHeight - 64);
      setjitsiContainerWidth(window.innerWidth - 240);
      setjitsiContainerzIndex(200);
      setjitsiContainerBoxShadow('0px 0px 41px -5px rgba(0,0,0,0.75)');
    } else if (
      (loginData.agent && !props.openMenu) ||
      (!loginData.agent && props.showMenu && !props.openMenu)
    ) {
      setjitsiContainerPositionTop(64);
      setjitsiContainerPositionLeft(73);
      setjitsiContainerHeight(window.innerHeight - 64);
      setjitsiContainerWidth(window.innerWidth - 73);
      setjitsiContainerzIndex(200);
      setjitsiContainerBoxShadow('0px 0px 41px -5px rgba(0,0,0,0.75)');
    } else {
      setjitsiContainerPositionTop(64);
      setjitsiContainerPositionLeft(0);
      setjitsiContainerHeight(window.innerHeight - 64);
      setjitsiContainerWidth(window.innerWidth);
      setjitsiContainerzIndex(200);
      setjitsiContainerBoxShadow('0px 0px 41px -5px rgba(0,0,0,0.75)');
    }
  };
  window.addEventListener('resize', moveMinijitsi);
  /***
   * Drag´n Drop
   */
  const [mousePressed, setmousePressed] = useState(false);
  const [dragX, setdragX] = useState();
  const [dragY, setdragY] = useState();
  const [shiftX, setshiftX] = useState();
  const [shiftY, setshiftY] = useState();
  useEffect(() => {
    if (!props.showMenu && maximizeValue !== maximizeValue.current) {
      moveMinijitsi();
    } else if (props.showMenu && maximizeValue !== maximizeValue.current) {
      moveMinijitsi();
    }
  }, [props.showMenu]); //eslint-disable-line
  const _onMouseDown = (e) => {
    if (props.position !== 'big') {
      setmousePressed(true);
      e.preventDefault();
      e.target.setPointerCapture(e.pointerId);
      setdragX(e.nativeEvent.clientX);
      setdragY(e.nativeEvent.clientY);
      setshiftX(e.nativeEvent.layerX);
      setshiftY(e.nativeEvent.layerY);
    }
  };
  const _onMouseUp = (e) => {
    setmousePressed(false);
  };
  const _onMouseMove = (e) => {
    return mousePressed
      ? (setdragX(e.nativeEvent.clientX - shiftX),
        setdragY(e.nativeEvent.clientY - shiftY),
        setjitsiContainerPositionTop(dragY),
        setjitsiContainerPositionLeft(dragX))
      : null;
  };
  const _onMouseEnter = (e) => {
    return props.position !== 'big' ? (document.body.style.cursor = 'move') : null;
  };
  const _onMouseOut = (e) => {
    return props.position !== 'big' ? (document.body.style.cursor = 'default') : null;
  };
  /***
   * calculating the jitsicomponent window
   */
  const br = () => {
    setjitsiContainerPositionTop(window.innerHeight - miniJitsiSize - 10);
    setjitsiContainerPositionLeft(window.innerWidth - miniJitsiSize - 10);
    setjitsiContainerHeight(miniJitsiSize);
    setjitsiContainerWidth(miniJitsiSize);
    setjitsiContainerzIndex(10000);
    setjitsiContainerBoxShadow('0px 0px 41px -5px rgba(0,0,0,0.75)');
  };
  const bl = () => {
    setjitsiContainerPositionTop(window.innerHeight - miniJitsiSize - 10);
    setjitsiContainerPositionLeft(10);
    setjitsiContainerHeight(miniJitsiSize);
    setjitsiContainerWidth(miniJitsiSize);
    setjitsiContainerzIndex(10000);
    setjitsiContainerBoxShadow('0px 0px 41px -5px rgba(0,0,0,0.75)');
  };
  const tr = () => {
    setjitsiContainerPositionTop(10);
    setjitsiContainerPositionLeft(window.innerWidth - miniJitsiSize - 10);
    setjitsiContainerHeight(miniJitsiSize);
    setjitsiContainerWidth(miniJitsiSize);
    setjitsiContainerzIndex(10000);
    setjitsiContainerBoxShadow('0px 0px 41px -5px rgba(0,0,0,0.75)');
  };
  const tl = () => {
    setjitsiContainerPositionTop(10);
    setjitsiContainerPositionLeft(10);
    setjitsiContainerHeight(miniJitsiSize);
    setjitsiContainerWidth(miniJitsiSize);
    setjitsiContainerzIndex(10000);
    setjitsiContainerBoxShadow('0px 0px 41px -5px rgba(0,0,0,0.75)');
  };
  const big = () => {
    document.body.style.cursor = 'default';
    setjitsiContainerPositionTop(64);
    if (loginData.agent && props.openMenu === true) {
      setjitsiContainerPositionLeft(240);
      setjitsiContainerHeight(window.innerHeight - 64);
      setjitsiContainerWidth(window.innerWidth - 240);
    } else if (loginData.agent && props.openMenu === false) {
      setjitsiContainerPositionLeft(73);
      setjitsiContainerHeight(window.innerHeight - 64);
      setjitsiContainerWidth(window.innerWidth - 73);
    } else if (props.showMenu) {
      setjitsiContainerPositionLeft(73);
      setjitsiContainerHeight(window.innerHeight - 64);
      setjitsiContainerWidth(window.innerWidth - 73);
    } else {
      setjitsiContainerPositionLeft(0);
      setjitsiContainerHeight(window.innerHeight - 64);
      setjitsiContainerWidth(window.innerWidth);
    }
    setjitsiContainerzIndex(200);
    setjitsiContainerBoxShadow('0px 0px 41px -5px rgba(0,0,0,0.75)');
  };
  useEffect(() => {
    if (!props.showMenu && maximizeValue !== maximizeValue.current) {
      moveMinijitsi(); //todo timeout
    } else if (props.showMenu && maximizeValue !== maximizeValue.current) {
      setTimeout(function () {
        moveMinijitsi();
      }, 225);
    }
  }, [props.showMenu]); //eslint-disable-line
  useEffect(() => {
    if (!props.openMenu && resizeJitsiByMenu !== resizeJitsiByMenu.current) {
      moveMinijitsi();
    } else if (props.openMenu && resizeJitsiByMenu !== resizeJitsiByMenu.current) {
      setTimeout(function () {
        moveMinijitsi();
      }, 225);
    }
  }, [props.openMenu]); //eslint-disable-line
  const jitsiposition = (props) => {
    switch (props.position) {
      case 'br':
        if (props.openChatDrawer) {
          setzoomJitsi(false);
          setTimeout(() => {
            bl();
            setzoomJitsi(true);
          }, 300);
        } else {
          setzoomJitsi(false);
          setTimeout(() => {
            br();
            setzoomJitsi(true);
          }, 300);
        }
        break;
      case 'bl':
        setzoomJitsi(false);
        setTimeout(() => {
          bl();
          setzoomJitsi(true);
        }, 300);
        break;
      case 'tr':
        tr();
        break;
      case 'tl':
        tl();
        break;
      case 'big':
        setzoomJitsi(false);
        setTimeout(() => {
          big();
          setzoomJitsi(true);
        }, 300);
        break;
      default:
        setzoomJitsi(false);
        setTimeout(() => {
          br();
          setzoomJitsi(true);
        }, 200);
    }
  };
  useEffect(() => {
    jitsiposition(props);
  }, [props.position]); //eslint-disable-line
  useEffect(() => {
    if (props.askForUploadDialog && props.position === 'br') {
      setzoomJitsi(false);
      setTimeout(() => {
        bl();
        setzoomJitsi(true);
      }, 300);
    }
  }, [props.askForUploadDialog]); //eslint-disable-line
  useEffect(() => {
    if (props.openChatDrawer && props.position === 'br') {
      setzoomJitsi(false);
      setTimeout(() => {
        bl();
        setzoomJitsi(true);
      }, 300);
    } else if (props.position !== 'big') {
      jitsiposition(props);
    }
  }, [props.openChatDrawer]); //eslint-disable-line
  const useStyles = makeStyles((theme) => ({
    content: {
      flexGrow: 1,
      overflow: 'hidden',
    },
    jitsistyle: {
      overflow: 'hidden',
      position: 'fixed',
      top: jitsiContainerPositionTop,
      left: jitsiContainerPositionLeft,
      height: jitsiContainerHeight,
      width: jitsiContainerWidth,
      zIndex: jitsiContainerzIndex,
      boxShadow: jitsiContainerBoxShadow,
    },
    jitsiministyle: {
      overflow: 'hidden',
      position: 'fixed',
      top: jitsiContainerPositionTop,
      left: jitsiContainerPositionLeft,
      height: jitsiContainerHeight,
      width: jitsiContainerWidth,
      zIndex: jitsiContainerzIndex,
      boxShadow: jitsiContainerBoxShadow,
      borderRadius: '10px 10px 10px 10px',
    },
    jitsistyleoverlay: {
      //backgroundColor: 'white',
      overflow: 'hidden',
      position: 'fixed',
      top: jitsiContainerPositionTop,
      left: jitsiContainerPositionLeft,
      height: 40,
      width: jitsiContainerWidth,
      zIndex: jitsiContainerOverlayzIndex,
      //boxShadow: jitsiContainerBoxShadow,
    },
    overlayMinimizer: {
      height: 40,
      width: jitsiContainerWidth,
      backgroundColor: stylingData.colors.color2,
      zIndex: jitsiContainerOverlayzIndex + 1,
      borderRadius: '10px 10px 0px 0px',
    },
    toolbar: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
  }));
  const data = useContext(ApellaContext);
  const loginData = useContext(LoginContext);
  const classes = useStyles();

  const startConference = () => {
    let jwtToken = '';
    if (loginData.agent) {
      jwtToken = data.agentData.token;
    } else {
      jwtToken = loginData.user.jitsiToken;
    }
    try {
      const domain = urlData.urlList.jitsiUrl;
      let options = {};
      props.audioOnly
        ? (options = {
            roomName: data.roomData.roomHash,
            jwt: jwtToken,
            parentNode: document.getElementById('jitsi-container'),
            configOverwrite: {
              enableClosePage: false,
              requireDisplayName: true,
              disableSimulcast: false,
              startAudioOnly: true,
            },
            interfaceConfigOverwrite: {
              DEFAULT_LOCAL_DISPLAY_NAME: 'ich',
              SHOW_BRAND_WATERMARK: false,
              filmStripOnly: false,
              SHOW_WATERMARK_FOR_GUESTS: false,
              SHOW_JITSI_WATERMARK: false,
              HIDE_DEEP_LINKING_LOGO: true,

              /*           TOOLBAR_BUTTONS: ['microphone'], */
              DEFAULT_BACKGROUND: stylingData.colors.color2,
            },
          })
        : (options = {
            roomName: data.roomData.roomHash,
            jwt: jwtToken,
            parentNode: document.getElementById('jitsi-container'),
            configOverwrite: {
              enableClosePage: false,
              requireDisplayName: true,
              disableSimulcast: false,
              startAudioOnly: false,
            },
            interfaceConfigOverwrite: {
              DEFAULT_LOCAL_DISPLAY_NAME: 'ich',
              SHOW_BRAND_WATERMARK: false,
              filmStripOnly: false,
              SHOW_WATERMARK_FOR_GUESTS: true,
              SHOW_JITSI_WATERMARK: false,
              HIDE_DEEP_LINKING_LOGO: true,

              /*           TOOLBAR_BUTTONS: ['microphone'], */
              DEFAULT_BACKGROUND: stylingData.colors.color2,
            },
          });
      //const api = new JitsiMeetExternalAPI(domain, options);
      //React spezifisch
      const api = new window.JitsiMeetExternalAPI(domain, options);
      setthisapi(api);
      api.addEventListener('videoConferenceJoined', (listener) => {
        console.log('Local User Joined');
        loggingData.setSequence({ type: 'userJoints', value: api.getParticipantsInfo() });
        setownJitsiId(listener.id);
        let displayName = '';
        if (loginData.agent) {
          displayName = `${data.agentData.agentData.firstName} ${data.agentData.agentData.lastName}`;
        } else if (loginData.client) {
          displayName = `${data.clientData.clientData.firstName} ${data.clientData.clientData.lastName}`;
        } else displayName = `${loginData.user.firstName} ${loginData.user.lastName}`;
        api.executeCommand('displayName', displayName);
        api.executeCommand('subject', data.roomData.consultationTitle);
        api.executeCommand('localSubject', data.roomData.consultationTitle);
        setnumberOfParticipants(api.getNumberOfParticipants());
        /* api.executeCommand('subject', ' '); */
        /* api.executeCommand('startRecording', { mode: 'file' }); */
      });
      api.addListener('recordingStatusChanged', function (listener) {});
      api.addEventListener('dominantSpeakerChanged', function (listener) {
        setdominantSpeaker(listener.id);
      });
      api.addEventListener('filmstripDisplayChanged', (listener) => {
        setfilmstripEnabled(listener.visible);
      });
    } catch (error) {
      console.error('Failed to load Jitsi API', error);
    }
  };

  useEffect(() => {
    // verify the JitsiMeetExternalAPI constructor is added to the global..
    if (window.JitsiMeetExternalAPI) startConference();
    else alert('Jitsi Meet API script not loaded');
  }, []); //eslint-disable-line

  useEffect(() => {
    if (thisapi) {
      props.setOnlineUsers(thisapi.getParticipantsInfo());
    }
  }, [thisapi, numberOfParticipants]); //eslint-disable-line

  useEffect(() => {
    if (props.minijitsi === 1) {
      if (props.position === 'br') {
        thisapi.executeCommand('setTileView', false);
        if (ownJitsiId !== dominantSpeaker) {
          thisapi.executeCommand('setLargeVideoParticipant', dominantSpeaker);
        }
      }
      /*  if (props.position === 'big') {
        thisapi.executeCommand('setTileView', true);
      } */
      thisapi.addEventListener('participantRoleChanged', function (event) {
        if (event.role === 'moderator') {
          thisapi.executeCommand('toggleLobby', false);
        }
      });

      thisapi.executeCommand('toggleFilmStrip');
      if (props.exit) {
        thisapi.executeCommand('stopRecording', 'file');
        thisapi.executeCommand('hangup');
        setjitsiContainerzIndex(-1000);
        setjitsiContainerOverlayzIndex(-1000);
      }
      thisapi.addEventListener('participantJoined', function (event) {
        props.setOnlineUsers(thisapi.getParticipantsInfo());
        loggingData.setSequence({
          type: 'userJoints',
          value: {
            number: thisapi.getNumberOfParticipants(),
            detail: thisapi.getParticipantsInfo(),
          },
        });
      });
      thisapi.addEventListener('participantLeft', function (event) {
        props.setOnlineUsers(thisapi.getParticipantsInfo());
      });
    }
  }, [props.position, props.exit, numberOfParticipants, dominantSpeaker]); //eslint-disable-line

  useEffect(() => {
    if (props.minijitsi === 1) {
      if (props.recordAllow) {
        thisapi.executeCommand('startRecording', { mode: 'file' });
      } else {
        thisapi.executeCommand('stopRecording', 'file');
      }
    }
  }, [props.recordAllow]); //eslint-disable-line

  if (props.screenshare) {
    thisapi.executeCommand('toggleShareScreen');
  }

  return (
    <div className={classes.content}>
      <div className={classes.toolbar} />

      <div>
        {props.position !== 'big' ? (
          <Zoom in={zoomJitsi} style={{ transitionDelay: zoomJitsi ? '200ms' : '0ms' }}>
            <div
              id="jitsistyleoverlay"
              className={classes.jitsistyleoverlay}
              ref={jitsistyleoverlay}
              onPointerEnter={_onMouseEnter}
              onPointerLeave={_onMouseOut}
              onPointerUp={_onMouseUp}
              onPointerDown={_onMouseDown}
              onPointerMove={_onMouseMove}
              onPointerCancel={_onMouseUp}
            >
              <div className={classes.overlayMinimizer}>
                <IconButton onClick={() => handleMinimizeMinijitsi()}>
                  <ToggleIcon
                    on={dropdownArrow}
                    onIcon={<ArrowDropUpIcon color={stylingData.colors.color3} fontSize="large" />}
                    offIcon={
                      <ArrowDropDownIcon color={stylingData.colors.color3} fontSize="large" />
                    }
                  />
                </IconButton>
              </div>
            </div>
          </Zoom>
        ) : null}

        <Zoom in={zoomJitsi} style={{ transitionDelay: zoomJitsi ? '200ms' : '0ms' }}>
          <div
            id="jitsi-container"
            className={props.position === 'big' ? classes.jitsistyle : classes.jitsiministyle}
            ref={jitsicontainer}
          />
        </Zoom>
      </div>
    </div>
  );
};

export default JitsiMeetComponent;
