import React, { Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import { LoginContext } from '../../context/LoginContext';
import { UrlContext } from '../../context/UrlContext';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  goodby: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(10),
  },
}));

const ThankYou = (props) => {
  const classes = useStyles();
  const urlData = useContext(UrlContext);
  const loginData = useContext(LoginContext);
  return (
    <Fragment>
      <Dialog fullScreen open={props.goodbyDialog} TransitionComponent={Transition}>
        <Grid
          container
          justify="center"
          alignItems="center"
          alignContent="center"
          className={classes.goodby}
        >
          <Grid item>
            <Typography variant="h4" color="primary">
              Vielen Dank, dass Sie {urlData.urlList.companyname} Connect benutzt haben.
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          alignContent="center"
          className={classes.goodby}
        >
          <Grid item>
            {!loginData.agent ? (
              <Typography variant="h5" color="primary">
                Sie können das Browserfenster nun schließen
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      </Dialog>
    </Fragment>
  );
};

export default ThankYou;
