import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Badge from '@material-ui/core/Badge';
import { Beforeunload } from 'react-beforeunload';
import RecordOnButton from '../s_components/RecordOnButton';
import Bell from '../s_components/Bell';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import {
  faHome,
  faPhotoVideo,
  faKeyboard,
  faFileSignature,
  faIdCardAlt,
  faVideo,
  faUserPlus,
  faFileAlt,
  faChalkboardTeacher,
  faCog,
  faPoll,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ChatIcon from '@material-ui/icons/Chat';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

import Home from '../Home';
import Whiteboard from '../Whiteboard';
import Dokumente from '../Dokumente';
import Unterschrift from '../Unterschrift';
import VertragContainer from '../VertragContainer';
import JitsiMeetComponent from '../JitsiMeetComponent';
import Feedback from '../dialogues/Feedback';
import AddUser from '../dialogues/AddUser';
import { ApellaContext } from '../../context/ApellaContext';
import { LoginContext } from '../../context/LoginContext';
import { StylingContext } from '../../context/StylingContext';
import { UrlContext } from '../../context/UrlContext';
import { LoggingContext } from '../../context/LoggingContext';
import { saveRoom } from '../../helper/saveRoom';
import Editor from '../Editor';
import Settings from '../dialogues/Settings';
import ChatDrawer from './ChatDrawer';
import Screenshare from '../dialogues/Screenshare.js';
import CloseRoom from '../dialogues/CloseRoom.js';
import ThankYou from '../dialogues/ThankYou';
import AskForUpload from '../dialogues/AskForUpload';
import { updateLog } from '../../helper/getData';
import SelectPolls from '../SelectPolls';

React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const drawerWidth = 240;
const chatDrawerwidth = 400;

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: 'white',
    /* boxShadow: `0 0 0 2px ${theme.palette.background.paper}`, */
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const AgentDrawer = (props) => {
  const [backgroundWidth, setbackgroundWidth] = useState(window.innerWidth);
  const [backgroundHeight, setBackgroundHeight] = useState(window.innerHeight);
  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    appBarShiftRight: {
      marginRight: chatDrawerwidth,
      width: `calc(100% - ${chatDrawerwidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    appBarShiftBoth: {
      marginRight: chatDrawerwidth,
      marginLeft: drawerWidth,
      width: `calc(100% - ${chatDrawerwidth + drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    chatButton: {},
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      paddingLeft: theme.spacing(3),
      paddingTop: theme.spacing(3),
      paddingRight: 0,
      paddingBottom: theme.spacing(3),
      width: '100%',
      height: '100%',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -chatDrawerwidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    },
    barContent: {
      marginRight: 300,
      marginLeft: 10,
    },
    avatar: {
      marginLeft: 'auto',
    },
    whiteboardLink: {
      width: 'fit-content',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.secondary,
      '& svg': {
        margin: theme.spacing(1.5),
      },
      '& hr': {
        margin: theme.spacing(0, 0.5),
      },
    },
    snackbar: {
      color: 'white',
      background: '006c52',
    },
    goodby: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(10),
    },
    snackbarError: {
      background: '#ff6300',
    },
    bell: {
      marginRight: 30,
    },
    recordButton: {},
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
    background: {
      zIndex: -1,
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: 'white',
      width: backgroundWidth,
      height: backgroundHeight,
    },
    feedbackButton: {
      marginRight: 20,
    },
    settingsButton: {
      marginTop: 'auto',
    },
    avatarDiv: {
      marginRight: 3,
    },
    popoverText: {
      padding: theme.spacing(2),
    },
    exit: {
      marginRight: 15,
    },
  }));
  const data = useContext(ApellaContext);
  const loginData = useContext(LoginContext);
  const stylingData = useContext(StylingContext);
  const urlData = useContext(UrlContext);
  const loggingData = useContext(LoggingContext);
  const wsuri = urlData.urlList.websocketBaseUrl;
  const [globalWs, setglobalWs] = useState(new WebSocket(wsuri)); //eslint-disable-line
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [mainContent, setMainContent] = useState();
  const [minijitsi, setminijitsi] = useState(0);
  const [position, setposition] = useState('big');
  const [openDialog, setopenDialog] = useState(false);
  const [record, setrecord] = useState(false);
  const [recordAllow, setrecordAllow] = useState(false);
  const [screenshare, setscreenshare] = useState(false);
  const [goodbyDialog, setgoodbyDialog] = useState(false);
  const [openExpertDialog, setopenExpertDialog] = useState(false);
  const [showSignedIcon, setshowSignedIcon] = useState(false);
  const [openFeedbackDialog, setopenFeedbackDialog] = useState(false);
  const [exitConference, setexitConference] = useState(false);
  const [showWhiteboardDialog, setshowWhiteboardDialog] = useState(true);
  const [settings, setsettings] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState(null);
  const [openChatDrawer, setopenChatDrawer] = useState(false);
  const [websocketmessages, setwebsocketmessages] = useState([]);
  const [newWebsocketmessages, setnewWebsocketmessages] = useState(0);
  const [locked, setlocked] = useState(false);
  const [browserVersion, setbrowserVersion] = useState();
  const [openScreenshareDialogue, setopenScreenshareDialogue] = useState(false);
  const [openPollDialog, setopenPollDialog] = useState(false); // eslint-disable-line -- useState
  const [askForUploadDialog, setaskForUploadDialog] = useState(false);
  const [showMenu, setshowMenu] = useState(true); // eslint-disable-line -- useState
  const [showClientMenu, setshowClientMenu] = useState(false);

  useEffect(() => {
    if (showClientMenu) {
      send(JSON.stringify({ command: 'ee0fa100-80ce-4ee1-97b6-27758caa435c' }));
    } else {
      send(JSON.stringify({ command: 'f897704c-338f-40aa-b453-a7a80e97f8e7' }));
    }
    // eslint-disable-next-line
  }, [showClientMenu]);

  const resizeBackground = () => {
    setbackgroundWidth(window.innerWidth);
    setBackgroundHeight(window.innerHeight);
  };
  window.addEventListener('resize', resizeBackground);

  const handleWhiteboardDialog = () => {
    setshowWhiteboardDialog((prev) => !prev);
  };

  let wsIntervall;
  const connect = () => {
    globalWs.onopen = () => {
      console.log('connection ready');
      globalWs.send(
        `{"action":"enterRoom", "data":"${data.roomData.roomHash}", "user":"${data.agentData.id}"}`
      );
      let tempRoomData = data.roomData;
      tempRoomData.locked = false;
      data.setroomData(tempRoomData);
      handleSave();
      send(JSON.stringify({ command: 'e10bc53c-2ead-42f8-a83d-969780f6ab3e' }));
    };
    globalWs.onmessage = (e) => {
      if (
        e.data.command === '4372079c-59f2-4b0e-8de2-a199d964e9d7' ||
        e.data.command === 'ad50f2cf-4e3f-48a3-adea-487d6685b30f'
      ) {
        handleUpdateDocumentTable(e.data.command);
      }
    };

    wsIntervall = setInterval(() => {
      send(JSON.stringify({ command: 'b7f69ddc-123c-4bfb-810b-6e0a922e74a3' }));
    }, 540000);
  };
  useEffect(() => {
    loggingData.setSequence({
      type: 'startMeeting',
      agent: {
        id: data.agentData.agentData.id,
        firstName: data.agentData.agentData.firstName,
        lastName: data.agentData.agentData.lastName,
      },
      client: {
        id: data.clientData.clientData.id,
        firstName: data.clientData.clientData.firstName,
        lastName: data.clientData.clientData.lastName,
      },
      consultationDate: data.roomData.consultationDate,
      consultationTitle: data.roomData.consultationTitle,
      roomHash: data.roomData.roomHash,
    });
    connect();
  }, []); // eslint-disable-line -- only run on componentload

  globalWs.onmessage = (e) => {
    let decodedData = decodeURI(e.data);
    let decodedJson = JSON.parse(decodedData);
    let command = decodedJson.command;
    if (command === '85f79357-33d8-438f-8025-9e108f159c8f') {
      setwebsocketmessages([...websocketmessages, decodedJson]);
      setnewWebsocketmessages((prev) => prev + 1);
    }
    if (command === 'e7c0346a-0e83-4afd-9755-4b681aac2bcc') {
      handleRecord(command);
    }

    if (e.data.includes('connectionId')) {
      let agentConnectionId = `${e.data
        .slice(e.data.indexOf('connectionId'), e.data.indexOf(', "requestId"'))
        .replace(/"/g, '')
        .replace('connectionId:', '')}`;
      data.setagentConnectionId(agentConnectionId);
      handleSave();
    }
    if (command === '7b3ddf5e-c41b-42e3-a365-c0cc2f1bdabf') {
      handleShowSignedIcon();
      handleUpdateDocumentTable('ad50f2cf-4e3f-48a3-adea-487d6685b30f');
      send(JSON.stringify({ command: '4372079c-59f2-4b0e-8de2-a199d964e9d7' }));
    } else {
      handleListItemClick(command);
    }
  };

  const exitRoom = () => {
    let tempRoomData = data.roomData;
    if (locked === true) {
      tempRoomData.locked = true;
      data.setroomData(tempRoomData);
      handleSave();
      console.log('connection exited');
      globalWs.send(
        `{ "action": "exitRoom", "data": "${data.roomData.roomHash}", "user":"${data.agentData.id}"}`
      );
    }
    globalWs.close();
    clearInterval(wsIntervall);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (navigator.userAgent.includes('Firefox')) {
      setbrowserVersion('Firefox');
    }
    if (navigator.userAgent.includes('Safari') || navigator.userAgent.includes('Chrome')) {
      setbrowserVersion('Chrome');
    }
    setMainContent(<Home />);
    setminijitsi(1);
    setposition('big');
  }, []);

  useEffect(() => {
    handleSave();
  }, [data.agentConnectionId]); //eslint-disable-line

  const handlejitsi = () => {
    setposition('br');
    setminijitsi(1);
  };

  const handleShowSignedIcon = () => {
    setshowSignedIcon(true);
  };

  const handleCloseSignButton = () => {
    setshowSignedIcon(false);
  };

  const send = (msg) => {
    if (globalWs.readyState === 1) {
      const str = `${encodeURI(msg)}`;
      globalWs.send(`{ "action": "message", "data": "${str}"}`);
    }
  };

  const sendChatmessage = (msg) => {
    let str = '';
    if (globalWs.readyState === 1) {
      if (loginData.agent) {
        str = `${JSON.stringify({
          command: '85f79357-33d8-438f-8025-9e108f159c8f',
          message: msg,
          user: {
            firstName: data.agentData.agentData.firstName,
            lastName: data.agentData.agentData.lastName,
            photo: data.agentData.agentData.photo,
            timestamp: Date.now(),
          },
        })}`;
      } else if (loginData.client) {
        str = `${JSON.stringify({
          command: '85f79357-33d8-438f-8025-9e108f159c8f',
          message: msg,
          user: {
            firstName: loginData.user.firstName,
            lastName: loginData.user.lastName,
            photo: data.clientData.clientData.photo,
            timestamp: Date.now(),
          },
        })}`;
      } else {
        str = `${JSON.stringify({
          command: '85f79357-33d8-438f-8025-9e108f159c8f',
          message: msg,
          user: {
            firstName: loginData.user.firstName,
            lastName: loginData.user.lastName,
            timestamp: Date.now(),
          },
        })}`;
      }
      send(str);
    }
  };

  const openSettings = () => {
    setsettings(true);
  };

  const handleCloseScreenshareDialogue = () => {
    setopenScreenshareDialogue(false);
  };

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
    switch (index) {
      case 'Menu0':
        setMainContent(<Home />);
        setposition('br');
        handlejitsi();
        console.log('connection ready');
        loggingData.setSequence({ type: 'homeButton' });
        break;
      case 'Menu1':
        setposition('big');
        handleDrawerClose();
        loggingData.setSequence({ type: 'videoButton' });
        break;
      case 'Menu2':
        setMainContent(<Editor />);
        handlejitsi();
        handleDrawerClose();
        loggingData.setSequence({ type: 'editorButton' });
        break;
      case 'Menu3':
        setMainContent(
          <Whiteboard
            showWhiteboardDialog={showWhiteboardDialog}
            setshowWhiteboardDialog={setshowWhiteboardDialog}
            handleWhiteboardDialog={handleWhiteboardDialog}
            showMenu={showMenu}
          />
        );
        handlejitsi();
        handleDrawerClose();
        loggingData.setSequence({ type: 'whiteboardButton' });
        break;
      case 'Menu4':
        setMainContent(<VertragContainer />);
        handlejitsi();
        loggingData.setSequence({ type: 'contractsButton' });
        break;
      /* case 'Menu5':
        setMainContent(<Dokumente send={send} />);
        handlejitsi();
        loggingData.setSequence({ type: 'documentsButton' });
        break;
      case 'Menu6':
        setMainContent(
          <Unterschrift
            send={send}
            setopenScreenshareDialogue={setopenScreenshareDialogue}
            browserVersion={browserVersion}
            setscreenshare={setscreenshare}
          />
        );
        handlejitsi();
        loggingData.setSequence({ type: 'signButton' });
        break; */
      case 'Menu9':
        if (!recordAllow) send(JSON.stringify({ command: 'ef17908f-12a7-4cd6-aedb-dc600e4b6214' }));
        setrecordAllow((prev) => !prev);
        setrecord((prev) => !prev);
        loggingData.setSequence({ type: 'recordButton' });
        break;
      case 'Menu10':
        setposition('big');
        if (!screenshare) setscreenshare(true);
        setTimeout(() => {
          setscreenshare(false);
        }, 1000);
        if (browserVersion !== 'Chrome') {
          setopenScreenshareDialogue(true);
        }
        handleDrawerClose();
        send(JSON.stringify({ command: '5edcf140-096f-49e0-91d8-44f87e79d1f7' }));
        loggingData.setSequence({ type: 'screensharingButton' });
        break;
      case 'Menu11':
        handleExpertClick();
        loggingData.setSequence({ type: 'addParticipantButton' });
        break;
      case 'Menu12':
        openSettings();
        loggingData.setSequence({ type: 'settingsButton' });
        break;
      case 'Menu13':
        setMainContent(
          <SelectPolls
            handleStartPoll={handleStartPoll}
            handleStopPoll={handleStopPoll}
            handleShowPollResults={handleShowPollResults}
            stopShowPollResults={stopShowPollResults}
            handleShowComparePollResults={handleShowComparePollResults}
            handleStopShowComparePollResults={handleStopShowComparePollResults}
            origin2="agentDrawer"
          />
        );
        setposition('br');
        handlejitsi();
        console.log('connection ready');
        loggingData.setSequence({ type: 'poll' });
        break;
      default:
    }
  };

  const handleExpertClick = () => {
    setopenExpertDialog(true);
  };

  const handleCloseExpertDialog = () => {
    setopenExpertDialog(false);
  };

  useEffect(() => {
    handleSave();
  }, [data.experts]); //eslint-disable-line

  const handleSave = () => {
    saveRoom(
      data.apiToken,
      data.agentData,
      data.clientData,
      data.roomData,
      data.documents,
      data.url,
      data.insignData,
      data.activeStep,
      data.otpKey,
      data.companyIdentification,
      data.stage,
      data.contextID,
      data.agentConnectionId,
      data.experts,
      data.agendaText,
      stylingData.colors,
      urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.updateRoom.endpoint
    );
  };

  const akronym = (user) => {
    console.log('user', user);
    if (user.firstName == null) {
      if (user.lastName != null) {
        return user.lastName.substr(0, 1);
      }
      return 'A';
    }
    if (user.firstName != null) {
      if (user.lastName != null) {
        let firstLetter = user.firstName.substr(0, 1);
        let lastLetter = user.lastName.substr(0, 1);
        return firstLetter + lastLetter;
      }
      return user.firstName.substr(0, 1);
    }

    return 'A';
  };

  const handleOpenDialog = () => {
    setopenDialog(true);
  };
  const handleCloseDialog = () => {
    setopenDialog(false);
  };

  const handleChatDrawerOpen = () => {
    setopenChatDrawer(true);
  };

  const handleChatDrawerClose = () => {
    setnewWebsocketmessages(0);
    setopenChatDrawer(false);
  };

  const saveAttributes = (attribute, value) => {
    let room = data.roomData;
    room[attribute] = value;
    data.setroomData(room);
  };

  const handleRecord = (e) => {
    if (e === 'e7c0346a-0e83-4afd-9755-4b681aac2bcc') {
      setrecord((record) => !record);
      setrecordAllow((allow) => !allow);
    }
    if (e === '1211e00a-aa4b-4671-ba62-9e873736b0f8') {
      setrecord(false);
      setrecordAllow(false);
    }
  };

  const handleUpdateDocumentTable = (e) => {
    if (e === 'ad50f2cf-4e3f-48a3-adea-487d6685b30f') data.setforceUpdateTableView((prev) => !prev);
  };

  const handleStartPoll = (runningPoll, pollResultId) => {
    setopenPollDialog(true);
    console.log('Umfrage gestartet');
    if (globalWs.readyState === 1) {
      send(
        JSON.stringify({
          command: '05442d32-95e3-4026-8d21-49ef85c5cb8b',
          runningPoll: runningPoll,
          pollResultId: pollResultId,
        })
      );
    }
  };

  const handleStopPoll = () => {
    if (globalWs.readyState === 1) {
      send(JSON.stringify({ command: 'dca139c6-eff6-4a17-85bd-b166375b9081' }));
    }
  };

  const handleShowPollResults = (pollResultId) => {
    if (globalWs.readyState === 1) {
      send(
        JSON.stringify({
          command: '38ef8255-528e-4efd-8986-6beebe765fcc',
          pollResultId: pollResultId,
        })
      );
    }
  };

  const handleShowComparePollResults = (comparePollResultsIndexArray) => {
    if (globalWs.readyState === 1) {
      send(
        JSON.stringify({
          command: 'a0e00423-d8c8-47f9-8ed6-6c41287432c2',
          comparePollResultsIndexArray: comparePollResultsIndexArray,
        })
      );
    }
  };

  const handleStopShowComparePollResults = () => {
    if (globalWs.readyState === 1) {
      send(
        JSON.stringify({
          command: '1e6cee6e-c930-44ca-a65b-9478ea557ee1',
        })
      );
    }
  };

  const stopShowPollResults = () => {
    if (globalWs.readyState === 1) {
      send(
        JSON.stringify({
          command: 'def925e9-3833-4f26-b017-74d6af166daa',
        })
      );
    }
  };

  const closeRoom = async () => {
    if (locked === true) {
      send(JSON.stringify({ command: '740de8e1-ca0c-46b7-b59c-6be07e96dd29' }));
    }
    /* if (data.documents.length !== 0) {
      setaskForUploadDialog(true);
      //TODO: für finacom raus
      setaskForUploadDialog(false);
    } else { */
    setgoodbyDialog(true);
    setTimeout(exitRoom, 3000);
    setTimeout(setopenFeedbackDialog(true), 3000);
    setexitConference(true);
    /*  } */
  };

  const closeTheRoomFinally = async () => {
    setgoodbyDialog(true);
    setTimeout(exitRoom, 3000);
    setTimeout(setopenFeedbackDialog(true), 3000);
    setexitConference(true);
    loggingData.setSequence({ type: 'exitConference' });
    updateLog({
      agentId: data.agentData.id,
      date: loggingData.theLogDateAndTime,
      sequence: JSON.parse(localStorage.getItem('sequence')),
      companyIdentification: data.companyIdentification,
      contextID: data.contextID,
      stage: data.stage,
      otpKey: data.otpKey,
      urlParam: urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.updateLog.endpoint,
      apiToken: data.apiToken,
    });
  };

  const handleFeedbackDialog = () => {
    setopenFeedbackDialog(true);
  };

  const handleFeedbackDialogClose = () => {
    setopenFeedbackDialog(false);
  };

  const onlineUserStatus = (user) => {
    return onlineUsers.some((participant) => participant.displayName === user);
  };

  const handleMiddleAvatar = () => {
    if (data.clientData.id !== 'xxxxx') {
      if (loginData.client) {
        if (onlineUsers !== undefined && onlineUsers !== null) {
          if (
            onlineUserStatus(
              `${data.agentData.agentData.firstName} ${data.agentData.agentData.lastName}`
            )
          ) {
            return (
              <div className={classes.avatar}>
                <StyledBadge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  variant="dot"
                >
                  <Avatar
                    src={`${data.agentData.agentData.photo}`}
                    alt={`${data.agentData.agentData.firstName} ${data.agentData.agentData.lastName}`}
                  >
                    {akronym(data.agentData.agentData, data.agentData.agentData)}
                  </Avatar>
                </StyledBadge>
              </div>
            );
          } else {
            return (
              <div className={classes.avatar}>
                <Avatar
                  src={`${data.agentData.agentData.photo}`}
                  alt={`${data.agentData.agentData.firstName} ${data.agentData.agentData.lastName}`}
                >
                  {akronym(data.agentData.agentData, data.agentData.agentData)}
                </Avatar>
              </div>
            );
          }
        } else {
          return (
            <div className={classes.avatar}>
              <Avatar
                src={`${data.agentData.agentData.photo}`}
                alt={`${data.agentData.agentData.firstName} ${data.agentData.agentData.lastName}`}
              >
                {akronym(data.agentData.agentData, data.agentData.agentData)}
              </Avatar>
            </div>
          );
        }
      } else {
        if (onlineUsers !== undefined && onlineUsers !== null) {
          if (
            onlineUserStatus(
              `${data.clientData.clientData.firstName} ${data.clientData.clientData.lastName}`
            )
          ) {
            return (
              <div className={classes.avatar}>
                <StyledBadge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  variant="dot"
                >
                  <Avatar
                    src={`${data.clientData.clientData.photo}`}
                    alt={`${data.clientData.clientData.firstName} ${data.clientData.clientData.lastName}`}
                  >
                    {akronym(data.clientData.clientData, data.clientData.clientData)}
                  </Avatar>
                </StyledBadge>
              </div>
            );
          } else {
            return (
              <div className={classes.avatar}>
                <Avatar
                  src={`${data.clientData.clientData.photo}`}
                  alt={`${data.clientData.clientData.firstName} ${data.clientData.clientData.lastName}`}
                >
                  {akronym(data.clientData.clientData, data.clientData.clientData)}
                </Avatar>
              </div>
            );
          }
        } else {
          return (
            <div className={classes.avatar}>
              <Avatar
                src={`${data.clientData.clientData.photo}`}
                alt={`${data.clientData.clientData.firstName} ${data.clientData.clientData.lastName}`}
              >
                {akronym(data.clientData.clientData, data.clientData.clientData)}
              </Avatar>
            </div>
          );
        }
      }
    } else return <div className={classes.avatar}></div>;
  };

  const lockRoom = () => {
    let tempRoomData = data.roomData;
    if (locked) {
      send(JSON.stringify({ command: '437603fa-bf60-4b6e-9015-dad32b00338d' }));
      tempRoomData.locked = true;
      data.setroomData(tempRoomData);
      handleSave();
    } else {
      send(JSON.stringify({ command: 'e10bc53c-2ead-42f8-a83d-969780f6ab3e' }));
      tempRoomData.locked = false;
      data.setroomData(tempRoomData);
      handleSave();
    }
  };

  useEffect(() => {
    if (data.apiToken) {
      const interval = setInterval(() => {
        updateLog({
          agentId: data.agentData.id,
          date: loggingData.theLogDateAndTime,
          sequence: JSON.parse(localStorage.getItem('sequence')),
          companyIdentification: data.companyIdentification,
          contextID: data.contextID,
          stage: data.stage,
          otpKey: data.otpKey,
          urlParam:
            urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.updateLog.endpoint,
          apiToken: data.apiToken,
        });
      }, 60000);

      return () => clearInterval(interval);
    }
  }, [data.apiToken]); //eslint-disable-line

  return (
    <ApellaContext.Consumer>
      {(context) => (
        <div className={classes.root}>
          <CssBaseline />
          <Beforeunload
            onBeforeunload={(event) => {
              event.preventDefault();
              event.returnValue = '';
              exitRoom();
            }}
          />
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open && !openChatDrawer,
              [classes.appBarShiftRight]: openChatDrawer && !open,
              [classes.appBarShiftBoth]: open && openChatDrawer,
            })}
          >
            <Toolbar>
              <IconButton
                id="open_drawer"
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                {urlData.urlList.companyname} Connect
              </Typography>
              {handleMiddleAvatar()}
              {data.clientData.id !== 'xxxxx' ? (
                <Typography className={classes.barContent} variant="h6" noWrap>
                  {`${context.clientData.clientData.firstName}  ${context.clientData.clientData.lastName}`}
                </Typography>
              ) : null}
              <Button
                id="feedback_button"
                disableElevation
                variant="contained"
                color="secondary"
                aria-label="open drawer"
                onClick={handleFeedbackDialog}
                className={classes.feedbackButton}
              >
                Feedback
              </Button>
              {showSignedIcon ? (
                <div>
                  <Bell handleCloseSignButton={handleCloseSignButton} />
                </div>
              ) : null}
              {onlineUsers !== undefined && onlineUsers !== null
                ? onlineUsers.map((user) =>
                    !user.formattedDisplayName.includes('(ich)') &&
                    user.displayName !==
                      `${context.clientData.clientData.firstName} ${context.clientData.clientData.lastName}` ? (
                      <div className={classes.avatarDiv} key={user.displayName}>
                        <StyledBadge
                          overlap="circle"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          variant="dot"
                        >
                          <Avatar
                            className={classes.avatar}
                            alt={`${user.displayName.split(' ')[0]}  ${
                              user.displayName.split(' ')[1]
                            }`}
                          >
                            {akronym({
                              firstName: user.displayName.split(' ')[0],
                              lastName: user.displayName.split(' ')[1],
                            })}
                          </Avatar>
                        </StyledBadge>
                      </div>
                    ) : null
                  )
                : null}
              <IconButton
                color="secondary"
                onClick={() => {
                  loggingData.setSequence({ type: 'exitButton' });
                  handleOpenDialog();
                }}
                className={classes.exit}
                id="exit_button"
              >
                <PowerSettingsNewIcon />
                <Typography variant="h6" noWrap>
                  Exit
                </Typography>
              </IconButton>
              <IconButton
                color="inherit"
                aria-label="open chat"
                onClick={() => setopenChatDrawer((prev) => !prev)}
                edge="start"
                className={clsx(classes.chatButton, {
                  [classes.hide]: openChatDrawer,
                })}
              >
                <Badge badgeContent={newWebsocketmessages} color="secondary">
                  <ChatIcon />
                </Badge>
              </IconButton>
            </Toolbar>
          </AppBar>

          <SwipeableDrawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
          >
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </div>
            <Divider />
            <List>
              <ListItem
                className={classes.root}
                button
                selected={selectedIndex === 'Menu0'}
                onClick={(event) => {
                  handleListItemClick('Menu0');
                  send(JSON.stringify({ command: '78af9440-cc6b-4940-b69a-812dbaad1e93' }));
                }}
              >
                <ListItemIcon>
                  <IconButton>
                    <FontAwesomeIcon icon={faHome} />
                  </IconButton>
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
              <ListItem
                id="homeButton"
                button
                selected={selectedIndex === 'Menu1'}
                onClick={(event) => {
                  handleListItemClick('Menu1');
                  send(JSON.stringify({ command: '412c1249-75d3-4d21-96bf-27c5ca272bac' }));
                }}
              >
                <ListItemIcon>
                  <IconButton>
                    <FontAwesomeIcon icon={faPhotoVideo} />
                  </IconButton>
                </ListItemIcon>
                <ListItemText primary="Vollbildmodus" />
              </ListItem>
              <ListItem
                button
                selected={selectedIndex === 'Menu2'}
                onClick={(event) => {
                  handleListItemClick('Menu2');
                  send(JSON.stringify({ command: 'e22fcb43-8212-4974-8a90-6fbac9edc5d3' }));
                }}
              >
                <ListItemIcon>
                  <IconButton>
                    <FontAwesomeIcon icon={faKeyboard} />
                  </IconButton>
                </ListItemIcon>
                <ListItemText primary="Editor" />
              </ListItem>
              <ListItem
                button
                selected={selectedIndex === 'Menu3'}
                onClick={(event) => {
                  handleListItemClick('Menu3');
                  send(JSON.stringify({ command: '54341296-b426-43d1-9206-903307f7407a' }));
                }}
              >
                <ListItemIcon>
                  <IconButton>
                    <FontAwesomeIcon icon={faChalkboardTeacher} />
                  </IconButton>
                </ListItemIcon>
                <ListItemText primary="Whiteboard" />
              </ListItem>
              {data.clientData.id !== 'xxxxx' ? (
                <ListItem
                  button
                  selected={selectedIndex === 'Menu4'}
                  onClick={(event) => {
                    handleListItemClick('Menu4');
                    send(JSON.stringify({ command: '0b7f2a1e-6a0d-4692-b5b9-89613c810a1f' }));
                  }}
                >
                  <ListItemIcon>
                    <IconButton>
                      <FontAwesomeIcon icon={faIdCardAlt} />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary="Verträge" />
                </ListItem>
              ) : null}
              {/* <ListItem
                button
                selected={selectedIndex === 'Menu5'}
                onClick={(event) => {
                  handleListItemClick('Menu5');
                  send(JSON.stringify({ command: '605649c1-b8f4-4eef-b432-f0f6de1a37ba' }));
                }}
              >
                <ListItemIcon>
                  <IconButton>
                    <FontAwesomeIcon icon={faFileAlt} />
                  </IconButton>
                </ListItemIcon>
                <ListItemText primary="Dokumente" />
              </ListItem> */}
              {/*  <ListItem
                button
                selected={selectedIndex === 'Menu6'}
                onClick={(event) => {
                  handleListItemClick('Menu6');
                  send(JSON.stringify({ command: '7570c1f3-7fa7-499e-a08d-6bcb9edb35c4' }));
                }}
              >
                <ListItemIcon>
                  <IconButton>
                    <FontAwesomeIcon icon={faFileSignature} />
                  </IconButton>
                </ListItemIcon>
                <ListItemText primary="Unterschrift" />
              </ListItem> */}
              <ListItem
                button
                selected={selectedIndex === 'Menu9'}
                onClick={(event) => {
                  handleListItemClick('Menu9');
                }}
              >
                {record ? (
                  <ListItemIcon color="secondary">
                    <IconButton color="secondary">
                      <RecordOnButton />
                    </IconButton>
                  </ListItemIcon>
                ) : (
                  <ListItemIcon>
                    <IconButton>
                      <FontAwesomeIcon icon={faVideo} />
                    </IconButton>
                  </ListItemIcon>
                )}
                <ListItemText primary="Aufnahme" />
              </ListItem>
              <ListItem
                button
                selected={selectedIndex === 'Menu10'}
                onClick={(event) => {
                  handleListItemClick('Menu10');
                }}
              >
                <ListItemIcon>
                  <IconButton>
                    <ScreenShareIcon /* fontSize="large" */ />
                  </IconButton>
                </ListItemIcon>
                <ListItemText primary="Screensharing" />
              </ListItem>
              <ListItem
                button
                selected={selectedIndex === 'Menu13'}
                onClick={(event) => {
                  handleListItemClick('Menu13');
                }}
              >
                <ListItemIcon>
                  <IconButton>
                    <FontAwesomeIcon icon={faPoll} />
                  </IconButton>
                </ListItemIcon>
                <ListItemText primary="Umfragen" />
              </ListItem>
              <ListItem
                button
                selected={selectedIndex === 'Menu11'}
                onClick={(event) => {
                  handleListItemClick('Menu11');
                }}
              >
                <ListItemIcon>
                  <IconButton>
                    <FontAwesomeIcon icon={faUserPlus} />
                  </IconButton>
                </ListItemIcon>
                <ListItemText primary="Teilnehmer" />
              </ListItem>
            </List>
            <Divider />
            <div className={classes.settingsButton}>
              <List>
                <ListItem
                  button
                  selected={selectedIndex === 'Menu12'}
                  onClick={(event) => {
                    handleListItemClick('Menu12');
                  }}
                >
                  <ListItemIcon>
                    <IconButton>
                      <FontAwesomeIcon icon={faCog} />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary="Einstellungen" />
                </ListItem>
              </List>
            </div>
          </SwipeableDrawer>
          <div className={classes.background}></div>
          <div
            className={clsx(classes.content, {
              [classes.contentShift]: openChatDrawer,
            })}
          >
            {mainContent}
          </div>
          <ChatDrawer
            openChatDrawer={openChatDrawer}
            handleChatDrawer={handleChatDrawerOpen}
            handleChatDrawerClose={handleChatDrawerClose}
            chatDrawerwidth={chatDrawerwidth}
            websocketmessages={websocketmessages}
            sendChatmessage={sendChatmessage}
          />
          <JitsiMeetComponent
            showMenu={showMenu}
            position={position}
            minijitsi={minijitsi}
            recordAllow={recordAllow}
            screenshare={screenshare}
            exit={goodbyDialog}
            setOnlineUsers={setOnlineUsers}
            audioOnly={props.audioOnly}
            openChatDrawer={openChatDrawer}
            openMenu={open}
            askForUploadDialog={askForUploadDialog}
          />
          {openFeedbackDialog ? (
            <Feedback
              openFeedbackDialog={openFeedbackDialog}
              closeFeedbackDialog={handleFeedbackDialogClose}
              exitConference={exitConference}
            />
          ) : null}
          {openExpertDialog ? (
            <AddUser
              openExpertDialog={openExpertDialog}
              setopenExpertDialog={setopenExpertDialog}
              handleCloseExpertDialog={handleCloseExpertDialog}
              onlineUsers={onlineUsers}
            />
          ) : null}
          <ThankYou goodbyDialog={goodbyDialog} />
          <CloseRoom
            openDialog={openDialog}
            locked={locked}
            setlocked={setlocked}
            saveAttributes={saveAttributes}
            handleSave={handleSave}
            handleCloseDialog={handleCloseDialog}
            closeRoom={closeRoom}
          />
          <AskForUpload
            askForUploadDialog={askForUploadDialog}
            setaskForUploadDialog={setaskForUploadDialog}
            closeTheRoomFinally={closeTheRoomFinally}
          />

          <Screenshare
            openScreenshareDialogue={openScreenshareDialogue}
            handleCloseScreenshareDialogue={handleCloseScreenshareDialogue}
          />
          {settings ? (
            <Settings
              openSettings={settings}
              setopenSettings={setsettings}
              showWhiteboardDialog={showWhiteboardDialog}
              handleWhiteboardDialog={handleWhiteboardDialog}
              lockRoom={lockRoom}
              locked={locked}
              setlocked={setlocked}
              showClientMenu={showClientMenu}
              setshowClientMenu={setshowClientMenu}
            />
          ) : null}
        </div>
      )}
    </ApellaContext.Consumer>
  );
};
export default AgentDrawer;
