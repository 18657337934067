/**
 * Zeigt den Abstimmungsdialog
 */
import React, { Fragment, useState, useContext } from 'react';
import ShowPollResults from './ShowPollResults';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { ApellaContext } from '../../context/ApellaContext';
import { useSnackbar } from 'notistack';

import { useQuery, useMutation, useSubscription } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { deleteRunningPoll, createPollResult } from '../../graphql/mutations';
import { getRunningPoll } from '../../graphql/queries';
import { onCreateAndUpdateByRunningPollId } from '../../graphql/subscriptions';

const ShowPoll = ({
  pollIdToRun,
  pollResultId,
  setpollResultId,
  startVote,
  setStartVote,
  openShowPollDialog,
  setOpenShowPollDialog,
  handleStopPoll,
  handleShowPollResults,
  stopShowPollResults,
}) => {
  const contextData = useContext(ApellaContext);
  const [showPollResultsDialog, setShowPollResultsDialog] = useState(false);
  const [deleteTheRunningPoll, resultDeleteTheRunningPoll] = useMutation(deleteRunningPoll); // eslint-disable-line
  const [createThePollResult, resultCreateThePollResult] = useMutation(createPollResult); // eslint-disable-line
  const [votes, setVotes] = useState({});
  const [kindOfChart, setKindOfChart] = useState('');
  const [question, setQuestion] = useState('');
  const [answers, setAnswers] = useState();
  const [multipleAnswer, setmultipleAnswer] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const runningPoll = useQuery(getRunningPoll, {
    fetchPolicy: 'no-cache',
    variables: { id: pollIdToRun },
    onCompleted: (data) => {
      if (startVote) {
        let tempAnswers = [];
        let tempObject = {};

        data.getRunningPoll.poll.answers.forEach((answer) => {
          let vote = {};
          let key = answer.id;
          let value = { answerText: answer.answerText, value: 0 };
          vote[key] = value;
          tempObject = { ...tempObject, ...vote };
          tempAnswers.push({ answerText: answer.answerText, id: answer.id, vote: null });
        });
        setVotes(tempObject);
        setAnswers(tempAnswers);
        setKindOfChart(data.getRunningPoll.poll.kindOfChart);
        setQuestion(data.getRunningPoll.poll.question.questionText);
        setmultipleAnswer(data.getRunningPoll.poll.multipleAnswer);
        setStartVote(false);
      }
    },
  });

  useSubscription(onCreateAndUpdateByRunningPollId, {
    variables: { runningPollId: pollIdToRun, pollResultVotesId: pollResultId },
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      let tempValue = votes[data.onCreateAndUpdateByRunningPollId.answer.id].value;
      let incomingValue = data.onCreateAndUpdateByRunningPollId.answer.vote;
      let tempObject = { ...votes };
      tempObject[data.onCreateAndUpdateByRunningPollId.answer.id].value = tempValue + incomingValue;
      setVotes(tempObject);
    },
  });

  const closeTheShowPollDialog = async () => {
    try {
      handleStopPoll();
      await deleteTheRunningPoll({ variables: { input: { id: pollIdToRun } } });
      setOpenShowPollDialog(false);
      setpollResultId(uuidv4());
    } catch (error) {
      console.log(error);
    }
  };

  const saveRunningPoll = async () => {
    try {
      await createThePollResult({
        variables: {
          input: {
            pollPollResultsId: pollIdToRun,
            id: pollResultId,
            ownerPollResultsId: contextData.agentData.id,
            question: question,
            answers: answers,
            kindOfChart: kindOfChart,
            multipleAnswer: multipleAnswer,
          },
        },
      });
      handleStopPoll();
      setShowPollResultsDialog(true);
    } catch (error) {
      enqueueSnackbar('Ein Fehler beim Speichern der Umfrage ist aufgetreten!', {
        variant: 'error',
      });
    }
  };

  const showQuestionsAndVotes = (answer) => {
    if (votes === {}) {
      return null;
    } else {
      if (votes[answer.id] !== undefined)
        return (
          <TableRow>
            <TableCell key={answer.id}>{votes[answer.id].answerText}</TableCell>
            <TableCell>{votes[answer.id].value}</TableCell>
          </TableRow>
        );
    }
  };

  return (
    <Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={openShowPollDialog}
        onClose={() => closeTheShowPollDialog()}
      >
        {!runningPoll.loading && runningPoll?.data ? (
          <Fragment>
            <DialogTitle>{runningPoll.data.getRunningPoll.poll.question.questionText}</DialogTitle>
            <DialogContent>
              <Table>
                <TableHead>
                  {runningPoll.data.getRunningPoll.poll.multipleAnswer
                    ? 'Mehrfachantwort möglich'
                    : null}
                  <TableRow>
                    <TableCell>Antwort</TableCell>
                    <TableCell>abgegebene Stimmen</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {runningPoll.data.getRunningPoll.poll.answers.map((answer, key) => {
                    return showQuestionsAndVotes(answer);
                  })}
                </TableBody>
              </Table>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  closeTheShowPollDialog();
                }}
                color="secondary"
                variant="outlined"
              >
                Abbrechen
              </Button>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                onClick={() => {
                  saveRunningPoll();
                }}
              >
                Abstimmung beenden
              </Button>
            </DialogActions>
          </Fragment>
        ) : null}
      </Dialog>
      {showPollResultsDialog ? (
        <ShowPollResults
          showPollResultsDialog={showPollResultsDialog}
          setShowPollResultsDialog={setShowPollResultsDialog}
          pollIdToRun={pollIdToRun}
          pollResultId={pollResultId}
          setpollResultId={setpollResultId}
          setOpenShowPollDialog={setOpenShowPollDialog}
          votes={votes}
          kindOfChart={kindOfChart}
          handleShowPollResults={handleShowPollResults}
          stopShowPollResults={stopShowPollResults}
        />
      ) : null}
    </Fragment>
  );
};

export default ShowPoll;
