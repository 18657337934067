import React, { useState, createContext } from 'react';
export const UrlContext = createContext();

const UrlContextProvider = (props) => {
  const [urlList, seturlList] = useState();
  return (
    <UrlContext.Provider value={{ urlList: urlList, seturlList: seturlList }}>
      {props.children}
    </UrlContext.Provider>
  );
};

export default UrlContextProvider;
