import { gql } from '@apollo/client';

export const getOwner = gql`
  query GetOwner($id: ID!) {
    getOwner(id: $id) {
      id
    }
  }
`;

export const listPolls = gql`
  query ListPolls($filter: ModelPollFilterInput, $nextToken: String) {
    listPolls(filter: $filter, nextToken: $nextToken) {
      items {
        id
        kindOfChart
        ownerPollsId
        question {
          questionText
        }
        title
        multipleAnswer
        answers {
          id
          answerText
        }
      }
    }
  }
`;

export const getRunningPoll = gql`
  query GetRunningPoll($id: ID!) {
    getRunningPoll(id: $id) {
      poll {
        id
        kindOfChart
        multipleAnswer
        owner {
          id
        }
        answers {
          vote
          answerText
          id
        }
        question {
          questionText
        }
      }
      id
      votes {
        items {
          answer {
            vote
            id
            answerText
          }
        }
      }
    }
  }
`;
export const getPollResult = gql`
  query GetPollResult($id: ID!) {
    getPollResult(id: $id) {
      id
      pollPollResultsId
      question
      answers {
        id
        answerText
      }
      multipleAnswer
      kindOfChart
      poll {
        id
        title
        question {
          questionText
        }
        kindOfChart
        multipleAnswer
        answers {
          id
          answerText
          vote
        }
        createdAt
        updatedAt
      }
      votes {
        items {
          answer {
            answerText
            id
            vote
          }
          pollUser {
            id
          }
          id
        }
      }
      createdAt
      updatedAt
    }
  }
`;

export const getPoll = gql`
  query GetPoll($id: ID!) {
    getPoll(id: $id) {
      id
      owner {
        id
        companyIdentification
        stage
        firstName
        lastName
        pollsets {
          nextToken
        }
        polls {
          nextToken
        }
        createdAt
        updatedAt
      }
      pollsets {
        items {
          id
          pollsetID
          pollID
          createdAt
          updatedAt
        }
        nextToken
      }
      title
      questionID
      question {
        id
        questionText
      }
      kindOfChart
      multipleAnswer
      answers {
        id
        answerText
        vote
      }
      stage
      createdAt
      updatedAt
      ownerPollsId
    }
  }
`;

export const listPollResults = gql`
  query ListPollResults($filter: ModelPollResultFilterInput, $limit: Int, $nextToken: String) {
    listPollResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        pollPollResultsId
        id
        question
        answers {
          id
          answerText
          vote
        }
        kindOfChart
        multipleAnswer
        poll {
          id
          title
          question {
            questionText
          }
          kindOfChart
          multipleAnswer
          answers {
            id
            answerText
            vote
          }
          createdAt
          updatedAt
        }
        votes {
          items {
            answer {
              answerText
              id
              vote
            }
            pollUser {
              id
            }
            id
          }
        }
        owner {
          id
          companyIdentification
          stage
          firstName
          lastName
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        ownerPollResultsId
        pollPollResultsId
      }
      nextToken
    }
  }
`;

export const listPollsets = gql`
  query ListPollsets($filter: ModelPollsetFilterInput, $limit: Int, $nextToken: String) {
    listPollsets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner {
          id
          companyIdentification
          stage
          firstName
          lastName
          createdAt
          updatedAt
        }
        title
        polls {
          nextToken
        }
        stage
        createdAt
        updatedAt
        ownerPollsetsId
      }
      nextToken
    }
  }
`;
