import React, { Fragment, useState, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import {
  Cell,
  PieChart,
  Pie,
  Bar,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LabelList,
} from 'recharts';
import { StylingContext } from '../../context/StylingContext';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import { getPollResult } from '../../graphql/queries';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: { marginTop: 20, overflow: 'hidden' },
}));

const ShowClientPollResults = ({
  pollResultIdToShow,
  showClientPollResultDialog,
  setShowClientPollResultDialog,
}) => {
  const classes = useStyles();
  const stylingData = useContext(StylingContext);
  const colors = scaleOrdinal(schemeCategory10).range();
  const [actualPollData, setActualPollData] = useState(null);
  const [displayData, setDisplayData] = useState([]);

  const buildDisplayData = (pollResult) => {
    //Ids der Antworten finden
    let tempAnswerIdArray = [];
    let sortedArrays = [];
    let sumOfVotes = [];
    let tempDisplayDataArray = [];
    pollResult.answers.map((answer) => {
      return tempAnswerIdArray.push(answer.id);
    });
    //Votes nach AntwortIds sortieren
    tempAnswerIdArray.map((answerId) => {
      return sortedArrays.push(
        pollResult.votes.items.filter((vote) => vote.answer.id === answerId)
      );
    });
    //votes sortiert addieren
    sortedArrays.map((sortedArray) => {
      let vote = 0;
      // eslint-disable-next-line array-callback-return
      sortedArray.map((entry) => {
        /**
         * nach user sortieren, dann addieren und dann komplett addieren
         */
        if (entry.answer.vote === 1) {
          vote = vote + entry.answer.vote;
        }
      });
      return sumOfVotes.push(vote);
    });
    //Antworten mit Votes in displayData schreiben
    // eslint-disable-next-line array-callback-return
    sumOfVotes.map((sum, index) => {
      tempDisplayDataArray.push({ name: pollResult.answers[index].answerText, Stimmen: sum });
    });
    setDisplayData(tempDisplayDataArray);
  };

  useQuery(getPollResult, {
    fetchPolicy: 'no-cache',
    variables: { id: pollResultIdToShow },
    onCompleted: (data) => {
      setActualPollData(data.getPollResult);
      buildDisplayData(data.getPollResult);
    },
  });

  const handleKindOfChart = () => {
    if (actualPollData) {
      if (actualPollData.kindOfChart === 'barchart') {
        return (
          <BarChart
            width={800}
            height={500}
            data={displayData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" fontSize={15} />
            <YAxis />
            <Tooltip />
            <Bar
              dataKey="Stimmen"
              nameKey="name"
              fill={stylingData.colors.color2}
              legendType="circle"
              label={{ fill: 'white', fontSize: 20 }}
            >
              {displayData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % 20]} />
              ))}
            </Bar>
          </BarChart>
        );
      } else {
        return (
          <PieChart width={730} height={500}>
            <Legend verticalAlign="top" height={36} />
            <Tooltip />
            <Pie
              data={displayData}
              dataKey="Stimmen"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={200}
              fill="#8884d8"
              legendType="circle"
            >
              {displayData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % 20]} />
              ))}
              <LabelList dataKey="value" position="insideTop" fill="white" />
            </Pie>
          </PieChart>
        );
      }
    }
  };

  return (
    <Fragment>
      <Dialog open={showClientPollResultDialog} maxWidth="xl" scroll="body">
        <DialogTitle>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => setShowClientPollResultDialog(false)}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h5">
            {actualPollData?.question ? actualPollData.question : null}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>{handleKindOfChart()}</DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default ShowClientPollResults;
