import React, { Fragment, useContext, useState } from 'react';
import { PieChart, Pie } from 'recharts';
import ChartDialog from './dialogues/ChartDialog';
import { makeStyles } from '@material-ui/core/styles';

import { StylingContext } from '../../../context/StylingContext';

const useStyles = makeStyles({
  pie: {
    '&:hover': { cursor: 'pointer' },
  },
});

const Piechart = (props) => {
  const stylingData = useContext(StylingContext);
  const classes = useStyles();
  const [color, setcolor] = useState(stylingData.colors.color2); // eslint-disable-line
  const [openDialog, setopenDialog] = useState(false);
  const data01 = [
    {
      name: 'Group A',
      value: 4,
    },
    {
      name: 'Group B',
      value: 3,
    },
    {
      name: 'Group C',
      value: 3,
    },
    {
      name: 'Group D',
      value: 2,
    },
    {
      name: 'Group E',
      value: 2,
    },
    {
      name: 'Group F',
      value: 1,
    },
  ];
  const handleOpenDialog = () => {
    setopenDialog(true);
  };
  const handleCloseDialog = () => {
    setopenDialog(false);
  };
  const renderPieChart = (color) => {
    return (
      <PieChart width={120} height={120}>
        <Pie
          data={data01}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={60}
          fill={color}
          onClick={handleOpenDialog}
          className={classes.pie}
        />
      </PieChart>
    );
  };

  return (
    <Fragment>
      {renderPieChart(color)}
      <ChartDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        kindOfChart={props.kindOfChart}
        handleDesignPollDialog={props.handleDesignPollDialog}
      />
    </Fragment>
  );
};
export default Piechart;
