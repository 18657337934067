import { gql } from '@apollo/client';

export const onCreateAndUpdateByRunningPollId = gql`
  subscription OnCreateAndUpdateByRunningPollId($runningPollId: ID!, $pollResultVotesId: ID!) {
    onCreateAndUpdateByRunningPollId(
      runningPollId: $runningPollId
      pollResultVotesId: $pollResultVotesId
    ) {
      id
      answer {
        id
        vote
      }
      runningPollId
      pollUser {
        lastName
        firstName
      }
      pollResultVotesId
      updatedAt
    }
  }
`;
