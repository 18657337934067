import React, { Fragment, useState, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { LoginContext } from '../../context/LoginContext';

import { getRunningPoll } from '../../graphql/queries';
import { useQuery, useMutation } from '@apollo/client';
import { createVote, updateVote } from '../../graphql/mutations';
import { v4 as uuidv4 } from 'uuid';

const ShowClientPoll = ({ openPollDialog, runningPoll, pollResultId, startVote, setStartVote }) => {
  const loginData = useContext(LoginContext);
  const [runningPollData, setRunningPollData] = useState(null);
  const [createTheVote, resultCreateTheVote] = useMutation(createVote); // eslint-disable-line
  const [updateTheVote, resultUpdateTheVote] = useMutation(updateVote); // eslint-disable-line
  const [voteIds, setVoteIds] = useState([]);
  const [votes, setVotes] = useState([]);
  const [usedVoteIds, setUsedVoteIds] = useState([]);
  const [answers, setAnswers] = useState([]); //für Einzelantwort
  const [buttonStatus, setButtonStatus] = useState([]);
  useQuery(getRunningPoll, {
    variables: { id: runningPoll },
    onCompleted: (data) => {
      if (startVote) {
        let tempVotes = [];
        let tempVoteIds = [];
        let tempButtonStatus = [];
        let tempAnswers = [];
        setRunningPollData(data.getRunningPoll);
        data.getRunningPoll.poll.answers.forEach((answer) => {
          tempVoteIds.push(uuidv4());
          tempVotes.push(0);
          tempButtonStatus.push(false);
          tempAnswers.push({ id: answer.id, answerText: answer.answerText, vote: -1 });
        });
        setVoteIds(tempVoteIds);
        setVotes(tempVotes);
        setButtonStatus(tempButtonStatus);
        setAnswers(tempAnswers);
      }
      setStartVote(false);
    },
  });

  const handleCreateAndUpdateVote = (answerId, answerText, index) => {
    if (runningPollData.poll.multipleAnswer) {
      handleCreateandUpdateMultiAnswerVote(answerId, answerText, index);
    } else {
      handleCreateandUpdateSingleVote(answerId, answerText, index);
    }
  };

  const handleCreateandUpdateSingleVote = (answerId, answerText, index) => {
    console.log('voteIds', voteIds);
    console.log('votes', votes);
    console.log('buttonStatus', buttonStatus);
    console.log('answers', answers);
    let tempUsedVoteIdsArray = [...usedVoteIds];
    let tempVotesArray = [...votes];
    let tempAnswerArray = [...answers];
    let tempButtonStatus = [...buttonStatus];
    let minusOneIndex = tempAnswerArray.findIndex((element) => element.vote === 1);
    console.log('minusOneIndex', minusOneIndex);
    tempAnswerArray.forEach((answer) => {
      answer.vote = 0;
    });
    if (minusOneIndex !== -1) {
      tempAnswerArray[minusOneIndex].vote = -1;
    }
    for (let i = 0; i < tempButtonStatus.length; i++) {
      tempButtonStatus[i] = false;
    }
    tempButtonStatus[index] = true;
    tempAnswerArray[index].vote = 1;
    setAnswers(tempAnswerArray);
    setButtonStatus(tempButtonStatus);
    tempAnswerArray.forEach(async (answer, index) => {
      console.log(answer);
      if (tempUsedVoteIdsArray.indexOf(voteIds[index]) === -1) {
        tempVotesArray[index] = 1;
        try {
          await createTheVote({
            variables: {
              input: {
                id: voteIds[index],
                pollResultVotesId: pollResultId,
                runningPollId: runningPoll,
                runningPollVotesId: runningPoll,
                votePollUserId: loginData.pollUser.pollUserVoteId,
                answer: answer,
              },
            },
          });
          tempUsedVoteIdsArray.push(voteIds[index]);
          setUsedVoteIds(tempUsedVoteIdsArray);
          setVotes(tempVotesArray);
        } catch (error) {
          console.log(error);
        }
      } else {
        tempVotesArray[index] = tempVotesArray[index] * -1;
        try {
          await updateTheVote({
            variables: {
              input: {
                id: voteIds[index],
                pollResultVotesId: pollResultId,
                runningPollId: runningPoll,
                runningPollVotesId: runningPoll,
                votePollUserId: loginData.pollUser.pollUserVoteId,
                answer: answer,
              },
            },
          });
          setVotes(tempVotesArray);
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  const handleCreateandUpdateMultiAnswerVote = async (answerId, answerText, index) => {
    let tempUsedVoteIdsArray = [...usedVoteIds];
    let tempVotesArray = [...votes];
    let tempButtonStatusArray = [...buttonStatus];
    tempButtonStatusArray[index] = !tempButtonStatusArray[index];
    setButtonStatus(tempButtonStatusArray);

    if (tempUsedVoteIdsArray.indexOf(voteIds[index]) === -1) {
      tempVotesArray[index] = 1;
      try {
        await createTheVote({
          variables: {
            input: {
              id: voteIds[index],
              pollResultVotesId: pollResultId,
              runningPollId: runningPoll,
              runningPollVotesId: runningPoll,
              votePollUserId: loginData.pollUser.pollUserVoteId,
              answer: {
                id: answerId,
                vote: tempVotesArray[index],
                answerText: answerText,
              },
            },
          },
        });
        tempUsedVoteIdsArray.push(voteIds[index]);
        setUsedVoteIds(tempUsedVoteIdsArray);
        setVotes(tempVotesArray);
      } catch (error) {
        console.log(error);
      }
    } else {
      tempVotesArray[index] = tempVotesArray[index] * -1;
      try {
        await updateTheVote({
          variables: {
            input: {
              id: voteIds[index],
              pollResultVotesId: pollResultId,
              runningPollId: runningPoll,
              runningPollVotesId: runningPoll,
              votePollUserId: loginData.pollUser.pollUserVoteId,
              answer: {
                id: answerId,
                vote: tempVotesArray[index],
                answerText: answerText,
              },
            },
          },
        });
        setVotes(tempVotesArray);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Fragment>
      <Dialog open={openPollDialog} fullWidth={true} maxWidth={'md'}>
        {runningPollData ? (
          <Fragment>
            <DialogTitle>{runningPollData.poll.question.questionText}</DialogTitle>
            <DialogContent>
              <Table>
                <TableHead>
                  {runningPollData.poll.multipleAnswer ? 'Mehrfachantwort möglich' : null}
                </TableHead>
                <TableBody>
                  {answers.map((answer, index) => {
                    return (
                      <TableRow>
                        <TableCell>{answer.answerText}</TableCell>
                        <TableCell>
                          <Button
                            color="primary"
                            variant={buttonStatus[index] ? 'contained' : 'outlined'}
                            onClick={
                              runningPollData.poll.multipleAnswer
                                ? () =>
                                    handleCreateAndUpdateVote(answer.id, answer.answerText, index)
                                : !buttonStatus[index]
                                ? () =>
                                    handleCreateAndUpdateVote(answer.id, answer.answerText, index)
                                : () => {}
                            }
                          >
                            Abstimmen
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </DialogContent>
          </Fragment>
        ) : null}
      </Dialog>
    </Fragment>
  );
};

export default ShowClientPoll;
