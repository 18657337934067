import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
  Button,
} from '@material-ui/core';

const DeletePoll = (props) => {
  const { deletePollDialog, setDeletePollDialog, pollToDelete, deleteThePoll } = props;

  return (
    <div>
      <Dialog open={deletePollDialog} onClose={() => setDeletePollDialog(false)}>
        <DialogTitle>Löschen der Umfrage</DialogTitle>
        <DialogContent>
          <DialogContentText>Wollen Sie die Umfrage wirklich löschen?</DialogContentText>
          <DialogActions>
            <Button
              color="secondary"
              autoFocus
              onClick={() => setDeletePollDialog(false)}
              variant="outlined"
            >
              Abbrechen
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                deleteThePoll(pollToDelete);
                setDeletePollDialog(false);
              }}
            >
              OK
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DeletePoll;
