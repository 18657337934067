import React, { useState, createContext } from 'react';

export const LoginContext = createContext();

const LoginContextProvider = (props) => {
  const [client, setClient] = useState(false);
  const [agent, setAgent] = useState(false);
  const [expert, setExpert] = useState(false);
  const [user, setUser] = useState();
  const [pollUser, setPollUser] = useState({});
  return (
    <LoginContext.Provider
      value={{
        client: client,
        setClient: setClient,
        agent: agent,
        setAgent: setAgent,
        expert: expert,
        setExpert: setExpert,
        user: user,
        setUser: setUser,
        pollUser: pollUser,
        setPollUser: setPollUser,
      }}
    >
      {props.children}
    </LoginContext.Provider>
  );
};
export default LoginContextProvider;
