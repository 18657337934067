import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Rating from '@material-ui/lab/Rating';
import { useSnackbar } from 'notistack';

import { ApellaContext } from '../../context/ApellaContext';
import { LoginContext } from '../../context/LoginContext';
import { UrlContext } from '../../context/UrlContext';
import KonfigurationsTab from '../preload_components/KonfigurationsTab';
import { submitFeedback } from '../../helper/getData';

const useStyles = makeStyles((theme) => ({
  marginAutoContainer: {
    display: 'flex',
  },
  marginAutoItem: {
    marginLeft: 'auto',
  },
}));

const Feedback = (props) => {
  const classes = useStyles();
  const data = useContext(ApellaContext);
  const loginData = useContext(LoginContext);
  const urlData = useContext(UrlContext);
  const { enqueueSnackbar } = useSnackbar();
  const [feedbackDialogStatus, setfeedbackDialogStatus] = useState(false);
  const [overallqualityRating, setoverallqualityRating] = useState(5);
  const [videoqualityRating, setvideoqualityRating] = useState(5);
  const [audioqualityRating, setaudioqualityRating] = useState(5);
  const [qualityAnnotationText, setqualityAnnotationText] = useState('');
  let authorFirstName = '';
  let authorLastname = '';
  const { openFeedbackDialog, closeFeedbackDialog, exitConference } = props;

  let navigator = {
    appCodeName: window.navigator.appCodeName,
    appName: window.navigator.appName,
    appVersion: window.navigator.appVersion,
    buildID: window.navigator.buildID,
    clipboard: window.navigator.clipboard,
    cookieEnabled: window.navigator.cookieEnabled,
    credentials: window.navigator.credentials,
    doNotTrack: window.navigator.doNotTrack,
    geolocation: window.navigator.geolocation,
    hardwareConcurrency: window.navigator.hardwareConcurrency,
    language: window.navigator.language,
    languages: window.navigator.languages,
    mediaCapabilities: window.navigator.mediaCapabilities,
    mediaDevices: window.navigator.mediaDevices,
    mediaSession: window.navigator.mediaSession,
    mimeTypes: window.navigator.mimeTypes,
    onLine: window.navigator.onLine,
    oscpu: window.navigator.oscpu,
    platform: window.navigator.platform,
    product: window.navigator.product,
    productSub: window.navigator.productSub,
    userAgent: window.navigator.userAgent,
    vendor: window.navigator.vendor,
    vendorSub: window.navigator.vendorSub,
    webdriver: window.navigator.webdriver,
  };

  useEffect(() => {
    setfeedbackDialogStatus(openFeedbackDialog);
  }, [openFeedbackDialog]);

  const handleQualityAnnotationTextChange = (event) => {
    setqualityAnnotationText(event.target.value);
  };

  const returnToConfiguration = () => {
    data.setPage(<KonfigurationsTab />);
  };

  const exitFeedbackDialog = () => {
    if (exitConference) {
      closeFeedbackDialog();
      setTimeout(returnToConfiguration, 3000);
    } else {
      closeFeedbackDialog();
    }
  };

  if (loginData.agent) {
    authorFirstName = data.agentData.agentData.firstName;
    authorLastname = data.agentData.agentData.lastName;
  } else {
    authorFirstName = loginData.user.firstName;
    authorLastname = loginData.user.lastName;
  }

  return (
    <div>
      <Dialog
        onClose={closeFeedbackDialog}
        aria-labelledby="simple-dialog-title"
        open={feedbackDialogStatus}
      >
        <DialogTitle id="feedbackDialogTitle">
          <Typography variant="h6" noWrap align="center">
            Bitte bewerten Sie die Qualität der Onlineberatung?
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.marginAutoContainer}>
          <div>
            <Typography>Wie bewerten Sie die Videoqualität?</Typography>
          </div>
          <div className={classes.marginAutoItem}>
            <Rating
              name="videoquality"
              value={videoqualityRating}
              onChange={(event, newValue) => {
                setvideoqualityRating(newValue);
              }}
            />
          </div>
        </DialogContent>
        <DialogContent className={classes.marginAutoContainer}>
          <div>
            <Typography align="left">Wie bewerten Sie die Audioqualität?</Typography>
          </div>
          <div className={classes.marginAutoItem}>
            <Rating
              name="audioquality"
              value={audioqualityRating}
              onChange={(event, newValue) => {
                setaudioqualityRating(newValue);
              }}
            />
          </div>
        </DialogContent>
        <DialogContent className={classes.marginAutoContainer}>
          <div>
            <Typography>Wie bewerten Sie die Qualität der Onlineberatung insgesamt?</Typography>
          </div>
          <div className={classes.marginAutoItem}>
            <Rating
              name="overallquality"
              value={overallqualityRating}
              onChange={(event, newValue) => {
                setoverallqualityRating(newValue);
              }}
            />
          </div>
        </DialogContent>
        <DialogContent>
          <div>
            <Typography>
              Haben Sie Anmerkungen/Anregungen zur Verbesserung der Onlineberatung?
            </Typography>
          </div>
          <div>
            <TextField
              variant="outlined"
              margin="dense"
              id="qualityRatingTextfield"
              fullWidth
              multiline={true}
              rows={5}
              placeholder="Anmerkung"
              value={qualityAnnotationText}
              onChange={handleQualityAnnotationTextChange}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => exitFeedbackDialog()}
            color="secondary"
            disableElevation
            variant="outlined"
          >
            Abbrechen
          </Button>
          <Button
            onClick={() => {
              submitFeedback({
                agentId: data.agentData.id,
                clientId: data.clientData.id,
                consultationDate: data.roomData.consultationDate,
                roomHash: data.roomData.roomHash,
                overallqualityRating: overallqualityRating,
                videoqualityRating: videoqualityRating,
                audioqualityRating: audioqualityRating,
                qualityAnnotationText: qualityAnnotationText,
                authorFirstName: authorFirstName,
                authorLastname: authorLastname,
                companyIdentification: data.companyIdentification,
                stage: data.stage,
                navigator: navigator,
                apiToken: data.apiToken,
                urlParam:
                  urlData.urlList.feedbackBaseurl +
                  urlData.urlList.feedbackApiEndpoints.submitFeedback.endpoint,
              });
              enqueueSnackbar('Ihr Feedback wurde übermittelt.', {
                variant: 'success',
              });
              exitFeedbackDialog();
            }}
            color="primary"
            disableElevation
            variant="contained"
          >
            Senden
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Feedback;
