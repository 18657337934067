/**
 * Erstellt die Umfrage
 */
import React, { useState, useEffect, useContext, Fragment } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Fab,
  Divider,
  Zoom,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import { ApellaContext } from '../../../../context/ApellaContext';
import { PollContext } from '../../../../context/PollContext';
import { useMutation } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import { createPoll } from '../../../../graphql/mutations';

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex' },
  title: {
    width: '100%',
  },
  addButton: {
    alignSelf: 'flex-end',
  },
  saveButton: {
    marginRight: 'auto',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const ChartDialog = (props) => {
  const theme = useTheme();
  const apellacontextData = useContext(ApellaContext);
  const pollContextData = useContext(PollContext);
  const classes = useStyles();
  const [pollQuestion, setpollQuestion] = useState('');
  const [multipleAnswer, setmultipleAnswer] = useState(false);
  const [textfieldId, settextfieldId] = useState(0);
  const [remove, setremove] = useState(false);
  const [answersText, setanswersText] = useState(new Map());
  const { enqueueSnackbar } = useSnackbar();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen * 1.5,
    exit: theme.transitions.duration.leavingScreen * 1.5,
  };
  const [addPoll, resultPoll] = useMutation(createPoll); // eslint-disable-line
  const questionID = uuidv4();
  const handlePollInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    switch (name) {
      case 'pollQuestion':
        setpollQuestion(value);
        break;
      case '0':
        setanswersText(answersText.set(target.id, { answerText: value, id: uuidv4() }));
        break;
      case target.id:
        setanswersText(answersText.set(target.id, { answerText: value, id: uuidv4() }));
        break;
      default:
        setanswersText(answersText.set(target.id, { answerText: value, id: uuidv4() }));
        break;
    }
  };

  const [textfieldArray, settextfieldArray] = useState([]);

  const closePollDialog = () => {
    props.handleCloseDialog();
    settextfieldId(0);
    answersText.clear();
  };

  const handelSavePoll = async () => {
    const arr = [];
    // eslint-disable-next-line
    for (const [name, value] of answersText) {
      arr.push(value);
    }
    await addPoll({
      variables: {
        input: {
          ownerPollsId: apellacontextData.agentData.id,
          kindOfChart: props.kindOfChart,
          title: 'Vielleicht später',
          multipleAnswer: multipleAnswer,
          question: { id: questionID, questionText: pollQuestion },
          questionID: questionID,
          stage: apellacontextData.stage,
          answers: arr,
        },
      },
    });
  };

  const savePoll = async () => {
    await handelSavePoll();
    enqueueSnackbar('Die Umfrage wurde erfolgreich erstellt.', {
      variant: 'success',
    });
    props.handleCloseDialog();
    props.handleDesignPollDialog();
    await pollContextData.setReloadPolls(true);
  };

  const removeTextfield = (id) => {
    setremove(true);
    answersText.delete(`answer${id}`);
    settextfieldId((prev) => prev - 1);
    let temp = textfieldArray;
    temp.pop();
    settextfieldArray(temp);
  };

  const addNewTextfields = () => {
    if (textfieldId === 0) {
      settextfieldArray([
        [
          <DialogContent>
            <TextField
              fullWidth
              required
              id="0"
              name="answer0"
              label="Antwort"
              variant="outlined"
              className={classes.firstAnswer}
              onChange={handlePollInputChange}
              size="small"
            />
          </DialogContent>,
        ],
      ]);
    } else {
      settextfieldArray([
        ...textfieldArray,

        <DialogContent>
          <TextField
            fullWidth
            required
            id={textfieldId}
            name={`answer${textfieldId}`}
            label="Antwort"
            variant="outlined"
            className={classes.answer}
            onChange={handlePollInputChange}
            size="small"
          />
        </DialogContent>,
      ]);
    }
  };

  useEffect(() => {
    if (!remove) {
      addNewTextfields();
    }
  }, [textfieldId]); // eslint-disable-line

  return (
    <Fragment>
      <Dialog open={props.openDialog} scroll="body" className={classes.dialogContent}>
        <Grid container lg={12} md={12} sm={12}>
          <Grid item lg={12} md={12} sm={12}>
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => closePollDialog()}
            >
              <CloseIcon />
            </IconButton>
            <DialogTitle>Entwerfen Sie hier Ihre Umfrage</DialogTitle>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Divider />
          </Grid>

          <Grid item lg={12} md={12} sm={12}>
            <DialogContent>
              <TextField
                fullWidth
                required
                id="pollQuestion"
                name="pollQuestion"
                label="Frage"
                multiline
                variant="outlined"
                className={classes.title}
                onChange={handlePollInputChange}
                size="small"
              />
            </DialogContent>
          </Grid>

          {textfieldArray.map((element) => (
            <Grid item lg={12} md={12} sm={12}>
              {element}
            </Grid>
          ))}
          <Grid item lg={12} md={12} sm={12}>
            <DialogActions>
              <FormControlLabel
                control={
                  <Switch
                    checked={multipleAnswer}
                    onChange={() => {
                      setmultipleAnswer((prev) => !prev);
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Mehrfachantwort möglich"
              />
            </DialogActions>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <DialogActions>
              <Button
                disableElevation
                color="primary"
                variant="contained"
                className={classes.saveButton}
                onClick={() => savePoll()}
              >
                Speichern
              </Button>
              {textfieldId > 0 ? (
                <Zoom in={true} timeout={transitionDuration} unmountOnExit>
                  <Fab
                    color="secondary"
                    aria-label="remove"
                    onClick={() => removeTextfield(textfieldId)}
                  >
                    <RemoveIcon />
                  </Fab>
                </Zoom>
              ) : null}

              <Zoom in={true} timeout={transitionDuration} unmountOnExit>
                <Fab
                  color="primary"
                  aria-label="add"
                  onClick={() => {
                    setremove(false);
                    settextfieldId((prev) => prev + 1);
                  }}
                >
                  <AddIcon />
                </Fab>
              </Zoom>
            </DialogActions>
          </Grid>
        </Grid>
        {/* <DialogContent className={classes.dialogContent}>{renderPieChart(color)}</DialogContent> */}
      </Dialog>
    </Fragment>
  );
};

export default ChartDialog;
