import React, { Fragment, useContext } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { LoggingContext } from '../../context/LoggingContext';
import { UrlContext } from '../../context/UrlContext';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const CloseRoom = (props) => {
  const loggingData = useContext(LoggingContext);
  const urlData = useContext(UrlContext);
  return (
    <Fragment>
      <Dialog
        open={props.openDialog}
        aria-labelledby="alert-dialog-save"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-save">
          {`Vielen Dank, dass Sie ${urlData.urlList.companyname} Connect benutzt haben!`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sie können nun die Onlineberatung abschließen oder diese an einem anderen Termin
            fortsetzen.
          </DialogContentText>
        </DialogContent>
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.locked}
                onChange={() => {
                  props.setlocked((prev) => !prev);
                  loggingData.setSequence({ type: 'lockRoom', locked: props.locked });
                }}
                name="lockRoom"
                color="secondary"
              />
            }
            label="Raum sperren"
            labelPlacement="start"
          ></FormControlLabel>
        </DialogContent>
        <DialogActions>
          <Button
            disableElevation
            onClick={() => {
              loggingData.setSequence({ type: 'cancelExitRoom' });
              props.handleCloseDialog();
            }}
            variant="outlined"
            color="secondary"
            autoFocus
          >
            Abbrechen
          </Button>
          <Button
            disableElevation
            onClick={() => {
              loggingData.setSequence({ type: 'closeRoom', value: 'abgeschlossen' });
              props.saveAttributes('processingStatus', 'abgeschlossen');
              props.handleSave();
              props.handleCloseDialog();
              props.closeRoom();
            }}
            variant="contained"
            color="primary"
            autoFocus
          >
            Abschließen
          </Button>
          <Button
            disableElevation
            onClick={() => {
              loggingData.setSequence({ type: 'closeRoom', value: 'nicht abgeschlossen' });
              props.saveAttributes('processingStatus', 'nicht abgeschlossen');
              props.handleSave();
              props.handleCloseDialog();
              props.closeRoom();
            }}
            variant="contained"
            color="primary"
            autoFocus
          >
            Später Fortsetzen
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
export default CloseRoom;
