import React from 'react';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RecordOnButton = (props) => {
  return (
    <div>
      <FontAwesomeIcon icon={faSpinner} spin pulse />
    </div>
  );
};
export default RecordOnButton;
