import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Badge from '@material-ui/core/Badge';
import { Beforeunload } from 'react-beforeunload';
import ChatIcon from '@material-ui/icons/Chat';
import DialogActions from '@material-ui/core/DialogActions';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faPhotoVideo,
  faKeyboard,
  faFileSignature,
  faIdCardAlt,
  faFileAlt,
  faChalkboardTeacher,
  faCog,
} from '@fortawesome/free-solid-svg-icons';
import Home from '../Home';
import Whiteboard from '../Whiteboard';
import VertragContainer from '../VertragContainer';
import Dokumente from '../Dokumente';
import Unterschrift from '../Unterschrift';
import JitsiMeetComponent from '../JitsiMeetComponent';
import { ApellaContext } from '../../context/ApellaContext';
import { LoginContext } from '../../context/LoginContext';
import { UrlContext } from '../../context/UrlContext';
import { PollContext } from '../../context/PollContext';
import Editor from '../Editor';
import Settings from '../dialogues/Settings';
import Feedback from '../dialogues/Feedback';
import ChatDrawer from './ChatDrawer';
import Lobby from '../dialogues/Lobby';
import ThankYou from '../dialogues/ThankYou';
import ExitClientRoom from '../dialogues/ExitClientRoom';
import ShowClientPoll from '../dialogues/ShowClientPoll';
import ShowClientPollResults from '../dialogues/ShowClientPollResults';
import ShowClientPollResultCompareDialog from '../dialogues/ShowClientPollResultCompareDialog';
import { useMutation } from '@apollo/client';
import { createPollUser } from '../../graphql/mutations';
import { v4 as uuidv4 } from 'uuid';

React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: 'white',
    /* boxShadow: `0 0 0 2px ${theme.palette.background.paper}`, */
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const ClientDrawer = (props) => {
  const [backgroundWidth, setbackgroundWidth] = useState(window.innerWidth);
  const [backgroundHeight, setBackgroundHeight] = useState(window.innerHeight);
  const data = useContext(ApellaContext);
  const loginData = useContext(LoginContext);
  const urlData = useContext(UrlContext);
  const pollContextData = useContext(PollContext);
  const wsuri = urlData.urlList.websocketBaseUrl;
  const [globalWs, setglobalWs] = useState(new WebSocket(wsuri)); // eslint-disable-line
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [mainContent, setMainContent] = useState();
  const [minijitsi, setminijitsi] = useState(0);
  const [position, setposition] = useState('');
  const [openRecordDialog, setopenRecordDialog] = useState(false);
  const [recordAllow, setrecordAllow] = useState(false); // eslint-disable-line
  const [screenshare, setscreenshare] = useState(false); // eslint-disable-line
  const [goodbyDialog, setgoodbyDialog] = useState(false);
  const [showWhiteboardDialog, setshowWhiteboardDialog] = useState(true);
  const [settings, setsettings] = useState(false);
  const [openFeedbackDialog, setopenFeedbackDialog] = useState(false);
  const [exitConference, setexitConference] = useState(false);
  const [onlineUsers, setOnlineUsers] = useState();
  const [openChatDrawer, setopenChatDrawer] = useState(false);
  const [websocketmessages, setwebsocketmessages] = useState([]);
  const [newWebsocketmessages, setnewWebsocketmessages] = useState(0);
  const [locked, setlocked] = useState(data.roomData.locked);
  const [openPollDialog, setopenPollDialog] = useState(false);
  const [pollResultId, setPollResultId] = useState('');
  const [runningPoll, setRunningPoll] = useState('');
  const [showClientPollResultDialog, setShowClientPollResultDialog] = useState(false);
  const [pollResultIdToShow, setPollResultIdToShow] = useState('');
  const [startVote, setStartVote] = useState(false);
  const [addPollUser, resultAddPollUser] = useMutation(createPollUser); // eslint-disable-line
  const [showMenu, setshowMenu] = useState(false);
  const [showPollResultCompareDialog, setShowPollResultCompareDialog] = useState(false);
  const [drawerWidth, setdrawerWidth] = useState(0);
  const chatDrawerwidth = 400;
  const [exit, setexit] = useState(false);
  const [formularInput, setformularInput] = useState({
    command: '',
    inputfiledId: '',
    message: '',
    user: { firstName: '', lastName: '', photo: '', timestamp: 0 },
  });
  const [exitClientRoomDialog, setexitClientRoomDialog] = useState(false);

  const handleShowPollResultCompareDialog = () => {
    setShowPollResultCompareDialog((prev) => !prev);
  };

  useEffect(() => {
    let id = uuidv4();
    let user = {
      id: id,
      firstName: '',
      lastName: '',
      pollUserVoteId: id,
      runningPollPollUsersId: id,
    };
    if (loginData.client) {
      user.firstName = data.clientData.clientData.firstName;
      user.lastName = data.clientData.clientData.lastName;
    } else {
      user.firstName = loginData.user.firstName;
      user.lastName = loginData.user.lastName;
    }
    try {
      loginData.setPollUser(user);
      console.log('in PollUser');
      addPollUser({ variables: { input: user } });
    } catch (error) {
      console.log(error);
    }
  }, []); // eslint-disable-line

  const resizeBackground = () => {
    setbackgroundWidth(window.innerWidth);
    setBackgroundHeight(window.innerHeight);
  };
  window.addEventListener('resize', resizeBackground);

  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      // Kein Menü für User
      width: `calc(100% - ${drawerWidth}px)`,

      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    appBarShiftRight: {
      marginRight: chatDrawerwidth,
      width: `calc(100% - ${chatDrawerwidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    appBarShiftBoth: {
      marginRight: chatDrawerwidth,
      marginLeft: drawerWidth,
      width: `calc(100% - ${chatDrawerwidth + drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      paddingLeft: theme.spacing(3),
      paddingTop: theme.spacing(3),
      paddingRight: 0,
      paddingBottom: theme.spacing(3),
      width: '100%',
      height: '100%',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -chatDrawerwidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    },
    barContent: {
      marginRight: 300,
      marginLeft: 10,
    },
    avatar: {
      marginLeft: 'auto',
    },
    whiteboardLink: {
      width: 'fit-content',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.secondary,
      '& svg': {
        margin: theme.spacing(1.5),
      },
      '& hr': {
        margin: theme.spacing(0, 0.5),
      },
    },
    goodby: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(10),
    },
    background: {
      zIndex: -1,
      position: 'absolute',
      top: 0,
      left: 0,
      backgroundColor: 'white',
      width: backgroundWidth,
      height: backgroundHeight,
    },
    feedbackButton: {
      marginRight: 20,
    },
    settingsButton: {
      marginTop: 'auto',
    },
    avatarDiv: {
      marginRight: 3,
    },
    chatButton: { marginLeft: 10 },
  }));
  const classes = useStyles();
  const handleWhiteboardDialog = () => {
    setshowWhiteboardDialog((prev) => !prev);
  };
  let wsIntervall;
  const connect = () => {
    globalWs.onopen = () => {
      console.log('connection ready');
      globalWs.send(
        `{"action":"enterRoom", "data":"${data.roomData.roomHash}", "user":"${data.clientData.id}"}`
      );
    };
    wsIntervall = setInterval(() => {
      send(JSON.stringify({ command: 'b7f69ddc-123c-4bfb-810b-6e0a922e74a3' }));
    }, 540000);
  };

  useEffect(() => {
    if (showMenu) {
      setdrawerWidth(240);
    } else {
      setdrawerWidth(0);
    }
  }, [showMenu]);
  useEffect(() => {
    connect();
  }, []); // eslint-disable-line

  globalWs.onmessage = (e) => {
    let decodedData = decodeURI(e.data);
    let decodedJson = JSON.parse(decodedData);
    let command = decodedJson.command;
    if (command === '5edcf140-096f-49e0-91d8-44f87e79d1f7') {
      setposition('big');
    }
    if (command === 'ee0fa100-80ce-4ee1-97b6-27758caa435c') {
      setshowMenu(true);
    }
    if (command === 'f897704c-338f-40aa-b453-a7a80e97f8e7') {
      setshowMenu(false);
    }
    if (command === '437603fa-bf60-4b6e-9015-dad32b00338d') {
      setlocked(true);
    }
    if (command === '09dfd83e-c2e9-4ff7-891a-bc2205227b73') {
      if (
        loginData.client &&
        decodedJson.user.timestamp > formularInput.user.timestamp &&
        decodedJson.user.firstName !== data.clientData.clientData.firstName &&
        decodedJson.user.lastName !== data.clientData.clientData.lastName
      ) {
        setformularInput(decodedJson);
      } else if (
        loginData.user &&
        decodedJson.user.timestamp > formularInput.user.timestamp &&
        decodedJson.user.firstName !== loginData.user.firstName &&
        decodedJson.user.lastName !== loginData.user.lastName
      ) {
        console.log(loginData.user);
        setformularInput(decodedJson);
      }

      console.log(formularInput);
    }
    if (command === '85f79357-33d8-438f-8025-9e108f159c8f') {
      setwebsocketmessages([...websocketmessages, decodedJson]);
      setnewWebsocketmessages((prev) => prev + 1);
    }
    if (
      command === '4372079c-59f2-4b0e-8de2-a199d964e9d7' ||
      command === 'ad50f2cf-4e3f-48a3-adea-487d6685b30f'
    ) {
      handleUpdateDocumentTable(command);
    } else if (command === '740de8e1-ca0c-46b7-b59c-6be07e96dd29') {
      closeRoom(command);
    } else if (command === 'ef17908f-12a7-4cd6-aedb-dc600e4b6214') {
      handleRecordDialog(command);
    } else if (command === 'e10bc53c-2ead-42f8-a83d-969780f6ab3e') {
      setlocked(false);
    } else if (command === '05442d32-95e3-4026-8d21-49ef85c5cb8b') {
      handleStartPoll(decodedJson);
    } else if (command === 'dca139c6-eff6-4a17-85bd-b166375b9081') {
      handleStopPoll();
      setposition('big');
    } else if (command === '38ef8255-528e-4efd-8986-6beebe765fcc') {
      handleShowPollResults(decodedJson);
    } else if (command === 'def925e9-3833-4f26-b017-74d6af166daa') {
      setShowClientPollResultDialog(false);
      setposition('big');
    } else if (command === 'a0e00423-d8c8-47f9-8ed6-6c41287432c2') {
      pollContextData.setComparePollResultsIndexArray(decodedJson.comparePollResultsIndexArray);
      handleShowPollResultCompareDialog();
    } else if (command === '1e6cee6e-c930-44ca-a65b-9478ea557ee1') {
      pollContextData.setComparePollResultsIndexArray([]);
      pollContextData.setPollResults(null);
      if (showPollResultCompareDialog) handleShowPollResultCompareDialog();
    } else {
      handleListItemClick(command);
    }
  };

  const exitRoom = () => {
    console.log('connection exited');
    globalWs.send(`{ "action": "exitRoom", "data": "${data.roomData.roomHash}"}`);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleCloseRecordDialog = () => {
    setopenRecordDialog(false);
  };
  useEffect(() => {
    setMainContent(<Home />);
    setminijitsi(1);
    setposition('big');
  }, []);

  const handlejitsi = () => {
    setposition('br');
    setminijitsi(1);
  };

  const send = (msg) => {
    if (globalWs.readyState === 1) {
      const str = `${encodeURI(msg)}`;
      globalWs.send(`{ "action": "message", "data": "${str}"}`);
    }
  };

  const sendChatmessage = (msg) => {
    let str = '';
    if (globalWs.readyState === 1) {
      if (loginData.agent) {
        str = `${JSON.stringify({
          command: '85f79357-33d8-438f-8025-9e108f159c8f',
          message: msg,
          user: {
            firstName: data.agentData.agentData.firstName,
            lastName: data.agentData.agentData.lastName,
            photo: data.agentData.agentData.photo,
            timestamp: Date.now(),
          },
        })}`;
      } else if (loginData.client) {
        str = `${JSON.stringify({
          command: '85f79357-33d8-438f-8025-9e108f159c8f',
          message: msg,
          user: {
            firstName: loginData.user.firstName,
            lastName: loginData.user.lastName,
            photo: data.clientData.clientData.photo,
            timestamp: Date.now(),
          },
        })}`;
      } else {
        str = `${JSON.stringify({
          command: '85f79357-33d8-438f-8025-9e108f159c8f',
          message: msg,
          user: {
            firstName: loginData.user.firstName,
            lastName: loginData.user.lastName,
            timestamp: Date.now(),
          },
        })}`;
      }
      send(str);
    }
  };

  const handleShowPollResults = (decodedJson) => {
    setPollResultIdToShow(decodedJson.pollResultId);
    setShowClientPollResultDialog(true);
  };

  const closeRoom = (e) => {
    if (e === '740de8e1-ca0c-46b7-b59c-6be07e96dd29' || exit) setgoodbyDialog(true);
    setTimeout(setopenFeedbackDialog(true), 3000);
    setexitConference(true);
    exitRoom();
    clearInterval(wsIntervall);
  };

  const openSettings = () => {
    setsettings(true);
  };

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
    switch (index) {
      case '78af9440-cc6b-4940-b69a-812dbaad1e93':
        setMainContent(<Home />);
        setposition('br');
        handlejitsi();
        break;
      case '412c1249-75d3-4d21-96bf-27c5ca272bac':
        setposition('big');
        break;
      case 'e22fcb43-8212-4974-8a90-6fbac9edc5d3':
        setMainContent(<Editor showMenu={showMenu} />);
        handlejitsi();
        break;
      case '54341296-b426-43d1-9206-903307f7407a':
        setMainContent(
          <Whiteboard
            showWhiteboardDialog={showWhiteboardDialog}
            setshowWhiteboardDialog={setshowWhiteboardDialog}
            handleWhiteboardDialog={handleWhiteboardDialog}
            showMenu={showMenu}
          />
        );
        handlejitsi();
        handleDrawerClose();
        break;

      case '0b7f2a1e-6a0d-4692-b5b9-89613c810a1f':
        setMainContent(<VertragContainer />);
        handlejitsi();
        break;
      /*       case '605649c1-b8f4-4eef-b432-f0f6de1a37ba':
        setMainContent(<Dokumente send={send} />);
        handlejitsi();
        break; */
      /*       case '7570c1f3-7fa7-499e-a08d-6bcb9edb35c4':
        setMainContent(<Unterschrift send={send} />);
        handlejitsi();
        break; */
      case 'c49b083f-333a-4f35-b18e-d8d9e0d4c61f':
        openSettings();
        break;
      case '05442d32-95e3-4026-8d21-49ef85c5cb8b':
        handleStartPoll();
        handlejitsi();
        break;
      default:
    }
  };

  const akronym = (user) => {
    console.log('user', user);
    if (user.firstName == null) {
      if (user.lastName != null) {
        return user.lastName.substr(0, 1);
      }
      return 'A';
    }
    if (user.firstName != null) {
      if (user.lastName != null) {
        let firstLetter = user.firstName.substr(0, 1);
        let lastLetter = user.lastName.substr(0, 1);
        return firstLetter + lastLetter;
      }
      return user.firstName.substr(0, 1);
    }

    return 'A';
  };

  const handleRecordDialog = (e) => {
    if (e === 'ef17908f-12a7-4cd6-aedb-dc600e4b6214') setopenRecordDialog(true);
  };

  const handleUpdateDocumentTable = (e) => {
    if (e === '4372079c-59f2-4b0e-8de2-a199d964e9d7') data.setforceUpdateTableView((prev) => !prev);
  };

  const handleFeedbackDialog = () => {
    setopenFeedbackDialog(true);
  };

  const handleFeedbackDialogClose = () => {
    setopenFeedbackDialog(false);
  };

  const handleChatDrawerOpen = () => {
    setopenChatDrawer(true);
  };

  const handleChatDrawerClose = () => {
    setnewWebsocketmessages(0);
    setopenChatDrawer(false);
  };

  const handleStartPoll = (decodedJson) => {
    setStartVote(true);
    handlejitsi();
    setPollResultId(decodedJson.pollResultId);
    setRunningPoll(decodedJson.runningPoll);
    setopenPollDialog(true);
  };

  const handleStopPoll = () => {
    setopenPollDialog(false);
  };

  const onlineUserStatus = (user) => {
    return onlineUsers.some((participant) => participant.displayName === user);
  };
  const handleMiddleAvatar = () => {
    if (data.clientData.id !== 'xxxxx') {
      if (loginData.client) {
        if (onlineUsers !== undefined && onlineUsers !== null) {
          if (
            onlineUserStatus(
              `${data.agentData.agentData.firstName} ${data.agentData.agentData.lastName}`
            )
          ) {
            return (
              <div className={classes.avatar}>
                <StyledBadge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  variant="dot"
                >
                  <Avatar
                    src={`${data.agentData.agentData.photo}`}
                    alt={`${data.agentData.agentData.firstName} ${data.agentData.agentData.lastName}`}
                  >
                    {akronym(data.agentData.agentData, data.agentData.agentData)}
                  </Avatar>
                </StyledBadge>
              </div>
            );
          } else {
            return (
              <div className={classes.avatar}>
                <Avatar
                  src={`${data.agentData.agentData.photo}`}
                  alt={`${data.agentData.agentData.firstName} ${data.agentData.agentData.lastName}`}
                >
                  {akronym(data.agentData.agentData, data.agentData.agentData)}
                </Avatar>
              </div>
            );
          }
        } else {
          return (
            <div className={classes.avatar}>
              <Avatar
                src={`${data.agentData.agentData.photo}`}
                alt={`${data.agentData.agentData.firstName} ${data.agentData.agentData.lastName}`}
              >
                {akronym(data.agentData.agentData, data.agentData.agentData)}
              </Avatar>
            </div>
          );
        }
      } else {
        if (onlineUsers !== undefined && onlineUsers !== null) {
          if (
            onlineUserStatus(
              `${data.clientData.clientData.firstName} ${data.clientData.clientData.lastName}`
            )
          ) {
            return (
              <div className={classes.avatar}>
                <StyledBadge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  variant="dot"
                >
                  <Avatar
                    src={`${data.clientData.clientData.photo}`}
                    alt={`${data.clientData.clientData.firstName} ${data.clientData.clientData.lastName}`}
                  >
                    {akronym(data.clientData.clientData, data.clientData.clientData)}
                  </Avatar>
                </StyledBadge>
              </div>
            );
          } else {
            return (
              <div className={classes.avatar}>
                <Avatar
                  src={`${data.clientData.clientData.photo}`}
                  alt={`${data.clientData.clientData.firstName} ${data.clientData.clientData.lastName}`}
                >
                  {akronym(data.clientData.clientData, data.clientData.clientData)}
                </Avatar>
              </div>
            );
          }
        } else {
          return (
            <div className={classes.avatar}>
              <Avatar
                src={`${data.agentData.agentData.photo}`}
                alt={`${data.agentData.agentData.firstName}  ${data.agentData.agentData.lastName}`}
              >
                {akronym(data.agentData.agentData, data.agentData.agentData)}
              </Avatar>
            </div>
          );
        }
      }
    } else {
      /**
       * Onlineberatung ohne Kunden
       */
      if (onlineUsers !== undefined && onlineUsers !== null) {
        if (
          onlineUserStatus(
            `${data.agentData.agentData.firstName} ${data.agentData.agentData.lastName}`
          )
        ) {
          return (
            <div className={classes.avatar}>
              <StyledBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot"
              >
                <Avatar
                  src={`${data.agentData.agentData.photo}`}
                  alt={`${data.agentData.agentData.firstName} ${data.agentData.agentData.lastName}`}
                >
                  {akronym(data.agentData.agentData, data.agentData.agentData)}
                </Avatar>
              </StyledBadge>
            </div>
          );
        } else {
          return (
            <div className={classes.avatar}>
              <Avatar
                src={`${data.agentData.agentData.photo}`}
                alt={`${data.agentData.agentData.firstName} ${data.agentData.agentData.lastName}`}
              >
                {akronym(data.agentData.agentData, data.agentData.agentData)}
              </Avatar>
            </div>
          );
        }
      }
    }
  };

  useEffect(() => {
    if (exit) closeRoom();

    return () => {
      setexit(false);
    };
  }, [exit]); // eslint-disable-line

  return (
    <ApellaContext.Consumer>
      {(context) => (
        <div className={classes.root}>
          {data.roomData.locked || locked ? <Lobby locked={locked} /> : null}
          <CssBaseline />
          <Beforeunload
            onBeforeunload={(event) => {
              event.preventDefault();
              event.returnValue = '';
              exitRoom();
            }}
          />
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: open && !openChatDrawer,
              [classes.appBarShiftRight]: openChatDrawer && !open,
              [classes.appBarShiftBoth]: open && openChatDrawer,
            })}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap>
                {urlData.urlList.companyname} connect
              </Typography>
              {data.clientData.id !== 'xxxxx' ? handleMiddleAvatar() : null}
              {data.clientData.id !== 'xxxxx' ? (
                loginData.client ? (
                  <Typography className={classes.barContent} variant="h6" noWrap>
                    {`${context.agentData.agentData.firstName}  ${context.agentData.agentData.lastName}`}
                  </Typography>
                ) : (
                  <Typography className={classes.barContent} variant="h6" noWrap>
                    {`${context.clientData.clientData.firstName}  ${context.clientData.clientData.lastName}`}
                  </Typography>
                )
              ) : (
                <div className={classes.avatar}></div>
              )}

              <Button
                disableElevation
                variant="contained"
                color="secondary"
                aria-label="open drawer"
                onClick={handleFeedbackDialog}
                className={classes.feedbackButton}
              >
                Feedback
              </Button>
              {loginData.client && onlineUsers !== undefined && onlineUsers !== null
                ? onlineUsers.map((user) =>
                    !user.formattedDisplayName.includes('(ich)') &&
                    user.displayName !==
                      `${context.clientData.clientData.firstName} ${context.clientData.clientData.lastName}` &&
                    user.displayName !==
                      `${context.agentData.agentData.firstName} ${context.agentData.agentData.lastName}` ? (
                      <div className={classes.avatarDiv}>
                        <StyledBadge
                          overlap="circle"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          variant="dot"
                        >
                          <Avatar
                            className={classes.avatar}
                            alt={`${user.displayName.split(' ')[0]}  ${
                              user.displayName.split(' ')[1]
                            }`}
                          >
                            {akronym({
                              firstName: user.displayName.split(' ')[0],
                              lastName: user.displayName.split(' ')[1],
                            })}
                          </Avatar>
                        </StyledBadge>
                      </div>
                    ) : null
                  )
                : onlineUsers !== undefined && onlineUsers !== null
                ? onlineUsers.map((user) =>
                    !user.formattedDisplayName.includes('(ich)') &&
                    user.displayName !==
                      `${context?.clientData?.clientData?.firstName} ${context?.clientData?.clientData?.lastName}` ? (
                      <div className={classes.avatarDiv}>
                        <StyledBadge
                          overlap="circle"
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          variant="dot"
                        >
                          {user.displayName ===
                          `${context.agentData.agentData.firstName} ${context.agentData.agentData.lastName}` ? (
                            <Avatar
                              className={classes.avatar}
                              src={`${data.agentData.agentData.photo}`}
                              alt={`${user.displayName.split(' ')[0]}  ${
                                user.displayName.split(' ')[1]
                              }`}
                            >
                              {akronym({
                                firstName: user.displayName.split(' ')[0],
                                lastName: user.displayName.split(' ')[1],
                              })}
                            </Avatar>
                          ) : (
                            <Avatar
                              className={classes.avatar}
                              alt={`${user.displayName.split(' ')[0]}  ${
                                user.displayName.split(' ')[1]
                              }`}
                            >
                              {akronym({
                                firstName: user.displayName.split(' ')[0],
                                lastName: user.displayName.split(' ')[1],
                              })}
                            </Avatar>
                          )}
                        </StyledBadge>
                      </div>
                    ) : null
                  )
                : null}

              <IconButton
                color="inherit"
                aria-label="open chat"
                onClick={() => setopenChatDrawer((prev) => !prev)}
                edge="start"
                className={clsx(classes.chatButton, {
                  [classes.hide]: openChatDrawer,
                })}
              >
                <Badge badgeContent={newWebsocketmessages} color="secondary">
                  <ChatIcon />
                </Badge>
              </IconButton>
              <IconButton
                color="secondary"
                onClick={() => {
                  setexitClientRoomDialog(true);
                }}
                className={classes.exit}
                id="exit_button"
              >
                <PowerSettingsNewIcon />
                <Typography variant="h6" noWrap>
                  Exit
                </Typography>
              </IconButton>
            </Toolbar>
          </AppBar>
          {showMenu ? (
            <SwipeableDrawer
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                }),
              }}
            >
              <div className={classes.toolbar}>
                <IconButton onClick={handleDrawerClose}>
                  {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
              </div>
              <Divider />
              <List>
                <ListItem
                  className={classes.root}
                  button
                  selected={selectedIndex === 'Menu0'}
                  onClick={(event) => {
                    handleListItemClick('78af9440-cc6b-4940-b69a-812dbaad1e93');
                  }}
                >
                  <ListItemIcon>
                    <IconButton>
                      <FontAwesomeIcon icon={faHome} />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary="Home" />
                </ListItem>
                <ListItem
                  button
                  selected={selectedIndex === 'Menu1'}
                  onClick={(event) => {
                    handleListItemClick('412c1249-75d3-4d21-96bf-27c5ca272bac');
                  }}
                >
                  <ListItemIcon>
                    <IconButton>
                      <FontAwesomeIcon icon={faPhotoVideo} />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary="Vollbildmodus" />
                </ListItem>
                <ListItem
                  button
                  selected={selectedIndex === 'Menu2'}
                  onClick={(event) => {
                    handleListItemClick('e22fcb43-8212-4974-8a90-6fbac9edc5d3');
                  }}
                >
                  <ListItemIcon>
                    <IconButton>
                      <FontAwesomeIcon icon={faKeyboard} />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary="Editor" />
                </ListItem>
                <ListItem
                  button
                  selected={selectedIndex === 'Menu3'}
                  onClick={(event) => {
                    handleListItemClick('54341296-b426-43d1-9206-903307f7407a');
                  }}
                >
                  <ListItemIcon>
                    <IconButton>
                      <FontAwesomeIcon icon={faChalkboardTeacher} />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary="Whiteboard" />
                </ListItem>
                {data.clientData.id !== 'xxxxx' ? (
                  <ListItem
                    button
                    selected={selectedIndex === 'Menu4'}
                    onClick={(event) => {
                      handleListItemClick('0b7f2a1e-6a0d-4692-b5b9-89613c810a1f');
                    }}
                  >
                    <ListItemIcon>
                      <IconButton>
                        <FontAwesomeIcon icon={faIdCardAlt} />
                      </IconButton>
                    </ListItemIcon>
                    <ListItemText primary="Verträge" />
                  </ListItem>
                ) : null}
                {/* <ListItem
                  button
                  selected={selectedIndex === 'Menu5'}
                  onClick={(event) => {
                    handleListItemClick('605649c1-b8f4-4eef-b432-f0f6de1a37ba');
                  }}
                >
                  <ListItemIcon>
                    <IconButton>
                      <FontAwesomeIcon icon={faFileAlt} />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary="Dokumente" />
                </ListItem> */}
                {/*                 <ListItem
                  button
                  selected={selectedIndex === 'Menu6'}
                  onClick={(event) => {
                    handleListItemClick('7570c1f3-7fa7-499e-a08d-6bcb9edb35c4');
                  }}
                >
                  <ListItemIcon>
                    <IconButton>
                      <FontAwesomeIcon icon={faFileSignature} />
                    </IconButton>
                  </ListItemIcon>
                  <ListItemText primary="Unterschrift" />
                </ListItem> */}
              </List>
              <Divider />
              <div className={classes.settingsButton}>
                <List>
                  <ListItem
                    button
                    selected={selectedIndex === 'Menu12'}
                    onClick={(event) => {
                      handleListItemClick('c49b083f-333a-4f35-b18e-d8d9e0d4c61f');
                    }}
                  >
                    <ListItemIcon>
                      <IconButton>
                        <FontAwesomeIcon icon={faCog} />
                      </IconButton>
                    </ListItemIcon>
                    <ListItemText primary="Einstellungen" />
                  </ListItem>
                </List>
              </div>
            </SwipeableDrawer>
          ) : null}
          <div className={classes.background}></div>
          <div
            className={clsx(classes.content, {
              [classes.contentShift]: openChatDrawer,
            })}
          >
            {mainContent}
          </div>
          <ChatDrawer
            openChatDrawer={openChatDrawer}
            handleChatDrawer={handleChatDrawerOpen}
            handleChatDrawerClose={handleChatDrawerClose}
            chatDrawerwidth={chatDrawerwidth}
            websocketmessages={websocketmessages}
            sendChatmessage={sendChatmessage}
          />

          <JitsiMeetComponent
            showMenu={showMenu}
            position={position}
            minijitsi={minijitsi}
            recordAllow={recordAllow}
            screenshare={screenshare}
            exit={goodbyDialog}
            setOnlineUsers={setOnlineUsers}
            audioOnly={props.audioOnly}
            openChatDrawer={openChatDrawer}
            openMenu={open}
          />

          {openFeedbackDialog ? (
            <Feedback
              openFeedbackDialog={openFeedbackDialog}
              closeFeedbackDialog={handleFeedbackDialogClose}
              exitConference={exitConference}
            />
          ) : null}
          <ThankYou goodbyDialog={goodbyDialog} />
          <Dialog
            open={openRecordDialog}
            onClose={handleCloseRecordDialog}
            aria-labelledby="alert-dialog-record"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-record">
              <IconButton color="secondary">
                <FontAwesomeIcon icon={faExclamationTriangle} size="large" />
              </IconButton>
              {' Die Beratungssitzung wird nun aufgezeichnet!'}
            </DialogTitle>
            <DialogActions>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                aria-label="open drawer"
                onClick={handleCloseRecordDialog}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
          {settings ? (
            <Settings
              openSettings={settings}
              setopenSettings={setsettings}
              showWhiteboardDialog={showWhiteboardDialog}
              handleWhiteboardDialog={handleWhiteboardDialog}
            />
          ) : null}
          {openPollDialog ? (
            <ShowClientPoll
              openPollDialog={openPollDialog}
              runningPoll={runningPoll}
              pollResultId={pollResultId}
              startVote={startVote}
              setStartVote={setStartVote}
            />
          ) : null}
          {showClientPollResultDialog ? (
            <ShowClientPollResults
              pollResultIdToShow={pollResultIdToShow}
              showClientPollResultDialog={showClientPollResultDialog}
              setShowClientPollResultDialog={setShowClientPollResultDialog}
            />
          ) : null}
          {showPollResultCompareDialog ? (
            <ShowClientPollResultCompareDialog
              showPollResultCompareDialog={showPollResultCompareDialog}
              setShowPollResultCompareDialog={setShowPollResultCompareDialog}
            />
          ) : null}
          <ExitClientRoom
            exitClientRoomDialog={exitClientRoomDialog}
            setexitClientRoomDialog={setexitClientRoomDialog}
            closeRoom={closeRoom}
            setexit={setexit}
          />
        </div>
      )}
    </ApellaContext.Consumer>
  );
};
export default ClientDrawer;
