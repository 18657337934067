import React, { useState, useContext, useEffect } from 'react';
import { ApellaContext } from '../context/ApellaContext';
import { LoginContext } from '../context/LoginContext';

const Main = () => {
  const data = useContext(ApellaContext);
  const loginData = useContext(LoginContext);
  const page = data.page;
  const [main, setMain] = useState(page);

  useEffect(() => {
    loginData.setAgent(true);
    setMain(page);
  }, [page]); // eslint-disable-line

  return <div>{main}</div>;
};
export default Main;
