import React, { Fragment, useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ApellaContext } from '../../context/ApellaContext';
import { LoginContext } from '../../context/LoginContext';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { useMutation, useQuery } from '@apollo/client';
import { createOwner } from '../../graphql/mutations';
import { getOwner } from '../../graphql/queries';

import Piechart from './Poll/Piechart';
import Barchart from './Poll/Barchart';

const useStyles = makeStyles({
  root: {
    maxWidth: 300,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  content: {
    flex: '1 0 auto',
  },
  charts: {
    width: 50,
  },
  chartcontainer: { display: 'flex' },
});
const CreatePolls = (props) => {
  const classes = useStyles();
  const apellacontextData = useContext(ApellaContext);
  const loginData = useContext(LoginContext);
  const [addOwner, resultOwner] = useMutation(createOwner); // eslint-disable-line
  const { data } = useQuery(getOwner, {
    variables: {
      id: apellacontextData.agentData.id,
    },
  });
  const headers = ['Balkendiagramm', 'Kreisdiagramm'];
  const kindOfChart = ['barchart', 'piechart'];
  const [chartIndex, setchartIndex] = useState(0);

  const charts = [
    <Barchart
      className={classes.charts}
      kindOfChart={kindOfChart[0]}
      handleDesignPollDialog={props.handleDesignPollDialog}
    />,
    <Piechart
      className={classes.charts}
      kindOfChart={kindOfChart[1]}
      handleDesignPollDialog={props.handleDesignPollDialog}
    />,
  ];

  const handleClickLeft = () => {
    if (chartIndex >= 1) {
      setchartIndex((prev) => prev - 1);
    } else {
      setchartIndex(charts.length - 1);
    }
  };
  const handleClickRight = () => {
    if (chartIndex < charts.length - 1) {
      setchartIndex((prev) => prev + 1);
    } else {
      setchartIndex(0);
    }
  };

  useEffect(() => {
    if (loginData.agent) {
      if (data?.getOwner === null) {
        try {
          addOwner({
            variables: {
              input: {
                companyIdentification: apellacontextData.companyIdentification,
                firstName: apellacontextData.agentData.agentData.firstName,
                lastName: apellacontextData.agentData.agentData.lastName,
                id: apellacontextData.agentData.id,
                stage: apellacontextData.stage,
              },
            },
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
  }, [data]); // eslint-disable-line
  return (
    <Fragment>
      <Card className={classes.root} variant="outlined">
        <CardHeader title={headers[chartIndex]} className={classes.title}></CardHeader>
        <div className={classes.chartcontainer}>
          <CardActions>
            <IconButton>
              <ChevronLeftIcon onClick={() => handleClickLeft()} />
            </IconButton>
          </CardActions>
          <CardContent className={classes.content}>{charts[chartIndex]}</CardContent>
          <CardActions>
            <IconButton>
              <ChevronRightIcon onClick={() => handleClickRight()} />
            </IconButton>
          </CardActions>
        </div>
      </Card>
    </Fragment>
  );
};
export default CreatePolls;
