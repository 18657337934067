import React, { Fragment, useContext, useState } from 'react';
import AnhangTab from './AnhangTab';
import { saveRoom } from '../../helper/saveRoom';
import {
  Tabs,
  Tab,
  Box,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Dialog,
  Typography,
  Grid,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { OpenInNew } from '@material-ui/icons';
import ApellaDrawer from '../ApellaDrawer';
import Participants from './Participants';
import Agenda from './Agenda';
import { ApellaContext } from '../../context/ApellaContext';
import { StylingContext } from '../../context/StylingContext';
import { UrlContext } from '../../context/UrlContext';
import CreatePolls from './CreatePolls';
import { LoggingContext } from '../../context/LoggingContext';
import ListPolls from './Poll/ListPolls';
import PollResultList from './Poll/PollResultList';
import PollResultListDialog from '../dialogues/PollResultListDialog';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box padding={3} paddingBottom={8}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  expertInputField: {
    margin: 5,
  },
  dialogRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
    minWidth: '90vw',
    maxWidth: '90vw',
  },
  root: {
    width: '100%',
    position: 'relative',
  },
}));

export default function RoomFunctions({ onClose }) {
  const contextData = useContext(ApellaContext);
  const stylingData = useContext(StylingContext);
  const urlData = useContext(UrlContext);
  const loggingData = useContext(LoggingContext);
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const data = useContext(ApellaContext);
  const [openDesignPoll, setopenDesignPoll] = useState(false);
  const [backdropOpen, setbackdropOpen] = useState(false); // eslint-disable-line
  const [showMiniPollresultList, setShowMiniPollresultList] = useState(true);
  const [showPollResultListDialog, setShowPollResultListDialog] = useState(false);
  //const [showMiniPollSets, setShowMiniPollSets] = useState(true); // eslint-disable-line

  const handleShowPollResultListDialog = () => {
    setShowPollResultListDialog((prev) => !prev);
    setShowMiniPollresultList((prev) => !prev);
  };
  // eslint-disable-next-line
  const handleSave = () => {
    if (!contextData.saveVariable) {
      contextData.setsaveVariable(true);
      setbackdropOpen(true);
      saveRoom(
        data.apiToken,
        data.agentData,
        data.clientData,
        data.roomData,
        data.documents,
        data.url,
        data.insignData,
        data.activeStep,
        data.otpKey,
        data.companyIdentification,
        data.stage,
        data.contextID,
        data.agentConnectionId,
        data.experts,
        data.agendaText,
        stylingData.colors,
        urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.updateRoom.endpoint
      );
      setbackdropOpen(false);
      contextData.setsaveVariable(false);
    }
  };

  const handleDesignPollDialog = () => {
    setopenDesignPoll((prev) => !prev);
  };

  return (
    <Fragment>
      <Dialog
        open={true}
        aria-labelledby="new-room-title"
        aria-describedby="new-room-description"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent classes={{ root: classes.dialogRoot }}>
          <DialogTitle id="new-room-title">
            <div>
              <Typography variant={'h5'}>{data.roomData.consultationTitle}</Typography>
            </div>

            <div>
              <Typography variant={'h6'}>{data.roomData.consultationDate}</Typography>
            </div>
          </DialogTitle>

          <Grid
            container
            spacing={2}
            id="new-room-description"
            style={{ flexGrow: 1, paddingBottom: '20px' }}
          >
            <div className={classes.root}>
              <Tabs
                value={selectedTab}
                onChange={(e, value) => setSelectedTab(value)}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="Agenda" />
                {/* <Tab label="Dokumente" /> */}
                <Tab label="Umfragen" />
                <Tab label="Teilnehmer" />
              </Tabs>

              <Box>
                <TabPanel value={selectedTab} index={0}>
                  <Agenda />
                </TabPanel>
                {/* <TabPanel value={selectedTab} index={1}>
                  <AnhangTab />
                </TabPanel> */}
                <TabPanel value={selectedTab} index={1}>
                  <Grid container lg={12} md={12} sm={12} spacing={1}>
                    <Grid item lg={8} md={8} sm={8}>
                      <ListPolls origin={'Preload'} />
                    </Grid>
                    {showMiniPollresultList ? (
                      <Grid item lg={4} md={4} sm={4}>
                        <PollResultList
                          origin="mini"
                          showMiniPollresultList={showMiniPollresultList}
                          setShowMiniPollresultList={setShowMiniPollresultList}
                          handleShowPollResultListDialog={handleShowPollResultListDialog}
                        />
                      </Grid>
                    ) : null}
                    <Grid item lg={8} md={8} sm={8}></Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Button
                        disableElevation
                        variant="contained"
                        onClick={() => handleDesignPollDialog()}
                      >
                        neue Umfrage entwerfen
                      </Button>
                    </Grid>
                    {/**
                     * @TODO Sets erstellen
                     */}
                    {/* <Grid item lg={2} md={2} sm={6} xs={12}>
                    <Button disableElevation variant="contained">
                      Set aus Umfragen erstellen/bearbeiten
                    </Button>
                  </Grid> */}
                  </Grid>
                  {openDesignPoll ? (
                    <Dialog open={openDesignPoll} onClose={() => handleDesignPollDialog()}>
                      <CreatePolls handleDesignPollDialog={handleDesignPollDialog} />
                    </Dialog>
                  ) : null}
                </TabPanel>
                <TabPanel value={selectedTab} index={2}>
                  <Participants />
                </TabPanel>
              </Box>
            </div>
          </Grid>

          <DialogActions>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
              <Button
                className={classes.button}
                size="large"
                onClick={() => {
                  onClose();
                  loggingData.setSequence({ type: 'closePreload' });
                }}
                disableElevation
                variant="outlined"
                color="secondary"
              >
                Schließen
              </Button>

              {selectedTab < 2 && ( //Umfrage raus //3
                <Button
                  disableElevation
                  className={classes.button}
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={() => {
                    setSelectedTab(selectedTab + 1);
                  }}
                >
                  <Typography>Weiter</Typography>
                </Button>
              )}

              {selectedTab === 2 && ( //Umfrage raus (3)
                <Button
                  disableElevation
                  className={classes.button}
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={() => {
                    data.setPage(<ApellaDrawer />);
                  }}
                >
                  <OpenInNew style={{ marginRight: '10px' }} />
                  <Typography>Raum betreten</Typography>
                </Button>
              )}
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {showPollResultListDialog && (
        <PollResultListDialog
          handleShowPollResultListDialog={handleShowPollResultListDialog}
          showPollResultListDialog={showPollResultListDialog}
          origin="maxi"
        />
      )}
    </Fragment>
  );
}
