import React, { useState, createContext } from 'react';
import KonfigurationsTab from '../components/preload_components/KonfigurationsTab';
export const ApellaContext = createContext();

const ApellaContextProvider = (props) => {
  /**
   * Maklerdaten
   */
  const [rooms, setrooms] = useState([]);
  const [NewRoom, setNewRoom] = useState(false);
  const [agentData, setagentData] = useState({
    id: '',
    token: '',
    company: '',
    agentData: {
      firstName: '',
      lastName: '',
      title: 'Herr',
      photo: '',
      email1: '',
      email2: '',
    },
  });

  /**
   * Kundendaten
   */
  const [clientData, setclientData] = useState({
    id: '',
    token: '',
    company: '',
    clientData: {
      firstName: '',
      lastName: '',
      title: '',
      photo: '',
      email1: '',
      email2: '',
    },
    contracts: [],
  });

  /**
   * Expertendaten
   */
  // eslint-disable-next-line
  const [expertData, setexpertData] = useState({
    id: '',
    token: '',
    company: '',
    expertData: {
      firstName: '',
      lastName: '',
      title: '',
      photo: '',
      email: '',
    },
  });

  /**
   * Raumdaten
   */
  const [roomsList, setRoomsList] = useState([]);
  const [roomData, setroomData] = useState({
    roomHash: '',
    roomPassword: '',
    consultationDate: '',
    consultationTime: '',
    workflow: [false, false, false],
    videofile: '',
    lastUpdate: '',
    consultationTitle: '',
    processingStatus: '',
    expiryDate: '',
    whiteBoard: '',
    longURL: '',
    shortURL: '',
    locked: true,
    polls: [],
    companyIdentification: props.companyIdentification,
    stage: props.stage,
  });

  /**
   * Dokumente
   */
  const [documents, setDocuments] = useState([]);
  const [companyIdentification, setcompanyIdentification] = useState(props.companyIdentification);
  const [contextID, setcontextID] = useState(props.contextID);

  const [otpKey, setotpKey] = useState(props.otpKey);
  const [stage, setstage] = useState(props.stage); // eslint-disable-line
  const [toggleBackdrop, settoggleBackdrop] = useState(false);
  const [forceUpdate, setforceUpdate] = useState(false);
  const [forceUpdateTableView, setforceUpdateTableView] = useState(false);
  const [page, setPage] = useState(<KonfigurationsTab />);
  const [fetch, setFetch] = useState(false);
  const [fileUpload, setFileUpload] = useState([]);
  const [activeStep, setactiveStep] = useState(0);
  const [apiToken, setapiToken] = useState('');
  const [agentConnectionId, setagentConnectionId] = useState('');
  const [agendaText, setAgendaText] = useState('');
  const [saveVariable, setsaveVariable] = useState(false);

  /**
   * Experten
   */
  const [experts, setexperts] = useState([]);

  return (
    <ApellaContext.Provider
      value={{
        experts: experts,
        setexperts: setexperts,
        agentConnectionId: agentConnectionId,
        setagentConnectionId: setagentConnectionId,
        apiToken: apiToken,
        setapiToken: setapiToken,
        roomsList: roomsList,
        setRoomsList: setRoomsList,
        forceUpdateTableView: forceUpdateTableView,
        setforceUpdateTableView: setforceUpdateTableView,
        NewRoom: NewRoom,
        setNewRoom: setNewRoom,
        activeStep: activeStep,
        setactiveStep: setactiveStep,
        toggleBackdrop: toggleBackdrop,
        settoggleBackdrop: settoggleBackdrop,
        forceUpdate: forceUpdate,
        setforceUpdate: setforceUpdate,
        rooms: rooms,
        setrooms: setrooms,
        agentData: agentData,
        setagentData: setagentData,
        clientData: clientData,
        setclientData: setclientData,
        roomData: roomData,
        setroomData: setroomData,
        documents: documents,
        setDocuments: setDocuments,
        page: page,
        setPage: setPage,
        fetch: fetch,
        setFetch: setFetch,
        fileUpload: fileUpload,
        setFileUpload: setFileUpload,
        companyIdentification: companyIdentification,
        setcompanyIdentification: setcompanyIdentification,
        contextID: contextID,
        setcontextID: setcontextID,
        otpKey: otpKey,
        setotpKey: setotpKey,
        agendaText: agendaText,
        setAgendaText: setAgendaText,
        saveVariable: saveVariable,
        setsaveVariable: setsaveVariable,
        stage: stage,
      }}
    >
      {props.children}
    </ApellaContext.Provider>
  );
};

export default ApellaContextProvider;
