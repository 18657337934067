import React, { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
  Button,
} from '@material-ui/core';
import ThankYou from './ThankYou';

const ExitClientRoom = (props) => {
  const { exitClientRoomDialog, setexitClientRoomDialog, setexit } = props;
  const [goodbyDialog, setgoodbyDialog] = useState(false); // eslint-disable-line

  return (
    <div>
      <Dialog open={exitClientRoomDialog} onClose={() => setexitClientRoomDialog(false)}>
        <DialogTitle>Verlassen des Beratungsraums</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Wollen Sie die die Beratungssitzung wirklich beenden?
          </DialogContentText>
          <DialogActions>
            <Button
              color="secondary"
              autoFocus
              onClick={() => setexitClientRoomDialog(false)}
              variant="outlined"
            >
              Abbrechen
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setexit(true);
                setexitClientRoomDialog(false);
              }}
            >
              OK
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <ThankYou goodbyDialog={goodbyDialog} />
    </div>
  );
};

export default ExitClientRoom;
