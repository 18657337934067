import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import LoginContextProvider from './context/LoginContext';
import ApellaContextProvider from './context/ApellaContext';
import StylingContextProvider from './context/StylingContext';
import UrlContextProvider from './context/UrlContext';
import LoggingContextProvider from './context/LoggingContext';
import PollContextProvider from './context/PollContext';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import { CookiesProvider } from 'react-cookie';
import { SnackbarProvider } from 'notistack';

import { client } from './graphql/configuration';
import { ApolloProvider } from '@apollo/client';

const urlParams = new URLSearchParams(window.location.search);
const contextID = urlParams.get('c');
const otpKey = urlParams.get('otp');
const stage = process.env.REACT_APP_STAGE;
let companyIdentification = '';
if (
  urlParams.get('ci') === undefined ||
  urlParams.get('ci') === '' ||
  urlParams.get('ci') === null
) {
  companyIdentification = process.env.REACT_APP_COMPANYIDENTIFICATION;
} else {
  companyIdentification = urlParams.get('ci');
}

ReactDOM.render(
  <React.StrictMode>
    <LoggingContextProvider
      companyIdentification={companyIdentification}
      stage={stage}
      contextID={contextID}
      otpKey={otpKey}
    >
      <PollContextProvider>
        <SnackbarProvider maxSnack={4} autoHideDuration={6000}>
          <CookiesProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
              <LoginContextProvider>
                <StylingContextProvider>
                  <ApellaContextProvider
                    contextID={contextID}
                    otpKey={otpKey}
                    companyIdentification={companyIdentification}
                    stage={stage}
                  >
                    <UrlContextProvider>
                      <ApolloProvider client={client}>
                        <App stage={stage} companyIdentification={companyIdentification} />
                      </ApolloProvider>
                    </UrlContextProvider>
                  </ApellaContextProvider>
                </StylingContextProvider>
              </LoginContextProvider>
            </MuiPickersUtilsProvider>
          </CookiesProvider>
        </SnackbarProvider>
      </PollContextProvider>
    </LoggingContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
