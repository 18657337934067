import React, { useContext, useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { ApellaContext } from '../../context/ApellaContext';
import { UrlContext } from '../../context/UrlContext';
import { StylingContext } from '../../context/StylingContext';
import Backdrop from '@material-ui/core/Backdrop';
import Spinner from 'react-spinkit';
import { muiTableTextLabels } from '../../translation/Translation';
import { listRecordings, getDownload } from '../../helper/getData';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Recordings = (props) => {
  const classes = useStyles();
  const contextData = useContext(ApellaContext);
  const urlData = useContext(UrlContext);
  const stylingData = useContext(StylingContext);
  const [recordingsData, setrecordingsData] = useState([]);
  const [backdropOpen, setbackdropOpen] = useState(true);

  useEffect(() => {
    const loadRecordings = async () => {
      setbackdropOpen(true);
      let recordings = await listRecordings({
        agentId: contextData.agentData.id,
        clientId: contextData.clientData.id,
        apiToken: contextData.apiToken,
        urlParam:
          urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.listRecordings.endpoint,
      });

      setrecordingsData(recordings.Items);
    };
    loadRecordings();
    console.log(recordingsData);
    setbackdropOpen(false);
  }, []); // eslint-disable-line

  const handleDownload = (index) => {
    console.log('recordingsData', recordingsData);
    getDownload({
      fileObjName: recordingsData[index].path,
      apiToken: contextData.apiToken,
      urlParam:
        urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.downloadFromS3.endpoint,
      documentName: `${recordingsData[index].consultationTitle}-${recordingsData[index].FILEDATE}.mp4`,
    }).then((path) => {
      const url = path.url;
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const columns = [
    {
      name: 'FILEDATE',
      label: 'Dateiname',
    },
    {
      name: 'participants',
      label: 'Teilnehmer',
    },
    {
      name: 'consultationTitle',
      label: 'Beratungstitel',
    },
    {
      name: 'consultationDate',
      label: 'Datum',
    },
    {
      name: 'consultationTime',
      label: 'Uhrzeit',
    },
  ];

  const options = {
    rowsPerPage: 20,
    rowsPerPageOptions: [5, 10, 20],
    filterType: 'textField',
    caseSensitive: false,
    textLabels: muiTableTextLabels,
    print: false,
    download: false,
    filter: false,
    viewColumns: false,
    searchable: true,
    selectableRows: 'none',
    selectableRowsOnClick: false,
    responsive: 'vertical',
  };
  let tableData = [];

  if (recordingsData !== []) {
    tableData = recordingsData.map((recording, index) => [
      <Link href="#" onClick={() => handleDownload(index)} underline="hover">
        {recording.FILEDATE}
      </Link>,
      recording.hasOwnProperty('metadata')
        ? recording.metadata.participants.map((part, index, recording) => {
            if (index + 1 === recording.length) return `${part.user.name}`;
            return `${part.user.name}, `;
          })
        : '',

      recording.consultationTitle,
      recording.consultationDate.split('-')[0],
      recording.consultationDate.split('-')[1],
    ]);
  }

  return (
    <div>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <Spinner name="line-scale" color={stylingData.colors.color3} />
      </Backdrop>
      <MUIDataTable
        title={'Liste der gespeicherten Aufnahmen'}
        columns={columns}
        elevation={1}
        data={tableData}
        options={options}
      />
    </div>
  );
};

export default Recordings;
