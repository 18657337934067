import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import BasisTab from './BasisTap';
import UserPanel from './UserPanel';
import { ApellaContext } from '../../context/ApellaContext';
import { UrlContext } from '../../context/UrlContext';
import { StylingContext } from '../../context/StylingContext';
import { LoggingContext } from '../../context/LoggingContext';
import { Drawer } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop';
import Spinner from 'react-spinkit';
import Recordings from './Recordings';
import { updateLog } from '../../helper/getData';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 100,
      color: '#fff',
    },
    drawer: {
      [theme.breakpoints.down('md')]: {
        width: 250,
        padding: theme.spacing(1),
      },
      [theme.breakpoints.up('lg')]: {
        width: 400,
        padding: theme.spacing(2),
      },
      height: '100%',
    },
    drawerPaper: {
      [theme.breakpoints.down('md')]: {
        width: 250,
        padding: theme.spacing(1),
        fontSize: '10px',
      },
      [theme.breakpoints.up('lg')]: {
        width: 400,
        padding: theme.spacing(2),
      },
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    drawerContainer: {
      overflowX: 'hidden',
      [theme.breakpoints.down('md')]: {
        width: 250,
        padding: theme.spacing(1),
      },
      [theme.breakpoints.up('lg')]: {
        width: 400,
        padding: theme.spacing(2),
      },
    },
    content: {
      flexGrow: 1,
      //padding: "0 10px 10px 10px",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      background: theme.palette.grey['900'],
    },
  };
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function KonfigurationsTab() {
  const classes = useStyles();
  const data = useContext(ApellaContext);
  const urlData = useContext(UrlContext);
  const stylingData = useContext(StylingContext);
  const loggingData = useContext(LoggingContext);
  const [backdropOpen, setbackdropOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [welcomeDialogOpen, setWelcomeDialogOpen] = useState(false); // eslint-disable-line

  const buildOptions = (postData) => {
    return {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        /* 'Content-Type': 'application/json', */
        Authorization: `Bearer ${data.apiToken}`,
      },
      body: JSON.stringify(postData), // body data type must match "Content-Type" header
    };
  };

  async function fetchRooms() {
    setbackdropOpen(true);
    if (!data.agentData.id || !data.clientData.id || !data.apiToken) return;
    let roomsList = [];

    const postData = {
      agentData: {
        id: data.agentData.id,
      },
      clientData: {
        id: data.clientData.id,
      },
    };
    try {
      const response = await fetch(
        urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.listRooms.endpoint,
        buildOptions(postData)
      );

      if (response.status === 200) {
        let json = await response.json();
        roomsList = json.Items;
        data.setRoomsList(json.Items);
      }
    } catch (error) {
      console.log(`Übertragungsfehler: ${error}`);
    } finally {
      setbackdropOpen(false);
    }

    return roomsList;
  }

  useEffect(() => {
    if (data.agentData.id && data.clientData.id && data.apiToken) {
      fetchRooms().then((roomsList = []) => {
        if (roomsList.length === 0) {
          setWelcomeDialogOpen(true);
        }
      });
    }
  }, [data.agentData.id, data.clientData.id, data.apiToken]); // eslint-disable-line

  // eslint-disable-next-line
  const startNewRoom = () => {
    data.setNewRoom(true);
    setWelcomeDialogOpen(false);
  };
  // eslint-disable-next-line
  const closeWelcomeDialog = () => {
    setWelcomeDialogOpen(false);
  };

  const handleChange = (event, newValue) => {
    data.setNewRoom(false);
    setValue(newValue);
  };
  useEffect(() => {
    if (data.apiToken) {
      loggingData.setSequence({
        type: 'loadRoomlist',
        agentData: {
          id: data.agentData.id,
          firstName: data.agentData.agentData.firstName,
          lastName: data.agentData.agentData.lastName,
        },
        clientData: {
          id: data.clientData.id,
          firstName: data.clientData.clientData.firstName,
          lastName: data.clientData.clientData.lastName,
        },
      });
    }
  }, [data.apiToken]); // eslint-disable-line

  useEffect(() => {
    if (data.apiToken) {
      const interval = setInterval(() => {
        updateLog({
          agentId: data.agentData.id,
          date: loggingData.theLogDateAndTime,
          sequence: JSON.parse(localStorage.getItem('sequence')),
          companyIdentification: data.companyIdentification,
          contextID: data.contextID,
          stage: data.stage,
          otpKey: data.otpKey,
          urlParam:
            urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.updateLog.endpoint,
          apiToken: data.apiToken,
        });
      }, 60000);

      return () => clearInterval(interval);
    }
  }, [data.apiToken]); // eslint-disable-line

  if (!data.clientData.id || !data.agentData.id)
    return <div>{urlData.urlList.companyname} connect... Bitte warten.</div>;

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Offene Beratungen" {...a11yProps(0)} />
          <Tab label="Geschlossene Beratungen" {...a11yProps(1)} />
          <Tab label="Gespeicherte Aufnahmen" {...a11yProps(2)} />
        </Tabs>
      </AppBar>

      <main className={classes.content}>
        <Toolbar />
        <TabPanel value={value} index={0}>
          <BasisTab onFetchRooms={fetchRooms} showComplete={false} />
        </TabPanel>

        <TabPanel value={value} index={1}>
          <BasisTab onFetchRooms={fetchRooms} showComplete={true} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Recordings />
        </TabPanel>
      </main>

      <Box color={'text.primary'}>
        <Drawer
          className={classes.drawer}
          classes={{ paper: classes.drawerPaper }}
          variant="permanent"
          anchor="right"
        >
          <Toolbar />
          <div className={classes.drawerContainer}>
            <UserPanel
              onNewRoom={() => {
                setValue(0);
                data.setNewRoom(true);
              }}
              className={classes.user}
            />
          </div>
        </Drawer>
      </Box>

      {/*       {data.NewRoom && (
        <Backdrop className={classes.backdrop} open={backdropOpen}>
          <Spinner name="line-scale"color={stylingData.colors.color3} />
        </Backdrop>
      )} */}

      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <Spinner name="line-scale" color={stylingData.colors.color3} />
      </Backdrop>
    </div>
  );
}
