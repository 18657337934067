import React, { useState, createContext, useReducer, useEffect } from 'react';
export const LoggingContext = createContext();
const dateOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};
const theLogDateAndTime = new Date().toLocaleDateString('de-DE', dateOptions);
const sequenceFunction = (sequence, action) => {
  let timeAndDate = new Date().toLocaleDateString('de-DE', dateOptions);
  let timestamp = Date.now();
  switch (action.type) {
    //load the Page
    case 'loadRoomlist':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Homepage',
          action: 'load roomlist',
          button: 'none',
          informations: { agentData: action.agentData, clientData: action.clientData },
        },
      ];
    //Roomlist
    case 'createRoom':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Homepage',
          action: 'create room',
          button: 'Neue Beratung / Adhoc Beratung',
          informations: {
            agentData: action.agentData,
            clientData: action.clientData,
            consultationDate: action.consultationDate,
            consultationTime: action.consultationTime,
            consultationTitle: action.consultationTitle,
          },
        },
      ];
    case 'straitStart':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Homepage',
          action: 'jump into Meeting',
          button: 'Starten',
          informations: {
            agentData: action.agentData,
            clientData: action.clientData,
            consultationDate: action.consultationDate,
            consultationTime: action.consultationTime,
            consultationTitle: action.consultationTitle,
          },
        },
      ];

    case 'deleteRoom':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Homepage',
          action: 'delete room',
          button: 'Löschen',
          informations: {
            agentData: action.agentData,
            clientData: action.clientData,
            consultationDate: action.consultationDate,
            consultationTime: action.consultationTime,
            consultationTitle: action.consultationTitle,
          },
        },
      ];

    // Preloadpages
    case 'openPreload':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Homepage',
          action: 'open PreloadPage',
          button: 'Editieren/Starten',
          informations: {
            agentData: action.agentData,
            clientData: action.clientData,
            consultationDate: action.consultationDate,
            consultationTime: action.consultationTime,
            consultationTitle: action.consultationTitle,
          },
        },
      ];

    case 'agenda':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Preloadpage',
          action: 'type Text into textarea',
          button: 'agenda textarea',
          informations: {
            agendatext: action.text,
          },
        },
      ];
    case 'send_customer_invitation':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Preloadpage',
          action: 'send invitation to client',
          button: 'Einladung an Kunden versenden',
          informations: {},
        },
      ];
    case 'addParticipant_dialogue':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Preloadpage',
          action: 'open add Participant dialogue',
          button: 'Weitere Teilnehmer einladen',
          informations: {},
        },
      ];
    case 'addParticipant_dialogue_cancel':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Preloadpage',
          action: 'cancel add Participant dialogue',
          button: 'Abbrechen',
          informations: {},
        },
      ];
    case 'addParticipant_send_invitation':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Preloadpage',
          action: 'send invitation to new participant',
          button: 'Einladung senden',
          informations: action.userValues,
        },
      ];

    case 'openDropzone_preload':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Preloadpage',
          action: 'open Dropzone on Preloadpage',
          button: 'Dokument Hochladen',
          informations: {},
        },
      ];

    case 'closePreload':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Preloadpage',
          action: 'close Preloadpage',
          button: 'Schliessen',
          informations: {},
        },
      ];
    //Hauptmenu
    case 'startMeeting':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Meeting',
          action: 'start meeting',
          button: 'none',
          informations: {
            consultationDate: action.consultationDate,
            consultationTitle: action.consultationTitle,
            roomHash: action.roomHash,
          },
        },
      ];

    case 'homeButton':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Meeting',
          action: 'show Homescreen',
          button: 'Home',
          informations: {},
        },
      ];

    case 'videoButton':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Meeting',
          action: 'show Videoscreen',
          button: 'Video',
          informations: {},
        },
      ];

    case 'editorButton':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Meeting',
          action: 'show Editor',
          button: 'Editor',
          informations: {},
        },
      ];

    case 'whiteboardButton':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Meeting',
          action: 'show Whiteboard',
          button: 'Whiteboard',
          informations: {},
        },
      ];

    case 'contractsButton':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Meeting',
          action: 'show contracts',
          button: 'Verträge',
          informations: {},
        },
      ];

    case 'documentsButton':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Meeting',
          action: 'show documents',
          button: 'Dokumente',
          informations: {},
        },
      ];

    case 'signButton':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Meeting',
          action: 'show documents to sign',
          button: 'Unterschrift',
          informations: {},
        },
      ];

    case 'recordButton':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Meeting',
          action: 'start/stop recording',
          button: 'Aufnahme',
          informations: {},
        },
      ];

    case 'screensharingButton':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Meeting',
          action: 'start screensharing',
          button: 'Screensharing',
          informations: {},
        },
      ];

    case 'addParticipantButton':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Meeting',
          action: 'add Participant',
          button: 'Teilnehmer',
          informations: {},
        },
      ];

    case 'settingsButton':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Meeting',
          action: 'open settings',
          button: 'Einstellungen',
          informations: {},
        },
      ];
    case 'exitButton':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Meeting',
          action: 'open exit dialogue',
          button: 'Exit',
          informations: {},
        },
      ];

    //Dokumente
    case 'openDropzone':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Dokumente',
          action: 'open dropzone',
          button: 'Dateien hinzufügen',
          informations: {},
        },
      ];
    case 'addDocument':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Dokumente',
          action: 'add file',
          button: 'dropzone',
          informations: { fileName: action.fileName },
        },
      ];
    case 'removeDocument':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Dokumente',
          action: 'remove file from dropzone',
          button: 'delete',
          informations: { fileName: action.fileName },
        },
      ];

    case 'fileUpload':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Dokumente',
          action: 'fileupload to s3 bucket from dropzone',
          button: 'Senden',
          informations: { fileName: action.fileName },
        },
      ];

    //Signprocess
    case 'signDocument':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Unterschrift',
          action: 'start sign process',
          button: 'Dokumente Unterschreiben',
          informations: { fileName: action.fileName },
        },
      ];
    case 'cancelSign':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Unterschrift',
          action: 'cancel sign process',
          button: 'Abbrechen',
          informations: {},
        },
      ];
    case 'transactionManagement':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Unterschrift',
          action: 'show transactionPage',
          button: 'Vorgangsverwaltung',
          informations: {},
        },
      ];
    case 'addUser':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Teilnehmer',
          action: 'add new User',
          button: 'Senden',
          informations: { userValues: action.userValues },
        },
      ];
    case 'lockRoom':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Exit',
          action: 'locks the room',
          button: 'Checkbox Raum Sperren',
          informations: { locked: action.locked },
        },
      ];

    case 'cancelExitRoom':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Exit',
          action: 'cancel exit the room',
          button: 'Abbrechen',
          informations: {},
        },
      ];
    case 'closeRoom':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Exit',
          action: 'closes the room',
          button: 'Abschliessen / Später Fortsetzen',
          informations: { value: action.value },
        },
      ];
    case 'exitConference':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Exit',
          action: 'finally leaves the room',
          button: 'Beratungsraum schließen',
          informations: {},
        },
      ];

    //Userdata
    case 'userJoints':
      return [
        ...sequence,
        {
          timestamp: timestamp,
          date: timeAndDate,
          mainPage: 'Meeting',
          action: 'user joints the meeting',
          button: 'none',
          informations: { value: action.value },
        },
      ];

    default:
      return sequence;
  }
};
const LoggingContextProvider = (props) => {
  const [sequence, setSequence] = useReducer(sequenceFunction, []);
  const [companyIdentification, setCompanyIdentification] = useState(props.companyIdentification); // eslint-disable-line
  const [contextID, setContextId] = useState(props.contextID); // eslint-disable-line
  const [stage, setStage] = useState(props.stage); // eslint-disable-line
  const [otpKey, setOtpKey] = useState(props.otpKey); // eslint-disable-line
  useEffect(() => {
    localStorage.setItem('sequence', JSON.stringify(sequence));
  }, [sequence]);

  return (
    <LoggingContext.Provider
      value={{
        sequence: sequence,
        setSequence: setSequence,
        theLogDateAndTime: theLogDateAndTime,
        companyIdentification: companyIdentification,
        contextID: contextID,
        stage: stage,
        otpKey: otpKey,
      }}
    >
      {props.children}
    </LoggingContext.Provider>
  );
};

export default LoggingContextProvider;
