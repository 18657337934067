import Grid from '@material-ui/core/Grid';
import {
  Card,
  Collapse,
  ListItem,
  ListItemAvatar,
  ListItemText,
  List,
  ListItemIcon,
  Avatar,
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import EmailIcon from '@material-ui/icons/Email';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { Fragment, useContext, useState, useReducer, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ApellaContext } from '../../context/ApellaContext';
import { sendEmail, getIdsOfUserToDelete, deleteUsers } from '../../helper/getData';
import { saveRoom } from '../../helper/saveRoom';
import { useSnackbar } from 'notistack';
import Backdrop from '@material-ui/core/Backdrop';
import Spinner from 'react-spinkit';
import { StylingContext } from '../../context/StylingContext';
import { UrlContext } from '../../context/UrlContext';
import { LoggingContext } from '../../context/LoggingContext';
import ToggleIcon from 'material-ui-toggle-icon';

const useStyles = makeStyles((theme) => ({
  expertInputField: {
    margin: 5,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  root: {
    position: 'relative',
    paddingBottom: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 10,
    color: '#fff',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  participants: {
    width: 420,
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
  card: {
    minWidth: 275,
    marginRight: 20,
    marginTop: 10,
  },
  urlText: {
    display: 'inline-block',
    wordWrap: 'break-word',
    maxWidth: 300,
  },
}));

export default function Participants() {
  const classes = useStyles();
  const data = useContext(ApellaContext);
  const stylingData = useContext(StylingContext);
  const urlData = useContext(UrlContext);
  const loggingData = useContext(LoggingContext);
  const { enqueueSnackbar } = useSnackbar();
  const [expertFirstname, setexpertFirstname] = useState('');
  const [expertLastname, setexpertLastname] = useState('');
  const [expertEmailValue, setexpertEmailValue] = useState('');
  const [openExpertDialog, setopenExpertDialog] = useState(false);
  const [backdropOpen, setbackdropOpen] = useState(false);
  const [hoverOverLock, sethoverOverLock] = useState(false);
  const [hoveroveraddPerson, sethoveroveraddPerson] = useState(false);
  const [hoveroverEmail, sethoveroverEmail] = useState(false);
  const [openCollapsedMenu, setopenCollapsedMenu] = useState([]);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0); // eslint-disable-line

  //const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, 'gm');

  const initialState = data.roomData.locked;
  const reducer = (state, action) => {
    if (action === 'change') {
      data.roomData.locked = !data.roomData.locked;
      state = !state;
      return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let tempArray = [];
    for (let i = 0; i < data.experts.length; i++) {
      tempArray.push(false);
    }
    setopenCollapsedMenu(tempArray);
  }, []); // eslint-disable-line

  const emailClick = async () => {
    setbackdropOpen(true);
    try {
      const response = await sendEmail({
        receiver: 'client',
        agentId: data.agentData.id,
        clientId: data.clientData.id,
        consultationDate: data.roomData.consultationDate,
        consultationTitle: data.roomData.consultationTitle,
        urlParam: urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.sendEmail.endpoint,
        apiToken: data.apiToken,
        repeating: data.roomData.repeating,
        kindOfRepeating: data.roomData.kindOfRepeating,
        fullDate: data.roomData.fullDate,
        selectedDays: data.roomData.selectedDays,
        companyIdentification: data.companyIdentification,
        stage: data.stage,
      });
      if (
        data.clientData.clientData.email1 !== '' &&
        data.clientData.clientData.email1 !== undefined
      ) {
        if (typeof response.error !== 'undefined') {
          console.log(response);
          enqueueSnackbar(response.message, { variant: 'error' });
          setbackdropOpen(false);
          return;
        }
        /* await addExpertAndSaveRoom({
          firstName: data.clientData.clientData.firstName,
          lastName: data.clientData.clientData.lastName,
          email: data.clientData.clientData.email1,
        }); */
        enqueueSnackbar('Eine E-Mail mit dem Einladungslink wurde gesendet.', {
          variant: 'success',
        });
      } else if (
        data.clientData.clientData.email2 !== '' &&
        data.clientData.clientData.email2 !== undefined
      ) {
        /* await addExpertAndSaveRoom({
          firstName: data.clientData.clientData.firstName,
          lastName: data.clientData.clientData.lastName,
          email: data.clientData.clientData.email2,
        }); */
        enqueueSnackbar('Eine E-Mail mit dem Einladungslink wurde gesendet.', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(
          'Die E-Mail konnte nicht gesendet werden. Es sind keine Emailadressen hinterlegt.',
          { variant: 'error' }
        );
        console.warn(
          'Die E-Mail konnte nicht gesendet werden. Es sind keine Emailadressen hinterlegt.'
        );
      }
    } catch (e) {
      enqueueSnackbar('Die E-Mail konnte nicht gesendet werden.', { variant: 'error' });
      console.warn(e);
    }
    await getParticipansAgain();
    setbackdropOpen(false);
  };

  const handleExpertInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    switch (name) {
      case 'expertFirstname':
        setexpertFirstname(value);
        break;
      case 'expertLastname':
        setexpertLastname(value);
        break;
      case 'expertEmailValue':
        setexpertEmailValue(value);
        break;
      default:
        break;
    }
  };

  const handleCloseExpertDialog = () => {
    setopenExpertDialog(false);
  };

  const handleExpertIconAndClose = async () => {
    setopenExpertDialog(false);
    setbackdropOpen(true);

    try {
      loggingData.setSequence({
        type: 'addParticipant_send_invitation',
        userValues: {
          firstName: expertFirstname,
          lastName: expertLastname,
          email: expertEmailValue,
        },
      });
      /*  const isValidEmail = emailRegex.test(expertEmailValue); */

      /* console.log(isValidEmail);

      if (!isValidEmail) 
        setbackdropOpen(false);
        enqueueSnackbar('Die Email ist in einem ungültigen Format!', { variant: 'error' });
        return;
       } else { */
      const response = await sendEmail({
        receiver: {
          userValues: {
            firstName: expertFirstname,
            lastName: expertLastname,
            email: expertEmailValue,
          },
        },
        agentId: data.agentData.id,
        clientId: data.clientData.id,
        consultationDate: data.roomData.consultationDate,
        consultationTitle: data.roomData.consultationTitle,
        urlParam: urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.sendEmail.endpoint,
        apiToken: data.apiToken,
        repeating: data.roomData.repeating,
        kindOfRepeating: data.roomData.kindOfRepeating,
        fullDate: data.roomData.fullDate,
        selectedDays: data.roomData.selectedDays,
        companyIdentification: data.companyIdentification,
        stage: data.stage,
      });
      if (response instanceof TypeError) {
        throw new Error(response.message);
      }
      setbackdropOpen(false);
      enqueueSnackbar('Eine E-Mail mit dem Einladungslink wurde gesendet.', {
        variant: 'success',
      });
      /* } */
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
      setbackdropOpen(false);
    }
    setexpertFirstname('');
    setexpertLastname('');
    setexpertEmailValue('');
    await getParticipansAgain();
  };

  const addExpertAndSaveRoom = async ({ firstName, lastName, email }) => {
    const newExperts = [
      ...data.experts.filter(
        (e) => e.firstName !== firstName && e.lastName !== lastName && e.email !== email
      ),
      { firstName, lastName, email },
    ];
    if (!data.saveVariable) {
      data.setsaveVariable(true);
      await saveRoom(
        data.apiToken,
        data.agentData,
        data.clientData,
        data.roomData,
        data.documents,
        data.url,
        data.insignData,
        data.activeStep,
        data.otpKey,
        data.companyIdentification,
        data.stage,
        data.contextID,
        data.agentConnectionId,
        newExperts,
        data.agendaText,
        stylingData.colors,
        urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.updateRoom.endpoint
      );

      data.setexperts(newExperts);
      data.setsaveVariable(false);
    }
  };

  // eslint-disable-next-line
  const saveExperts = async () => {
    setbackdropOpen(true);
    if (expertEmailValue && (expertFirstname || expertLastname)) {
      // TODO: checken, ob e-mail valide ist

      await addExpertAndSaveRoom({
        firstName: expertFirstname,
        lastName: expertLastname,
        email: expertEmailValue,
      });

      setexpertFirstname('');
      setexpertLastname('');
      setexpertEmailValue('');
    }
    setbackdropOpen(false);
  };

  const lockRoom = () => {
    if (!data.saveVariable) {
      data.setsaveVariable(true);
      setbackdropOpen(true);
      dispatch('change');
      saveRoom(
        data.apiToken,
        data.agentData,
        data.clientData,
        data.roomData,
        data.documents,
        data.url,
        data.insignData,
        data.activeStep,
        data.otpKey,
        data.companyIdentification,
        data.contextID,
        data.agentConnectionId,
        data.experts,
        data.agendaText,
        stylingData.colors,
        urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.updateRoom.endpoint
      );
      setbackdropOpen(false);
      data.setsaveVariable(false);
    }
  };
  const hoveroverLock = () => {
    sethoverOverLock((prev) => !prev);
  };

  const hoveroverAddPerson = () => {
    sethoveroveraddPerson((prev) => !prev);
  };

  const hoverOverEmail = () => {
    sethoveroverEmail((prev) => !prev);
  };

  const handleOpenParticipantMenu = (index) => {
    let tempArray2 = openCollapsedMenu;
    tempArray2[index] = !tempArray2[index];
    setopenCollapsedMenu(tempArray2);
    forceUpdate();
  };

  const handlesendEmailAgain = async (firstName, lastName, email, client) => {
    setbackdropOpen(true);
    await handleDeleteUser(firstName, lastName, email);
    try {
      if (client) {
        emailClick();
      } else {
        const response = await sendEmail({
          receiver: {
            userValues: {
              firstName: firstName,
              lastName: lastName,
              email: email,
            },
          },

          agentId: data.agentData.id,
          clientId: data.clientData.id,
          consultationDate: data.roomData.consultationDate,
          consultationTitle: data.roomData.consultationTitle,
          urlParam:
            urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.sendEmail.endpoint,
          apiToken: data.apiToken,
          repeating: data.roomData.repeating,
          kindOfRepeating: data.roomData.kindOfRepeating,
          fullDate: data.roomData.fullDate,
          selectedDays: data.roomData.selectedDays,
          companyIdentification: data.companyIdentification,
          stage: data.stage,
        });
        if (typeof response.error !== 'undefined') {
          console.log(response);
          enqueueSnackbar(response.message, { variant: 'error' });
          setbackdropOpen(false);
          throw new Error('Fehler!!!');
        }
      }

      /* await saveExperts(); */

      enqueueSnackbar('Eine E-Mail mit dem Einladungslink wurde gesendet.', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Die E-Mail konnte nicht gesendet werden.', { variant: 'error' });
      console.warn(e);
    }
    setexpertFirstname('');
    setexpertLastname('');
    setexpertEmailValue('');
    getParticipansAgain();
    setbackdropOpen(false);
  };

  const getParticipansAgain = async () => {
    setbackdropOpen(true);
    const participants = await fetch(
      urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.getUsers.endpoint,
      {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'omit',
        headers: {
          Authorization: `Bearer ${data.apiToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          agentId: data.agentData.id,
          clientId: data.clientData.id,
          consultationDate: data.roomData.consultationDate,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => response.Items)
      .then((participants) =>
        participants.map((participant) => ({
          firstName: '',
          lastName: '',
          email: '',
          client: '',
          ...participant,
        }))
      );
    data.setexperts(participants);
    forceUpdate();

    setbackdropOpen(false);
  };

  const handleDeleteUser = async (firstName, lastName, email) => {
    setbackdropOpen(true);
    await getIdsOfUserToDelete({
      agentId: data.agentData.id,
      clientId: data.clientData.id,
      consultationDate: data.roomData.consultationDate,
      firstName: firstName,
      lastName: lastName,
      email: email,
      apiToken: data.apiToken,
      urlParam:
        urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.getIdsToDelete.endpoint,
    })
      .then((response) => {
        if (response !== undefined && response !== '')
          response.data.forEach(async (element) => {
            await deleteUsers({
              password: element.password,
              apiToken: data.apiToken,
              urlParam:
                urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.deleteUsers.endpoint,
            });
          });
      })
      .then((response) => {
        setTimeout(async () => {
          await getParticipansAgain();
          forceUpdate();
        }, 1500);
      });

    setbackdropOpen(false);
  };

  const copyTextToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  };

  const handleShareLink = (copyText) => {
    copyTextToClipboard(copyText)
      .then(() => {
        enqueueSnackbar('Beratungslink in die Zwischenablage kopiert!', {
          variant: 'success',
        });
      })
      .catch((err) => {
        enqueueSnackbar(
          'Beim Kopieren des Beratungslink in die Zwischenablage ist ein Fehler aufgetreten',
          {
            variant: 'error',
          }
        );
        console.log(err);
      });
  };

  return (
    <Fragment>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <Spinner name="line-scale" color={stylingData.colors.color3} />
      </Backdrop>
      <Grid container spacing={5}>
        <Grid container item xs={12}>
          {data.experts.length > 0 &&
            data.experts.map(
              (
                { firstName = '', lastName = '', email = '', client = '', password = '' },
                index
              ) => {
                return (
                  <Fragment>
                    <Grid item>
                      <Card className={classes.card} variant="outlined">
                        <List>
                          <ListItem key={'expert-' + index} className={classes.participants}>
                            <ListItemAvatar>
                              <Avatar
                                src={client && `${data.clientData.clientData.photo}`}
                                alt={`${firstName} ${lastName}`}
                              >
                                {`${firstName.substr(0, 1)}${lastName.substr(0, 1)}`.toUpperCase()}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`${firstName} ${lastName}`} secondary={email} />
                            {openCollapsedMenu[index] ? (
                              <IconButton onClick={() => handleOpenParticipantMenu(index)}>
                                <ExpandLess />
                              </IconButton>
                            ) : (
                              <IconButton onClick={() => handleOpenParticipantMenu(index)}>
                                <ExpandMore />
                              </IconButton>
                            )}
                            <Collapse in={openCollapsedMenu[index]} timeout="auto" unmountOnExit>
                              <List component="div" disablePadding>
                                <ListItem button className={classes.nested}>
                                  <Button
                                    disableElevation
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => handleDeleteUser(firstName, lastName, email)}
                                  >
                                    Teilnehmer Ausladen
                                  </Button>
                                </ListItem>
                                <ListItem button className={classes.nested}>
                                  <Button
                                    disableElevation
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      handlesendEmailAgain(firstName, lastName, email, client)
                                    }
                                  >
                                    Email erneut senden
                                  </Button>
                                </ListItem>
                                <ListItem>
                                  <ListItemIcon>
                                    <IconButton
                                      onClick={() =>
                                        handleShareLink(
                                          `https://${urlData.urlList.domain}?roomPassword=${password}`
                                        )
                                      }
                                    >
                                      <ShareIcon />
                                    </IconButton>
                                  </ListItemIcon>
                                  <ListItemText
                                    primary="Beratungslink"
                                    secondary={
                                      <Fragment>
                                        <Typography
                                          className={classes.urlText}
                                          component="span"
                                          variant="body2"
                                          color="textPrimary"
                                        >
                                          {`https://${urlData.urlList.domain}?roomPassword=${password}`}
                                        </Typography>
                                      </Fragment>
                                    }
                                  />
                                </ListItem>
                              </List>
                            </Collapse>
                          </ListItem>
                        </List>
                      </Card>
                    </Grid>
                  </Fragment>
                );
              }
            )}
        </Grid>
        {data.clientData.id !== 'xxxxx' ? (
          <Grid item>
            <Button
              disableElevation
              variant="contained"
              onClick={() => {
                emailClick();
                loggingData.setSequence({
                  type: 'send_customer_invitation',
                });
              }}
              onMouseOver={() => hoverOverEmail()}
              onMouseOut={() => hoverOverEmail()}
              startIcon={
                <ToggleIcon
                  on={hoveroverEmail}
                  onIcon={<EmailIcon />}
                  offIcon={<MailOutlineIcon />}
                />
              }
            >
              <Typography variant="body2">Einladung an Kunden versenden</Typography>
            </Button>
          </Grid>
        ) : null}

        <Grid item>
          <Button
            disableElevation
            variant="contained"
            onClick={() => {
              loggingData.setSequence({
                type: 'addParticipant_dialogue',
              });
              setopenExpertDialog(true);
            }}
            onMouseOver={() => hoveroverAddPerson()}
            onMouseOut={() => hoveroverAddPerson()}
            startIcon={
              <ToggleIcon
                on={hoveroveraddPerson}
                onIcon={<PersonAddIcon />}
                offIcon={<PersonAddOutlinedIcon />}
              />
            }
          >
            <Typography variant="body2">Weitere Teilnehmer einladen</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            disableElevation
            variant="contained"
            onClick={() => lockRoom()}
            onMouseOver={() => hoveroverLock()}
            onMouseOut={() => hoveroverLock()}
            startIcon={
              state ? (
                <ToggleIcon on={hoverOverLock} onIcon={<LockOpenIcon />} offIcon={<LockIcon />} />
              ) : (
                <ToggleIcon on={hoverOverLock} onIcon={<LockIcon />} offIcon={<LockOpenIcon />} />
              )
            }
          >
            <Typography variant="body2">
              {state
                ? 'Entsperren des Beratungsraums für Teilnehmer'
                : 'Sperren des Beratungsraums für Teilnehmer'}
            </Typography>
          </Button>
        </Grid>
      </Grid>

      <Dialog
        open={openExpertDialog}
        onClose={handleCloseExpertDialog}
        aria-labelledby="form-expert-invite"
      >
        <DialogTitle id="form-expert-invite-title">Weitere Teilnehmer einladen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Um eine weitere Personen zur Teilnahme an dieser Beratung einzuladen, können Sie ihr
            über dieses Formular eine E-Mail mit einem Einladungslink senden.
          </DialogContentText>
          <form>
            <TextField
              name="expertFirstname"
              className={classes.expertInputField}
              required
              autoFocus
              margin="dense"
              id="expertFirstname"
              label="Vorname"
              type="text"
              variant="outlined"
              onChange={handleExpertInputChange}
            />
            <TextField
              name="expertLastname"
              className={classes.expertInputField}
              required
              margin="dense"
              id="expertLastname"
              label="Nachname"
              type="text"
              variant="outlined"
              onChange={handleExpertInputChange}
            />
            <TextField
              name="expertEmailValue"
              className={classes.expertInputField}
              required
              margin="dense"
              id="expertEmailValue"
              label="Email"
              type="email"
              fullWidth
              variant="outlined"
              onChange={handleExpertInputChange}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            disableElevation
            variant="outlined"
            color="secondary"
            onClick={() => {
              loggingData.setSequence({
                type: 'addParticipant_dialogue_cancel',
              });
              handleCloseExpertDialog();
            }}
          >
            Abbrechen
          </Button>
          <Button
            disableElevation
            disabled={!(expertEmailValue && (expertFirstname || expertLastname))}
            onClick={() => {
              handleExpertIconAndClose();
            }}
            variant="contained"
            color="primary"
          >
            Einladung senden
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
