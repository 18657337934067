import React, { Fragment, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ApellaContext } from '../context/ApellaContext';
import { LoginContext } from '../context/LoginContext';
import { UrlContext } from '../context/UrlContext';
import { useCookies } from 'react-cookie';

import Iframe from 'react-iframe';

const Editor = (props) => {
  const data = useContext(ApellaContext);
  const loginData = useContext(LoginContext);
  const urlData = useContext(UrlContext);
  const [groupPad, setgroupPad] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies(['sessionID']); // eslint-disable-line
  const [cookieSet, setcookieSet] = useState(false);
  const [width, setwidth] = useState(0);
  const [left, setleft] = useState(0);
  useEffect(() => {
    if (props.showMenu) {
      setwidth(73);
      setleft(72);
    } else {
      setwidth(0);
      setleft(0);
    }
  }, [props.showMenu]);
  const useStyles = makeStyles((theme) => ({
    content: {
      justifyContent: 'flex-end',
      padding: theme.spacing(3),
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    editor: {
      position: 'absolute',
      top: 64,
      left: left,
      width: `calc(100% - ${width}px)`,
      height: 'calc(100% - 65px)',
    },
  }));
  const classes = useStyles();
  const connectToEtherpadUrl =
    urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.connectToEtherpad.endpoint;
  const etherpadUrl = urlData.urlList.etherpadUrl;
  useEffect(() => {
    const setupEtherpad = async () => {
      removeCookie('sessionID');
      const createGroup = await fetch(connectToEtherpadUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors',
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${data.apiToken}` },
        body: JSON.stringify({
          command: 'createGroupIfNotExistsFor',
          roomHash: data.roomData.roomHash.slice(0, 20),
        }), // body data type must match "Content-Type" header
      });
      const createGroupJson = await createGroup.json();
      const createGroupPad = await fetch(connectToEtherpadUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors',
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${data.apiToken}`,
        },
        body: JSON.stringify({
          command: 'createGroupPad',
          groupID: createGroupJson.response.data.groupID,
          consultationDate: data.roomData.consultationDate,
          text: `Willkommen im ${urlData.urlList.companyname} Connect Editor`,
        }), // body data type must match "Content-Type" header
      });
      const createGroupPadJson = await createGroupPad.json(); // eslint-disable-line
      setgroupPad(`${createGroupJson.response.data.groupID}$${data.roomData.consultationDate}`);
      const createAuthor = await fetch(connectToEtherpadUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors',
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${data.apiToken}`,
        },
        body: JSON.stringify({
          command: 'createAuthorIfNotExistsFor',
          authorId: loginData.agent
            ? data.agentData.id
            : loginData.client
            ? data.clientData.id
            : loginData.user.id,
          authorFirstname: loginData.agent
            ? data.agentData.agentData.firstName
            : loginData.client
            ? data.clientData.clientData.firstName
            : loginData.user.firstName,
          authorLastname: loginData.agent
            ? data.agentData.agentData.lastName
            : loginData.client
            ? data.clientData.clientData.lastName
            : loginData.user.lastName,
        }), // body data type must match "Content-Type" header
      });
      const createAuthorJson = await createAuthor.json();
      const createSession = await fetch(connectToEtherpadUrl, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors',
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${data.apiToken}`,
        },
        body: JSON.stringify({
          command: 'createSession',
          groupID: createGroupJson.response.data.groupID,
          authorId: createAuthorJson.response.data.authorID,
        }), // body data type must match "Content-Type" header
      });
      const createSessionJson = await createSession.json();
      setCookie('sessionID', createSessionJson.response.data.sessionID, {
        path: '/',
        /* sameSite: 'lax', */
        secure: true,
        /*  httpOnly: true, */
        domain: '.beratungsserver.de',
      });
      setcookieSet(true);
    };
    setupEtherpad();
  }, []); // eslint-disable-line

  return (
    <ApellaContext.Consumer>
      {(context) => (
        <Fragment>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            {cookieSet ? (
              <Iframe
                url={`${etherpadUrl}${groupPad}`}
                width={600}
                height={400}
                className={classes.editor}
              ></Iframe>
            ) : null}
          </main>
        </Fragment>
      )}
    </ApellaContext.Consumer>
  );
};

export default Editor;
