import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ListPolls from './preload_components/Poll/ListPolls';
import PollResultList from './preload_components/Poll/PollResultList';
import PollResultListDialog from './dialogues/PollResultListDialog';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

const SelectPolls = ({
  openSelectPollsDialog,
  setopenSelectPollsDialog,
  handleStartPoll,
  handleStopPoll,
  handleShowPollResults,
  stopShowPollResults,
  handleShowComparePollResults,
  handleStopShowComparePollResults,
  origin2,
}) => {
  const classes = useStyles();
  const [showMiniPollresultList, setShowMiniPollresultList] = useState(true);
  const [showPollResultListDialog, setShowPollResultListDialog] = useState(false);

  const handleShowPollResultListDialog = () => {
    setShowPollResultListDialog((prev) => !prev);
    setShowMiniPollresultList((prev) => !prev);
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid container>
        <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
          <ListPolls
            origin="meeting"
            handleStartPoll={handleStartPoll}
            handleStopPoll={handleStopPoll}
            handleShowPollResults={handleShowPollResults}
            stopShowPollResults={stopShowPollResults}
          />
        </Grid>
        {showMiniPollresultList ? (
          <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <PollResultList
              origin="mini"
              showMiniPollresultList={showMiniPollresultList}
              setShowMiniPollresultList={setShowMiniPollresultList}
              handleShowPollResultListDialog={handleShowPollResultListDialog}
            />
          </Grid>
        ) : null}
      </Grid>
      {showPollResultListDialog && (
        <PollResultListDialog
          handleShowPollResultListDialog={handleShowPollResultListDialog}
          showPollResultListDialog={showPollResultListDialog}
          handleShowComparePollResults={handleShowComparePollResults}
          handleStopShowComparePollResults={handleStopShowComparePollResults}
          origin="maxi"
          origin2={origin2}
        />
      )}
    </main>
  );
};
export default SelectPolls;
