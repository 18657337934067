import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import IconButton from '@material-ui/core/IconButton';
import Grow from '@material-ui/core/Grow';
import Fade from '@material-ui/core/Fade';
import Popover from '@material-ui/core/Popover';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  bell: {
    marginRight: 30,
  },
  recordButton: {},
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

const Bell = (props) => {
  const classes = useStyles();
  const [checked, setchecked] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openPopOver = Boolean(anchorEl);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleGrow();
  }, []);

  const handleGrow = () => {
    let blinkIntervall = setInterval(() => {
      setchecked((prev) => !prev);
    }, 1000);

    setTimeout(() => clearInterval(blinkIntervall), 10000);
  };

  return (
    <div>
      <Fade in={checked}>
        <Zoom in={checked}>
          <Grow in={checked}>
            <IconButton
              onClick={props.handleCloseSignButton}
              aria-owns={openPopOver ? 'mouse-over-bell' : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <NotificationsActiveIcon
                color="secondary"
                className={classes.bell}
                fontSize="large"
              />
            </IconButton>
          </Grow>
        </Zoom>
      </Fade>
      <Popover
        id="mouse-over-bell"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={openPopOver}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>Dokumente sind unterschrieben</Typography>
      </Popover>
    </div>
  );
};
export default Bell;
