import React, { Fragment, useState, useContext, useEffect } from 'react';
import { PollContext } from '../../context/PollContext';
import PollResultList from '../preload_components/Poll/PollResultList';
import PollResultCompareDialog from './PollResultCompareDialog';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: 20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const PollResultListDialog = ({
  showPollResultListDialog,
  handleShowPollResultListDialog,
  handleShowComparePollResults,
  handleStopShowComparePollResults,
  origin2,
}) => {
  const classes = useStyles();
  const pollData = useContext(PollContext);
  const [showPollResultCompareDialog, setShowPollResultCompareDialog] = useState(null);
  const [checkboxClicked, setCheckboxClicked] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const handleShowPollResultCompareDialog = () => {
    setShowPollResultCompareDialog((prev) => !prev);
  };

  const decideToShowUmfragevergleichenButton = () => {
    let exists = false;
    for (let i = 0; i < pollData.comparePollResultsIndexArray.length; i++) {
      if (pollData.comparePollResultsIndexArray[i].compare === true) {
        exists = true;
        break;
      }
    }
    setShowButton(exists);
  };

  useEffect(() => {
    decideToShowUmfragevergleichenButton();
  }, [pollData.comparePollResultsIndexArray, checkboxClicked]); // eslint-disable-line

  return (
    <Fragment>
      <Dialog
        open={showPollResultListDialog}
        onClose={() => handleShowPollResultListDialog()}
        fullWidth="true"
        maxWidth="lg"
      >
        <DialogTitle className={classes.root}>
          <Tooltip title="schließen" aria-label="schließen">
            <IconButton
              className={classes.closeButton}
              onClick={() => handleShowPollResultListDialog()}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <PollResultList setCheckboxClicked={setCheckboxClicked} />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            disableElevation
            variant="outlined"
            onClick={() => handleShowPollResultListDialog()}
          >
            Abbrechen
          </Button>
          <Button
            color="primary"
            disableElevation
            variant="contained"
            onClick={() => handleShowPollResultCompareDialog()}
            disabled={!showButton}
          >
            Umfragen vergleichen
          </Button>
        </DialogActions>
      </Dialog>
      {showPollResultCompareDialog ? (
        <PollResultCompareDialog
          showPollResultCompareDialog={showPollResultCompareDialog}
          handleShowPollResultCompareDialog={handleShowPollResultCompareDialog}
          handleShowComparePollResults={handleShowComparePollResults}
          handleStopShowComparePollResults={handleStopShowComparePollResults}
          origin2={origin2}
        />
      ) : null}
    </Fragment>
  );
};

export default PollResultListDialog;
