import React, { Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import screenshare from '../../assets/images/screenshare.png';
import { DialogActions, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialogText: {
    paddingLeft: 10,
  },
}));

const Screenshare = (props) => {
  const classes = useStyles();
  return (
    <Fragment>
      <Dialog open={props.openScreenshareDialogue} onClose={props.handleCloseScreenshareDialogue}>
        <DialogTitle>Screensharing</DialogTitle>
        <DialogContentText className={classes.dialogText}>
          Bitte benutzen Sie folgenden Button um Screensharing zu aktivieren.
        </DialogContentText>
        <img src={screenshare} alt="Screensharing" />
        <DialogActions>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={props.handleCloseScreenshareDialogue}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default Screenshare;
