import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import { ApolloLink } from '@apollo/client';
import { createHttpLink } from '@apollo/client';
import { ApolloClient } from '@apollo/client';
import { InMemoryCache } from '@apollo/client';

import awsconfiguration from './aws-config/awsconfiguration.json';

const url = awsconfiguration.AppSync.Default.ApiUrl;
const region = awsconfiguration.AppSync.Default.Region;
const auth = {
  type: awsconfiguration.AppSync.Default.AuthMode,
  apiKey: awsconfiguration.AppSync.Default.ApiKey,
  // jwtToken: async () => token, // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
  // credentials: async () => credentials, // Required when you use IAM-based auth.
};

/* const url = process.env.REACT_APP_APPSYNC_UR;
const region = process.env.REACT_APP_APPSYNC_REGION;
const auth = {
  type: process.env.REACT_APP_APPSYNC_AUTHTYPE,
  apiKey: process.env.REACT_APP_APPSYNC_APIKEY,
  // jwtToken: async () => token, // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
  // credentials: async () => credentials, // Required when you use IAM-based auth.
}; */

const httpLink = createHttpLink({ uri: url });

const link = ApolloLink.from([
  createAuthLink({ url, region, auth }),
  createSubscriptionHandshakeLink({ url, region, auth }, httpLink),
]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
