import React, { useState, createContext } from 'react';
export const PollContext = createContext();

const PollContextProvider = (props) => {
  const [reloadPolls, setReloadPolls] = useState(false);
  const [pollResults, setPollResults] = useState(null);
  const [comparePollResultsIndexArray, setComparePollResultsIndexArray] = useState([]);
  const [reloadPollResultsList, setReloadPollResultsList] = useState(false);
  const [resetResultListCheckboxes, setResetResultListCheckboxes] = useState(false);
  return (
    <PollContext.Provider
      value={{
        reloadPolls: reloadPolls,
        setReloadPolls: setReloadPolls,
        pollResults: pollResults,
        setPollResults: setPollResults,
        comparePollResultsIndexArray: comparePollResultsIndexArray,
        setComparePollResultsIndexArray: setComparePollResultsIndexArray,
        reloadPollResultsList: reloadPollResultsList,
        setReloadPollResultsList: setReloadPollResultsList,
        resetResultListCheckboxes: resetResultListCheckboxes,
        setResetResultListCheckboxes: setResetResultListCheckboxes,
      }}
    >
      {props.children}
    </PollContext.Provider>
  );
};
export default PollContextProvider;
