import React, { Fragment, useContext, useState, useEffect } from 'react';
import { LoginContext } from '../../context/LoginContext';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const Settings = (props) => {
  const loginData = useContext(LoginContext);
  const [showClientMenu, setshowClientMenu] = useState(props.showclientMenu);
  useEffect(() => {
    setshowClientMenu(props.showClientMenu);
  }, []); // eslint-disable-line

  return (
    <Fragment>
      <Dialog
        open={props.openSettings}
        aria-labelledby="settings-dialog"
        aria-describedby="settings-dialog"
      >
        <DialogTitle id="alert-dialog-whiteboardTitle">Einstellungen</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.showWhiteboardDialog}
                onChange={props.handleWhiteboardDialog}
                name="showDialogAgain"
                color="secondary"
              />
            }
            label="Dialog anzeigen: Whiteboardlink per Email verschicken"
            labelPlacement="end"
          ></FormControlLabel>
        </DialogContent>
        {loginData.agent ? (
          <DialogContent>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props.locked}
                    onChange={() => {
                      props.setlocked((prev) => !prev);
                    }}
                    name="lockRoom"
                    color="secondary"
                  />
                }
                label="Raum sperren"
                labelPlacement="end"
              ></FormControlLabel>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={showClientMenu}
                    onChange={() => {
                      setshowClientMenu((prev) => !prev);
                    }}
                    name="showClientMenu"
                    color="secondary"
                  />
                }
                label="Menü beim Kunden anzeigen"
                labelPlacement="end"
              ></FormControlLabel>
            </FormGroup>
          </DialogContent>
        ) : null}{' '}
        <DialogActions>
          <Button
            disableElevation
            onClick={() => {
              props.setopenSettings(false);
            }}
            variant="outlined"
            color="secondary"
            autoFocus
          >
            Abbrechen
          </Button>
          {loginData.agent ? (
            <Button
              disableElevation
              onClick={() => {
                props.lockRoom();
                props.setshowClientMenu(showClientMenu);
                props.setopenSettings(false);
              }}
              variant="contained"
              color="primary"
              autoFocus
            >
              Speichern
            </Button>
          ) : (
            <Button
              disableElevation
              onClick={() => {
                props.setopenSettings(false);
              }}
              variant="contained"
              color="primary"
              autoFocus
            >
              Speichern
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default Settings;
