import React, { useState, useEffect, useContext, useReducer } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { ApellaContext } from '../../context/ApellaContext';
import { getUserCId, getClientApiToken, getUserRoom } from '../../helper/getData';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import ApellaDrawer from '../../components/ApellaDrawer';
import { LoginContext } from '../../context/LoginContext';
import { StylingContext } from '../../context/StylingContext';
import { UrlContext } from '../../context/UrlContext';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Backdrop from '@material-ui/core/Backdrop';
import Spinner from 'react-spinkit';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const initialState = false;
const reducer = (audioOnly, action) => {
  switch (action) {
    case 'iphone': {
      return true;
    }
    case 'toggle': {
      return !audioOnly;
    }
    default: {
      return initialState;
    }
  }
};
const Landingpage = (props) => {
  const urlData = useContext(UrlContext);
  const urlParam = new URLSearchParams(window.location.search);
  const [roomPassword, setroomPassword] = useState(urlParam.get('roomPassword'));
  const [loggedin, setloggedin] = useState(false);
  const [passwordError, setpasswordError] = useState(false);
  const [backdropOpen, setbackdropOpen] = useState(false);

  const data = useContext(ApellaContext);
  const loginData = useContext(LoginContext);
  const stylingData = useContext(StylingContext);
  const classes = useStyles();
  const [audioOnly, setaudioOnly] = useReducer(reducer, initialState);

  const handleChange = (event) => {
    setroomPassword(event.target.value);
  };

  useEffect(() => {
    if (navigator.userAgent.includes('iPhone')) {
      setaudioOnly('iphone');
    }
  }, []);

  const handleAudioOnly = () => {
    setaudioOnly('toggle');
  };

  const handleSubmit = async () => {
    let cistage = '';
    try {
      cistage = await getUserCId(roomPassword);
    } catch (error) {
      setbackdropOpen(false);
      setpasswordError(true);
      console.log(error);
      cistage = '';
      return;
    }

    let urls = await props.loadUrlList(cistage.stage, cistage.companyIdentification);
    if (urls === undefined) {
      setbackdropOpen(false);
      setpasswordError(true);
      return;
    }
    await urlData.seturlList(urls);
    getInformation(urls);
  };

  const getInformation = async (urls) => {
    setbackdropOpen(true);
    if (
      roomPassword !== null &&
      roomPassword !== '' &&
      roomPassword !== undefined &&
      urls !== undefined
    ) {
      let apiToken = await getClientApiToken({
        roomPassword: roomPassword,
        urlParam:
          urls.connectToApellaBaseurl + urls.connectToApellaApiEndpoints.getClientApiToken.endpoint,
      });
      if (apiToken.error) {
        setbackdropOpen(false);
        setpasswordError(true);
        return;
      }
      if (apiToken.token === 0) {
        setbackdropOpen(false);
        setpasswordError(true);
        return;
      } else {
        data.setapiToken(apiToken);
        let result = await getUserRoom({
          roomPassword: roomPassword,
          apiToken: apiToken,
          urlParam: urls.apiEndpointBaseurl + urls.endpoints.getUserRoom.endpoint,
        });
        if (result === undefined || result.roomData === undefined) {
          setpasswordError(true);
          setbackdropOpen(false);
          return;
        } else if (result.roomData.Item.length !== 0) {
          setpasswordError(false);
          loginData.setUser(result.userData);
          data.setagentData(result.roomData.Item.agentData);
          data.setclientData(result.roomData.Item.clientData);
          data.setroomData(result.roomData.Item.roomData);
          data.setcompanyIdentification(result.roomData.Item.companyIdentification);
          data.setcontextID(result.roomData.Item.contextID);
          data.setDocuments(result.roomData.Item.documents);
          data.setexperts(result.roomData.Item.experts);
          data.setAgendaText(result.roomData.Item.agendaText);
          stylingData.setcolors(result.roomData.Item.colors);
          if (result.userData.client === true) loginData.setClient(true);

          setpasswordError(false);
          setloggedin(true);
          setroomPassword('');
        } else {
          setpasswordError(true);
          setbackdropOpen(false);
        }
      }
    } else {
      setpasswordError(true);
      setbackdropOpen(false);
    }
    setbackdropOpen(false);
  };

  return (
    <div>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <Spinner name="line-scale" color={stylingData.colors.color3} />
      </Backdrop>
      {!loggedin ? (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Anmeldung
            </Typography>
            <form className={classes.form} noValidate>
              <Typography>
                <TextField
                  variant="outlined"
                  autoFocus="true"
                  margin="normal"
                  required
                  fullWidth
                  name="roomPassword"
                  label="Passwort"
                  type="password"
                  value={roomPassword}
                  autoComplete={roomPassword}
                  onChange={handleChange}
                />
              </Typography>
              <Button
                fullWidth
                //type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => handleSubmit()}
              >
                <Typography>Anmelden</Typography>
              </Button>
              <FormControlLabel
                control={
                  <Switch color="primary" onChange={() => handleAudioOnly()} checked={audioOnly} />
                }
                label="Sitzung Audio-only betreten"
              />
            </form>
            <Collapse in={passwordError}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setpasswordError(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <Typography>
                  Das eingegebene Passwort ist falsch!. Bitte korrigieren Sie Ihre Eingabe.
                </Typography>
              </Alert>
            </Collapse>
          </div>
        </Container>
      ) : (
        <ApellaDrawer audioOnly={audioOnly} />
      )}
      {console.log('audioOnly: ' + audioOnly)}
    </div>
  );
};

export default Landingpage;
