import React, { useState, createContext } from 'react';
export const StylingContext = createContext();

export const StylingContextProvider = (props) => {
  const [colors, setcolors] = useState({ color1: '#373b3e', color2: '#006E55', color3: '#EA7C15' });

  return (
    <StylingContext.Provider
      value={{
        colors: colors,
        setcolors: setcolors,
      }}
    >
      {props.children}
    </StylingContext.Provider>
  );
};

export default StylingContextProvider;
