import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import { PollContext } from '../../context/PollContext';
import { ApellaContext } from '../../context/ApellaContext';

import { useQuery } from '@apollo/client';
import { listPollResults } from '../../graphql/queries';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: 20,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const ShowClientPollResultCompareDialog = ({
  showPollResultCompareDialog,
  setShowPollResultCompareDialog,
}) => {
  const classes = useStyles();
  const [displayData, setDisplayData] = useState(null);
  const [question, setQuestion] = useState('');
  const [multiAnswer, setMultiAnswer] = useState('');
  const colors = scaleOrdinal(schemeCategory10).range();
  const apellaContextData = useContext(ApellaContext);
  const pollContextData = useContext(PollContext);

  useQuery(listPollResults, {
    fetchPolicy: 'no-cache',
    variables: {
      filter: {
        ownerPollResultsId: { attributeExists: true, contains: apellaContextData.agentData.id },
      },
    },
    onCompleted: (data) => {
      pollContextData.setPollResults(data.listPollResults.items);
    },
  });

  const closePollResultCompareDialog = () => {
    setDisplayData(null);
    pollContextData.setComparePollResultsIndexArray([]);
    pollContextData.setPollResults(null);
    setDisplayData(null);
    setShowPollResultCompareDialog(false);
  };

  const convertTime = (timeString) => {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      timeZone: 'Europe/Berlin',
    };
    let time = new Date(timeString).toLocaleTimeString('de', options);
    return time;
  };

  const buildDisplayDataforOneResult = (pollResult) => {
    //Ids der Antworten finden
    let tempAnswerIdArray = [];
    let sortedArrays = [];
    let sumOfVotes = [];
    let tempDisplayDataArray = [];
    setQuestion(pollResult.question);
    setMultiAnswer(pollResult.multipleAnswer);
    pollResult.answers.map((answer) => {
      return tempAnswerIdArray.push(answer.id);
    });
    //Votes nach AntwortIds sortieren
    tempAnswerIdArray.map((answerId) => {
      return sortedArrays.push(
        pollResult.votes.items.filter((vote) => vote.answer.id === answerId)
      );
    });
    //votes sortiert addieren
    sortedArrays.map((sortedArray) => {
      let vote = 0;
      // eslint-disable-next-line array-callback-return
      sortedArray.map((entry) => {
        /**
         * nach user sortieren, dann addieren und dann komplett addieren
         */
        if (entry.answer.vote === 1) {
          vote = vote + entry.answer.vote;
        }
      });
      return sumOfVotes.push(vote);
    });
    //Antworten mit Votes in displayData schreiben
    sumOfVotes.map((sum, index) => {
      return tempDisplayDataArray.push({
        name: pollResult.answers[index].answerText,
        Stimmen: sum,
      });
    });
    return { date: [pollResult.createdAt], voteData: tempDisplayDataArray };
  };

  const buildDisplayData = () => {
    let filteredPollResultArray = [];

    let filteredComparePollResultsIndexArray = pollContextData.comparePollResultsIndexArray.filter(
      (pollResults) => pollResults.compare
    );
    filteredComparePollResultsIndexArray.map((result) => {
      return (filteredPollResultArray = filteredPollResultArray.concat(
        pollContextData.pollResults.filter((element) => element.id === result.id)
      ));
    });
    let datesAndVotesArray = [];
    filteredPollResultArray.forEach((res) => {
      datesAndVotesArray.push(buildDisplayDataforOneResult(res));
    });
    // Displaydata füllen
    let tempDisplayData = [];
    datesAndVotesArray.forEach((element) => {
      if (tempDisplayData.length === 0) {
        element.voteData.forEach((voteD, index) => {
          let readableTime = convertTime(element.date);
          let obj = {
            name: voteD.name,
            [readableTime]: voteD.Stimmen,
          };
          tempDisplayData.push(obj);
        });
      } else {
        let readableTime = convertTime(element.date);
        element.voteData.forEach((voteD, index) => {
          Object.assign(tempDisplayData[index], { [readableTime]: voteD.Stimmen });
        });
      }
    });
    setDisplayData(tempDisplayData);
  };

  useEffect(() => {
    if (pollContextData.pollResults !== undefined && pollContextData.pollResults !== null) {
      buildDisplayData();
    }
  }, [pollContextData.pollResults]); // eslint-disable-line

  const buildBars = () => {
    if (displayData && typeof displayData !== undefined && displayData.length !== 0) {
      let dataKeys = Object.keys(displayData[0]);
      dataKeys.shift();
      return dataKeys.map((dataKey, index) => {
        return <Bar dataKey={dataKey} fill={colors[index % 20]}></Bar>;
      });
    }
  };

  return (
    <Fragment>
      <Dialog
        open={showPollResultCompareDialog}
        onClose={() => closePollResultCompareDialog()}
        fullWidth="true"
        maxWidth="lg"
      >
        <DialogTitle className={classes.root}>
          {displayData ? question : null}
          <IconButton
            className={classes.closeButton}
            onClick={() => closePollResultCompareDialog()}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="subtitle1">
            {multiAnswer ? 'Mehrfachantwort möglich' : null}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <BarChart
            width={1000}
            height={600}
            data={displayData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {displayData ? buildBars() : null}
          </BarChart>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default ShowClientPollResultCompareDialog;
