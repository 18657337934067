export const getData = async ({ companyIdentification, contextID, otpKey, stage, urlParam }) => {
  const url = urlParam;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      contextID: contextID,
      companyIdentification: companyIdentification,
      otpKey: otpKey,
      stage: stage,
    }),
  };

  async function fetchData() {
    const response = await fetch(url, options);
    if (!response.ok) {
      console.log('Übertragungsfehler');
    } else {
      const json = await response.json();
      return json;
    }
  }
  const response = await fetchData();
  if (response.response1.AppLogin === undefined || response.response1.AppLogin.FAULT) {
    return -1;
  } else return response;
};

export const getContracts = async ({
  companyIdentification,
  contextID,
  otpKey,
  stage,
  agentId,
  clientId,
  urlParam,
}) => {
  const url = urlParam;

  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      contextID: contextID,
      companyIdentification: companyIdentification,
      otpKey: otpKey,
      stage: stage,
      agentId: agentId,
      clientId: clientId,
    }),
  };

  async function fetchData() {
    const response = await fetch(url, options);
    return await response.json();
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};

export const getClientContracts = async (
  companyIdentification,
  contextID,
  otpKey,
  stage,
  agentId,
  clientId,
  urlParam
) => {
  const url = urlParam;

  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      contextID: contextID,
      companyIdentification: companyIdentification,
      otpKey: otpKey,
      stage: stage,
      agentId: agentId,
      clientId: clientId,
    }),
  };

  async function fetchData() {
    const response = await fetch(url, options);
    return await response.json();
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};

export const getContract = async ({
  companyIdentification,
  contextID,
  otpKey,
  stage,
  agentId,
  clientId,
  contractId,
  urlParam,
}) => {
  const url = urlParam;

  const options = {
    method: 'POST',
    credentials: 'omit',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      contextID: contextID,
      companyIdentification: companyIdentification,
      otpKey: otpKey,
      stage: stage,
      agentId: agentId,
      clientId: clientId,
      contractId: contractId,
    }),
  };

  async function fetchData() {
    const response = await fetch(url, options);
    return await response.json();
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};

export const getUserRoom = async ({ roomPassword, apiToken, urlParam }) => {
  const url = `${urlParam}/${roomPassword}`;

  const options = {
    method: 'GET',
    credentials: 'omit',
    headers: { Authorization: `Bearer ${apiToken}` },
  };

  async function fetchData() {
    const response = await fetch(url, options);
    return response.json();
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};

export const getApiToken = async ({
  companyIdentification,
  contextID,
  otpKey,
  stage,
  urlParam,
}) => {
  const url = urlParam;

  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      contextID: contextID,
      companyIdentification: companyIdentification,
      otpKey: otpKey,
      stage: stage,
    }),
  };

  async function fetchData() {
    const response = await fetch(url, options);
    if (!response.ok) {
      console.log('Übertragungsfehler');
    } else {
      const json = await response.json();
      return json.token;
    }
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};

export const getClientApiToken = async ({ roomPassword, urlParam }) => {
  const url = `${urlParam}/${roomPassword}`;

  async function fetchData() {
    const response = await fetch(url);
    if (!response.ok) {
      console.log('Übertragungsfehler');
      let json = await response.json();
      json.token = 0;
      return json;
    } else {
      let json = await response.json();
      return json.token;
    }
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};

export const sendDocumentsInformation = async ({
  cmd,
  agentId,
  clientId,
  consultationDate,
  documentIds,
  agentConnectionId,
  companyIdentification,
  stage,
  apiToken,
  urlParam,
}) => {
  const url = urlParam;
  const options = {
    method: 'POST',

    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}`,
    },
    body: JSON.stringify({
      cmd: cmd,
      agentId: agentId,
      clientId: clientId,
      consultationDate: consultationDate,
      documentIds: documentIds,
      agentConnectionId: agentConnectionId,
      companyIdentification: companyIdentification,
      stage: stage,
    }),
  };

  async function fetchData() {
    const response = await fetch(url, options);
    if (!response.ok) {
      console.log('Übertragungsfehler');
    } else {
      const json = await response.json();
      return json;
    }
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};

export const sendEmail = async ({
  receiver,
  agentId,
  clientId,
  consultationDate,
  consultationTitle,
  urlParam,
  apiToken,
  repeating,
  kindOfRepeating,
  fullDate,
  selectedDays,
  companyIdentification,
  stage,
}) => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${apiToken}` },
    body: JSON.stringify({
      receiver: receiver,
      agentId: agentId,
      clientId: clientId,
      consultationDate: consultationDate,
      consultationTitle: consultationTitle,
      repeating: repeating,
      kindOfRepeating: kindOfRepeating,
      fullDate: fullDate,
      selectedDays: selectedDays,
      companyIdentification: companyIdentification,
      stage: stage,
    }),
  };
  const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, 'gm');
  async function fetchData() {
    const response = await fetch(urlParam, options);
    if (!response.ok) {
      console.log('Übertragungsfehler');
      const json = await response.json();
      return json;
    } else {
      const json = await response.json();
      return json;
    }
  }
  try {
    if (receiver !== 'client') {
      const isValidEmail = emailRegex.test(receiver.userValues.email);
      if (!isValidEmail) {
        throw new TypeError('Die Emailadresse ist leider nicht gültig!');
      }
    }
    const response = await fetchData();
    return response;
  } catch (error) {
    return error;
  }
};

export const saveAgendaText = async ({
  agentId,
  clientId,
  consultationDate,
  agendaText,
  apiToken,
  urlParam,
}) => {
  const url = urlParam;
  const options = {
    method: 'POST',

    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${apiToken}` },
    body: JSON.stringify({
      agentId: agentId,
      clientId: clientId,
      consultationDate: consultationDate,
      agendaText: agendaText,
    }),
  };

  async function fetchData() {
    const response = await fetch(url, options);
    if (!response.ok) {
      console.log('Übertragungsfehler');
    } else {
      const json = await response.json();
      return json;
    }
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};

export const submitFeedback = async ({
  agentId,
  clientId,
  consultationDate,
  roomHash,
  overallqualityRating,
  videoqualityRating,
  audioqualityRating,
  qualityAnnotationText,
  authorFirstName,
  authorLastname,
  companyIdentification,
  stage,
  navigator,
  apiToken,
  urlParam,
}) => {
  const url = urlParam;
  const options = {
    method: 'POST',

    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${apiToken}` },
    body: JSON.stringify({
      agentId: agentId,
      clientId: clientId,
      consultationDate: consultationDate,
      roomHash,
      overallqualityRating: overallqualityRating,
      videoqualityRating: videoqualityRating,
      audioqualityRating: audioqualityRating,
      qualityAnnotationText: qualityAnnotationText,
      authorFirstName,
      authorLastname,
      companyIdentification,
      stage,
      navigator,
    }),
  };

  async function fetchData() {
    const response = await fetch(url, options);
    if (!response.ok) {
      console.log('Übertragungsfehler');
    } else {
      const json = await response.json();
      return json;
    }
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};

export const sendWhiteboardUrl = async ({
  email,
  whiteboardUrl,
  companyIdentification,
  stage,
  agentId,
  clientId,
  consultationDate,
  apiToken,
  urlParam,
}) => {
  const url = urlParam;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${apiToken}` },
    body: JSON.stringify({
      email: email,
      whiteboardUrl: whiteboardUrl,
      companyIdentification: companyIdentification,
      stage: stage,
      agentId: agentId,
      clientId: clientId,
      consultationDate: consultationDate,
    }),
  };

  async function fetchData() {
    const response = await fetch(url, options);
    if (!response.ok) {
      console.log('Übertragungsfehler');
    } else {
      const json = await response.json();
      return json;
    }
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};

export const listRecordings = async ({ agentId, clientId, apiToken, urlParam }) => {
  const url = urlParam;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${apiToken}` },
    body: JSON.stringify({
      agentId: agentId,
      clientId: clientId,
    }),
  };

  async function fetchData() {
    const response = await fetch(url, options);
    if (!response.ok) {
      console.log('Übertragungsfehler');
    } else {
      const json = await response.json();
      return json;
    }
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};

export const getDownload = async ({ fileObjName, apiToken, urlParam, documentName }) => {
  const url = urlParam;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${apiToken}` },
    body: JSON.stringify({
      document: fileObjName,
      documentName: documentName,
    }),
  };

  async function fetchData() {
    const response = await fetch(url, options);
    if (!response.ok) {
      console.log('Übertragungsfehler');
    } else {
      const json = await response.json();
      return json;
    }
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};

export const getIdsToDelete = async ({
  agentId,
  clientId,
  consultationDate,
  apiToken,
  urlParam,
}) => {
  const url = urlParam;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${apiToken}` },
    body: JSON.stringify({
      agentId: agentId,
      clientId: clientId,
      consultationDate: consultationDate,
    }),
  };

  async function fetchData() {
    const response = await fetch(url, options);
    if (!response.ok) {
      console.log('Übertragungsfehler');
    } else {
      const json = await response.json();
      return json;
    }
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};

export const getIdsOfUserToDelete = async ({
  agentId,
  clientId,
  consultationDate,
  firstName,
  lastName,
  email,
  apiToken,
  urlParam,
}) => {
  const url = urlParam;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${apiToken}` },
    body: JSON.stringify({
      agentId: agentId,
      clientId: clientId,
      consultationDate: consultationDate,
      firstName: firstName,
      lastName: lastName,
      email: email,
    }),
  };

  async function fetchData() {
    const response = await fetch(url, options);
    if (!response.ok) {
      console.log('Übertragungsfehler');
    } else {
      const json = await response.json();
      return json;
    }
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};

export const deleteUsers = async ({ password, apiToken, urlParam }) => {
  const url = urlParam;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${apiToken}` },
    body: JSON.stringify({
      password: password,
    }),
  };

  async function fetchData() {
    const response = await fetch(url, options);
    if (!response.ok) {
      console.log('Übertragungsfehler');
    } else {
      const json = await response.json();
      return json;
    }
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};
export const uploadToApella = async ({
  agentId,
  clientId,
  consultationDate,
  companyIdentification,
  stage,
  apiToken,
  urlParam,
}) => {
  const url = urlParam;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${apiToken}` },
    body: JSON.stringify({
      agentId: agentId,
      clientId: clientId,
      consultationDate: consultationDate,
      companyIdentification: companyIdentification,
      stage: stage,
    }),
  };

  async function fetchData() {
    const response = await fetch(url, options);
    if (!response.ok) {
      console.log('Übertragungsfehler');
    } else {
      const json = await response.json();
      return json;
    }
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};
export const createPoll = async (
  agentId,
  chartIndex,
  question,
  answers,
  multiAnswer,
  kindOfChart,
  urlParam,
  apiToken
) => {
  const url = urlParam;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${apiToken}` },
    body: JSON.stringify({
      agentId: agentId,
      chartIndex: chartIndex,
      question: question,
      answers: answers,
      multiAnswer: multiAnswer,
      kindOfChart: kindOfChart,
    }),
  };

  async function fetchData() {
    const response = await fetch(url, options);
    if (!response.ok) {
      console.log('Übertragungsfehler');
    } else {
      const json = await response.json();
      return json;
    }
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};
export const getUserCId = async (password) => {
  const url = `${process.env.REACT_APP_GETUSERCID}/${password}`;
  const options = {
    method: 'GET',
    credentials: 'omit',
  };
  async function fetchData() {
    const response = await fetch(url, options);
    let result = await response.json();
    return result;
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};

export const updateLog = async ({
  agentId,
  date,
  sequence,
  companyIdentification,
  contextID,
  stage,
  otpKey,
  urlParam,
  apiToken,
}) => {
  const dateOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  const timestamp = new Date().toLocaleDateString('de-DE', dateOptions);
  const url = urlParam;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${apiToken}` },
    body: JSON.stringify({
      agentId: agentId,
      date: date,
      sequence: sequence,
      companyIdentification: companyIdentification,
      contextID: contextID,
      stage: stage,
      otpKey: otpKey,
      lastUpdate: timestamp,
    }),
  };

  async function fetchData() {
    const response = await fetch(url, options);
    if (!response.ok) {
      console.log('Übertragungsfehler');
    } else {
      const json = await response.json();
      return json;
    }
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};
export const sendAgentEmail = async ({
  agentId,
  clientId,
  consultationDate,
  consultationTitle,
  urlParam,
  apiToken,
  repeating,
  kindOfRepeating,
  fullDate,
  selectedDays,
  companyIdentification,
  stage,
}) => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${apiToken}` },
    body: JSON.stringify({
      agentId: agentId,
      clientId: clientId,
      consultationDate: consultationDate,
      consultationTitle: consultationTitle,
      repeating: repeating,
      kindOfRepeating: kindOfRepeating,
      fullDate: fullDate,
      selectedDays: selectedDays,
      companyIdentification: companyIdentification,
      stage: stage,
    }),
  };
  async function fetchData() {
    const response = await fetch(urlParam, options);
    if (!response.ok) {
      console.log('Übertragungsfehler');
    } else {
      const json = await response.json();
      return json;
    }
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};
export const getDocuments = async ({
  companyIdentification,
  contextID,
  otpKey,
  stage,
  urlParam,
  clientId,
  contracts,
  family,
}) => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      contextID: contextID,
      companyIdentification: companyIdentification,
      otpKey: otpKey,
      stage: stage,
      clientId: clientId,
      contracts: 0,
      family: 1,
    }),
  };

  async function fetchData() {
    const response = await fetch(urlParam, options);
    if (!response.ok) {
      console.log('Übertragungsfehler');
    } else {
      const json = await response.json();
      return json;
    }
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};
export const uploadPolicysystemDocumentsToS3 = async ({
  otp,
  agentId,
  clientId,
  consultationDate,
  companyIdentification,
  stage,
  documentsToImport,
  apiToken,
  contextId,
  urlParam,
}) => {
  const url = urlParam;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${apiToken}` },
    body: JSON.stringify({
      otp: otp,
      contextId: contextId,
      agentId: agentId,
      clientId: clientId,
      consultationDate: consultationDate,
      companyIdentification: companyIdentification,
      stage: stage,
      documentsToImport: documentsToImport,
    }),
  };

  async function fetchData() {
    const response = await fetch(url, options);
    if (!response.ok) {
      console.log('Übertragungsfehler');
    } else {
      const json = await response.json();
      return json;
    }
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};

export const getUpdates = async ({ urlParam, companyIdentification, apiToken }) => {
  const url = urlParam;
  const options = {
    method: 'GET',
    credentials: 'omit',
    headers: { Authorization: `Bearer ${apiToken}` },
  };
  async function fetchData() {
    const response = await fetch(url, options);
    if (!response.ok) {
      console.log('Übertragungsfehler');
    } else {
      const json = await response.json();
      return json;
    }
  }
  try {
    const response = await fetchData();
    return response;
  } catch (error) {
    console.log('error', error);
    return;
  }
};
