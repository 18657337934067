import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  DialogContent,
  Button,
  Typography,
} from '@material-ui/core';

const DeleteRoom = (props) => {
  const { deleteRoomDialog, setdeleteRoomDialog, handleDelete, roomToDelete } = props;

  return (
    <div>
      <Dialog open={deleteRoomDialog} onClose={() => setdeleteRoomDialog(false)}>
        <DialogTitle>Löschen des Raums {roomToDelete.roomName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Wollen Sie den Raum <Typography color="secondary">{roomToDelete.roomName}</Typography>{' '}
            wirklich löschen?
          </DialogContentText>
          <DialogActions>
            <Button
              color="secondary"
              autoFocus
              onClick={() => setdeleteRoomDialog(false)}
              variant="outlined"
            >
              Abbrechen
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleDelete(roomToDelete.roomIndex);
                setdeleteRoomDialog(false);
              }}
            >
              OK
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DeleteRoom;
