import React, { Fragment, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  backdrop: {
    zIndex: 11000,
    color: '#fff',
  },
  dialog: {
    zIndex: 11001,
  },
}));

const Lobby = (props) => {
  const [lobbyDialog, setlobbyDialog] = useState(true); // eslint-disable-line
  const classes = useStyles();
  // eslint-disable-next-line
  const handleCloseLobbyDialog = () => {
    setlobbyDialog(false);
  };
  return (
    <Fragment>
      <Dialog
        open={props.locked}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton color="secondary">
            <FontAwesomeIcon icon={faExclamationTriangle} size="large" />
          </IconButton>
          Beratungsraum gesperrt
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sobald der Moderator den Beratungsraum betritt, wird dieser entsperrt.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default Lobby;
