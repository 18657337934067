import React, { Fragment, useState, useEffect, useContext, useReducer } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { ApellaContext } from '../../context/ApellaContext';
import { StylingContext } from '../../context/StylingContext';
import { UrlContext } from '../../context/UrlContext';
import { LoggingContext } from '../../context/LoggingContext';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Badge from '@material-ui/core/Badge';

import { sendEmail } from '../../helper/getData';
import { useSnackbar } from 'notistack';
import { saveRoom } from '../../helper/saveRoom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  userdisplay: {
    display: 'flex',
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  expertInputfiled: {
    margin: 5,
  },
  chipContainer: {
    margin: theme.spacing(0.5),
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    /* boxShadow: `0 0 0 2px ${theme.palette.background.paper}`, */
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

const AddUser = (props) => {
  const data = useContext(ApellaContext);
  const loggingData = useContext(LoggingContext);
  const stylingData = useContext(StylingContext);
  const urlData = useContext(UrlContext);
  const classes = useStyles();
  const [expertFirstname, setexpertFirstname] = useState('');
  const [expertLastname, setexpertLastname] = useState('');
  const [expertEmailValue, setexpertEmailValue] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [offlineUsers, setofflineUsers] = useState(null);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0); // eslint-disable-line

  const akronym = (user) => {
    console.log('user', user);
    if (user.firstName == null) {
      if (user.lastName != null) {
        return user.lastName.substr(0, 1);
      }
      return 'A';
    }
    if (user.firstName != null) {
      if (user.lastName != null) {
        let firstLetter = user.firstName.substr(0, 1);
        let lastLetter = user.lastName.substr(0, 1);
        return firstLetter + lastLetter;
      }
      return user.firstName.substr(0, 1);
    }

    return 'A';
  };

  const handleExpertInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    switch (name) {
      case 'expertFirstname':
        setexpertFirstname(value);
        break;
      case 'expertLastname':
        setexpertLastname(value);
        break;
      case 'expertEmailValue':
        setexpertEmailValue(value);
        break;
      default:
        break;
    }
  };

  const getParticipansAgain = async () => {
    const participants = await fetch(
      urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.getUsers.endpoint,
      {
        method: 'POST',
        cache: 'no-cache',
        credentials: 'omit',
        headers: {
          Authorization: `Bearer ${data.apiToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          agentId: data.agentData.id,
          clientId: data.clientData.id,
          consultationDate: data.roomData.consultationDate,
        }),
      }
    )
      .then((response) => response.json())
      .then((response) => response.Items)
      .then((participants) =>
        participants.map((participant) => ({
          firstName: '',
          lastName: '',
          email: '',
          client: '',
          ...participant,
        }))
      );
    data.setexperts(participants);
    forceUpdate();
  };
  const handleExpertIconAndClose = async () => {
    props.setopenExpertDialog(false);
    try {
      const response = await sendEmail({
        receiver: {
          userValues: {
            firstName: expertFirstname,
            lastName: expertLastname,
            email: expertEmailValue,
          },
        },
        agentId: data.agentData.id,
        clientId: data.clientData.id,
        consultationDate: data.roomData.consultationDate,
        consultationTitle: data.roomData.consultationTitle,
        urlParam: urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.sendEmail.endpoint,
        apiToken: data.apiToken,
        repeating: data.roomData.repeating,
        kindOfRepeating: data.roomData.kindOfRepeating,
        fullDate: data.roomData.fullDate,
        selectedDays: data.roomData.selectedDays,
        companyIdentification: data.companyIdentification,
        stage: data.stage,
      });
      if (response instanceof TypeError) {
        throw new Error(response.message);
      }
      await saveExperts();
      loggingData.setSequence({
        type: 'addUser',
        userValues: {
          firstName: expertFirstname,
          lastName: expertLastname,
          email: expertEmailValue,
        },
      });

      enqueueSnackbar('Eine E-Mail mit dem Einladungslink wurde gesendet.', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
      console.warn(e);
    }
    getParticipansAgain();
  };

  const saveExperts = () => {
    data.setexperts([
      ...data.experts,
      {
        firstName: expertFirstname,
        lastName: expertLastname,
        email: expertEmailValue,
      },
    ]);
  };

  useEffect(() => {
    handleSave();
  }, [data.experts]); // eslint-disable-line

  const handleSave = () => {
    saveRoom(
      data.apiToken,
      data.agentData,
      data.clientData,
      data.roomData,
      data.documents,
      data.url,
      data.insignData,
      data.activeStep,
      data.otpKey,
      data.companyIdentification,
      data.stage,
      data.contextID,
      data.agentConnectionId,
      data.experts,
      data.agendaText,
      stylingData.colors,
      urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.updateRoom.endpoint
    );
  };

  useEffect(() => {
    let userA = data.experts.map((user) => ({
      name: `${user.firstName} ${user.lastName}`,
      password: user.password,
    }));

    if (props.onlineUsers !== null && userA !== null) {
      for (let i = 0; i < props.onlineUsers.length; i++) {
        for (let j = 0; j < userA.length; j++) {
          if (props.onlineUsers[i].displayName === userA[j].name) {
            userA.splice(j, 1);
          }
        }
      }
    }
    setofflineUsers(userA);
  }, [props.onlineUsers, data.experts]);

  const copyTextToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  };

  const findUserInformation = (index) => {
    if (props?.onlineUsers !== null && props?.onlineUsers !== undefined) {
      let foundInformation = null;
      foundInformation = data.experts.find(
        (element) =>
          `${element.firstName} ${element.lastName}`.valueOf() ===
          props.onlineUsers[index].displayName.valueOf()
      );
      console.log('foundInformation', foundInformation);
      if (foundInformation !== null) {
        handleShareLink(
          `https://${urlData.urlList.domain}?roomPassword=${foundInformation.password}`
        );
      }
    }
  };

  const handleShareLink = (copyText) => {
    copyTextToClipboard(copyText)
      .then(() => {
        enqueueSnackbar('Beratungslink in die Zwischenablage kopiert!', {
          variant: 'success',
        });
      })
      .catch((err) => {
        enqueueSnackbar(
          'Beim Kopieren des Beratungslink in die Zwischenablage ist ein Fehler aufgetreten',
          {
            variant: 'error',
          }
        );
        console.log(err);
      });
  };

  return (
    <Fragment>
      <Dialog
        open={props.openExpertDialog}
        onClose={props.handleCloseExpertDialog}
        aria-labelledby="form-expert-invite"
      >
        <DialogTitle id="form-expert-invite-title">Teilnehmer</DialogTitle>
        <DialogContent dividers className={classes.root}>
          <DialogContentText>
            Mit einem Mausklick auf den Teilnehmer können Sie den Einladungslink in die
            Zwischenablage kopieren!
          </DialogContentText>
          {props.onlineUsers !== null && props.onlineUsers !== undefined
            ? props.onlineUsers.map((user, index) => (
                <div className={classes.chipContainer}>
                  <Chip
                    clickable
                    onClick={() => {
                      findUserInformation(index);
                    }}
                    avatar={
                      <StyledBadge
                        overlap="circle"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        variant="dot"
                      >
                        <Avatar
                          className={classes.small}
                          alt={`${user.displayName.split(' ')[0]}  ${
                            user.displayName.split(' ')[1]
                          }`}
                        >
                          {akronym({
                            firstName: user.displayName.split(' ')[0],
                            lastName: user.displayName.split(' ')[1],
                          })}
                        </Avatar>
                      </StyledBadge>
                    }
                    label={`${user.formattedDisplayName}`}
                  />
                </div>
              ))
            : null}

          <div className={classes.root}>
            {offlineUsers !== null && offlineUsers !== undefined
              ? offlineUsers.map((user, index) => (
                  <div className={classes.chipContainer}>
                    <Chip
                      clickable
                      onClick={() =>
                        handleShareLink(
                          `https://${urlData.urlList.domain}?roomPassword=${user.password}`
                        )
                      }
                      avatar={
                        <Avatar
                          className={classes.small}
                          alt={`${user.name.split(' ')[0]}  ${user.name.split(' ')[1]}`}
                        >
                          {akronym({
                            firstName: user.name.split(' ')[0],
                            lastName: user.name.split(' ')[1],
                          })}
                        </Avatar>
                      }
                      label={`${user.name}`}
                    />
                  </div>
                ))
              : null}
          </div>
        </DialogContent>

        <DialogContent>
          <DialogContentText>
            Wollen Sie weitere Teilnehmer einladen? Dann geben Sie hier bitte ihre/seine Daten ein.
          </DialogContentText>
          <form>
            <TextField
              name="expertFirstname"
              required
              className={classes.expertInputfiled}
              autoFocus
              margin="dense"
              id="expertFirstname"
              label="Vorname"
              type="text"
              variant="outlined"
              onChange={handleExpertInputChange}
            ></TextField>
            <TextField
              name="expertLastname"
              required
              className={classes.expertInputfiled}
              margin="dense"
              id="expertLastname"
              label="Nachname"
              type="text"
              variant="outlined"
              onChange={handleExpertInputChange}
            ></TextField>
            <TextField
              name="expertEmailValue"
              className={classes.expertInputfiled}
              required
              margin="dense"
              id="expertEmailValue"
              label="Email"
              type="email"
              fullWidth
              variant="outlined"
              onChange={handleExpertInputChange}
            />{' '}
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            disableElevation
            variant="outlined"
            color="secondary"
            onClick={props.handleCloseExpertDialog}
          >
            Abbrechen
          </Button>
          <Button
            disableElevation
            disabled={!(expertEmailValue && expertFirstname && expertLastname)}
            onClick={handleExpertIconAndClose}
            variant="contained"
            color="primary"
          >
            Senden
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default AddUser;
