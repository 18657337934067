import React, { useEffect, useState, useContext, Fragment } from 'react';
import { ApellaContext } from '../../context/ApellaContext';
import { UrlContext } from '../../context/UrlContext';
import FileTable from '../FileTable';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { uploadToApella } from '../../helper/getData';
import { useSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';

const AskForUpload = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const contextData = useContext(ApellaContext);
  const urlData = useContext(UrlContext);
  const [responseStatus, setresponseStatus] = useState(false);
  const [responseMessage, setresponseMessage] = useState('');

  const uploadSelectedFilesToApella = async () => {
    setresponseStatus(true);
    let response = await uploadToApella({
      agentId: contextData.agentData.id,
      clientId: contextData.clientData.id,
      consultationDate: contextData.roomData.consultationDate,
      companyIdentification: contextData.companyIdentification,
      stage: contextData.stage,
      apiToken: contextData.apiToken,
      urlParam:
        urlData.urlList.apiEndpointBaseurl + urlData.urlList.endpoints.uploadToApella.endpoint,
    });
    setresponseMessage(response.message);
    await contextData.setDocuments(response.documents);
  };

  useEffect(() => {
    if (responseStatus) {
      if (responseMessage === 'Dokumente erfolgreich ins MSC übertragen') {
        enqueueSnackbar('Die Dokumente wurden erfogreich ins MSC übertragen!', {
          variant: 'success',
        });
      } else if (
        responseMessage === 'Es wurden keine Dokumente zur Übertragung ins MSC ausgewählt'
      ) {
        enqueueSnackbar('Es wurden keine Dokumente zur Übertragung ins MSC ausgewählt!');
      } else {
        enqueueSnackbar('Fehler bei der Übertragung der Dokumente ins MSC!', {
          variant: 'error',
        });
      }
      props.setaskForUploadDialog(false);
      props.closeTheRoomFinally();
      setresponseStatus(false);
    }
  }, [contextData.documents]); // eslint-disable-line

  return (
    <Fragment>
      <Dialog
        open={props.askForUploadDialog}
        aria-labelledby="alert-dialog-askForUploadDialog"
        aria-describedby="alert-dialog-askForUploadDialog"
        maxWidth="xl"
        fullWidth="fullWidth"
      >
        <DialogTitle>
          <Typography>
            Markieren Sie die Dokumente, die Sie zusätzlich ins MSC hochladen wollen. Unterschiebene
            Dokumente sind automatisch ins MSC übertragen worden.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FileTable askforupload={true} />
        </DialogContent>
        <DialogActions>
          <Button
            disableElevation
            onClick={() => {
              setTimeout(uploadSelectedFilesToApella(), 2000);
            }}
            variant="contained"
            color="primary"
            autoFocus
          >
            Beratungsraum schließen
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default AskForUpload;
