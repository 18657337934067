import React, { Fragment, useContext, useState } from 'react';
import { Bar, BarChart } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import ChartDialog from './dialogues/ChartDialog';
import { StylingContext } from '../../../context/StylingContext';

const useStyles = makeStyles({
  barchart: {
    '&:hover': { cursor: 'pointer' },
  },
});

const Barchart = (props) => {
  const classes = useStyles();
  const stylingData = useContext(StylingContext);
  const [color1, setcolor1] = useState(stylingData.colors.color2); // eslint-disable-line
  const [color2, setcolor2] = useState(stylingData.colors.color3); // eslint-disable-line
  const [openDialog, setopenDialog] = useState(false);

  const handleOpenDialog = () => {
    setopenDialog(true);
  };
  const handleCloseDialog = () => {
    setopenDialog(false);
  };

  const dataX = [
    {
      name: 'Page A',
      uv: 4000,
      pv: 2400,
    },
    {
      name: 'Page B',
      uv: 3000,
      pv: 1398,
    },
  ];
  const renderBarChart = (color1, color2) => {
    return (
      <BarChart width={150} height={120} data={dataX} onClick={handleOpenDialog}>
        <Bar dataKey="pv" fill={color1} className={classes.barchart} />
        <Bar dataKey="uv" fill={color2} className={classes.barchart} />
      </BarChart>
    );
  };

  return (
    <Fragment>
      {renderBarChart(color1, color2)}
      {openDialog ? (
        <ChartDialog
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          kindOfChart={props.kindOfChart}
          handleDesignPollDialog={props.handleDesignPollDialog}
        />
      ) : null}
    </Fragment>
  );
};
export default Barchart;
