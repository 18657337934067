import React, { useContext, useState, useEffect } from 'react';
import LaunchIcon from '@material-ui/icons/Launch';
import IconButton from '@material-ui/core/IconButton';
import MUIDataTable from 'mui-datatables';
import { muiTableTextLabels } from '../translation/Translation';
import { getContracts, getContract } from '../helper/getData';
import { ApellaContext } from '../context/ApellaContext';
import { LoginContext } from '../context/LoginContext';
import { UrlContext } from '../context/UrlContext';
import { StylingContext } from '../context/StylingContext';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from 'react-spinkit';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function VertragContainer(props) {
  const classes = useStyles();
  const data = useContext(ApellaContext);
  const loginData = useContext(LoginContext);
  const urlData = useContext(UrlContext);
  const stylingData = useContext(StylingContext);
  const [vertraege, setVertraege] = useState([]);
  const [backdropOpen, setbackdropOpen] = useState(false);

  const handleClose = () => {
    setbackdropOpen(false);
    /* data.settoggleBackdrop(false); */
  };
  const handleToggle = () => {
    setbackdropOpen(true);
  };

  useEffect(() => {
    setbackdropOpen(true);
    let response;

    if (loginData.agent) {
      response = async () => {
        var getDataResult;

        getDataResult = await getContracts({
          companyIdentification: data.companyIdentification,
          contextID: data.contextID,
          otpKey: data.otpKey,
          stage: data.stage,
          agentId: data.agentData.id,
          clientId: data.clientData.id,
          urlParam:
            urlData.urlList.connectToApellaBaseurl +
            urlData.urlList.connectToApellaApiEndpoints.getApellaContracts.endpoint,
        });
        setVertraege(getDataResult.response.CheckKundeEx.CheckKundeExResponse.Kunde.Vertrag);
        setbackdropOpen(false);
      };

      response();
    } else {
      setVertraege(data.clientData.contracts);
      setbackdropOpen(false);
    }
  }, []); // eslint-disable-line

  async function handleMscOpener(vertrag) {
    let newWindow = window.open('about:blank', 'MscSSOLink');
    newWindow.focus();

    let response = await getContract({
      companyIdentification: data.companyIdentification,
      contextID: data.contextID,
      otpKey: data.otpKey,
      stage: data.stage,
      agentId: data.agentData.id,
      clientId: data.clientData.id,
      contractId: vertrag.ApellaID,
      urlParam:
        urlData.urlList.connectToApellaBaseurl +
        urlData.urlList.connectToApellaApiEndpoints.getApellaContract.endpoint,
    });

    newWindow.location.href = response.GetSSOLink.GetSSOLinkResponse.SSOUrl;
  }

  const myDefaultOptions = {
    filter: false,
    sort: true,
  };
  const myDefaultHiddenOptions = {
    display: false,
    filter: false,
    sort: true,
  };

  let columns;
  if (loginData.agent) {
    columns = [
      {
        name: 'ApellaID',
        label: 'ApellaID',
        options: myDefaultHiddenOptions,
      },
      {
        name: 'ApellaVermID',
        label: 'ApellaVermID',
        options: myDefaultHiddenOptions,
      },
      {
        name: 'ApellaAdrID',
        label: 'ApellaAdrID',
        options: myDefaultHiddenOptions,
      },
      {
        name: 'Gesellschaft',
        label: 'Gesellschaft',
        options: myDefaultOptions,
      },
      {
        name: 'Produkt',
        label: 'Produkt',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'Sparte',
        label: 'Sparte',
        options: myDefaultHiddenOptions,
      },
      {
        name: 'GDVHauptsparte',
        label: 'GDVHauptsparte',
        options: myDefaultHiddenOptions,
      },
      {
        name: 'ApellaBEREICH',
        label: 'ApellaBEREICH',
        options: myDefaultHiddenOptions,
      },
      {
        name: 'ApellaSPARTE',
        label: 'ApellaSPARTE',
        options: myDefaultHiddenOptions,
      },
      {
        name: 'ApellaPRODUKT',
        label: 'ApellaPRODUKT',
        options: myDefaultOptions,
      },
      {
        name: 'PoliceNr',
        label: 'PoliceNr',
        options: myDefaultOptions,
      },
      {
        name: 'ApellaProduktID',
        label: 'ApellaProduktID',
        options: myDefaultHiddenOptions,
      },
      {
        name: 'Zahlweise',
        label: 'Zahlweise',
        options: myDefaultOptions,
      },
      {
        name: 'Beitrag',
        label: 'Beitrag',
        options: myDefaultOptions,
      },
      {
        name: 'ApellaGesID',
        label: 'ApellaGesID',
        options: myDefaultHiddenOptions,
      },
      {
        name: 'JahresBeitragNetto',
        label: 'JahresBeitragNetto',
        options: myDefaultHiddenOptions,
      },
      {
        name: 'Antragsdatum',
        label: 'Antragsdatum',
        options: myDefaultHiddenOptions,
      },
      /* {
        name: 'LogoURL',
        label: 'Logo',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <img src={vertraege[dataIndex].LogoURL} style={{ maxWidth: '75px' }} alt="logo" />
            );
          },
        },
      },
      {
        name: 'MP',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return (
              <IconButton
                onClick={(data) => {
                  handleMscOpener(vertraege[dataIndex]);
                }}
              >
                <LaunchIcon />
              </IconButton>
            );
          },
        },
      }, */
    ];
  } else {
    columns = [
      {
        name: 'ApellaID',
        label: 'ApellaID',
        options: myDefaultHiddenOptions,
      },
      {
        name: 'ApellaVermID',
        label: 'ApellaVermID',
        options: myDefaultHiddenOptions,
      },
      {
        name: 'ApellaAdrID',
        label: 'ApellaAdrID',
        options: myDefaultHiddenOptions,
      },
      {
        name: 'Gesellschaft',
        label: 'Gesellschaft',
        options: myDefaultOptions,
      },
      {
        name: 'Produkt',
        label: 'Produkt',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'Sparte',
        label: 'Sparte',
        options: myDefaultHiddenOptions,
      },
      {
        name: 'GDVHauptsparte',
        label: 'GDVHauptsparte',
        options: myDefaultHiddenOptions,
      },
      {
        name: 'ApellaBEREICH',
        label: 'ApellaBEREICH',
        options: myDefaultHiddenOptions,
      },
      {
        name: 'ApellaSPARTE',
        label: 'ApellaSPARTE',
        options: myDefaultHiddenOptions,
      },
      {
        name: 'ApellaPRODUKT',
        label: 'ApellaPRODUKT',
        options: myDefaultOptions,
      },
      {
        name: 'PoliceNr',
        label: 'PoliceNr',
        options: myDefaultOptions,
      },
      {
        name: 'ApellaProduktID',
        label: 'ApellaProduktID',
        options: myDefaultHiddenOptions,
      },
      {
        name: 'Zahlweise',
        label: 'Zahlweise',
        options: myDefaultOptions,
      },
      {
        name: 'Beitrag',
        label: 'Beitrag',
        options: myDefaultOptions,
      },
      {
        name: 'ApellaGesID',
        label: 'ApellaGesID',
        options: myDefaultHiddenOptions,
      },
      {
        name: 'JahresBeitragNetto',
        label: 'JahresBeitragNetto',
        options: myDefaultHiddenOptions,
      },
      {
        name: 'Antragsdatum',
        label: 'Antragsdatum',
        options: myDefaultHiddenOptions,
      },
      /* {
        name: 'LogoURL',
        label: 'LogoURL',
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRenderLite: (dataIndex, rowIndex) => {
            return <img src={vertraege[dataIndex].LogoURL} alt="logo" />;
          },
        },
      }, */
    ];
  }
  let options = {};
  if (loginData.agent) {
    options = {
      filterType: 'textfield',
      caseSensitive: true,
      textLabels: muiTableTextLabels,
      selectableRows: false,
      selectableRowsOnClick: false,
    };
  } else {
    options = {
      filterType: 'textfield',
      caseSensitive: true,
      textLabels: muiTableTextLabels,
      selectableRows: false,
      selectableRowsOnClick: false,
      filter: false,
      download: false,
      print: false,
      viewColumns: false,
    };
  }

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <Spinner name="line-scale" color={stylingData.colors.color3} />
      </Backdrop>
      <Grid container>
        <Grid item xl={12} lg={12} md={12}>
          <MUIDataTable
            title={'Liste der Verträge'}
            data={vertraege}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
    </main>
  );
}
