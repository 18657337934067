import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ApellaContext } from '../context/ApellaContext';
import { LoginContext } from '../context/LoginContext';
import dompurify from 'dompurify';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));
const Home = () => {
  const classes = useStyles();
  const data = useContext(ApellaContext);
  const loginData = useContext(LoginContext);

  function createMarkup() {
    const sanitizer = dompurify.sanitize;
    return { __html: sanitizer(`${data.agendaText}`) };
  }

  return (
    <ApellaContext.Consumer>
      {(context) => (
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Typography variant="h4">
            Willkommen,{' '}
            {loginData.agent
              ? `${context.agentData.agentData.firstName} ${context.agentData.agentData.lastName}`
              : loginData.client
              ? `${context.clientData.clientData.firstName} ${context.clientData.clientData.lastName}`
              : `${loginData.user.firstName} ${loginData.user.lastName}`}
          </Typography>
          <Typography paragraph>
            <br></br>
            {`Anlass: ${data.roomData.consultationTitle}`}
            <br></br>
          </Typography>
          <Typography paragraph>
            Uhrzeit: {context.roomData.consultationTime} Uhr <br></br>
            Datum: {context.roomData.consultationDate.split('-')[0]}
          </Typography>
          <div dangerouslySetInnerHTML={createMarkup()}></div>
        </main>
      )}
    </ApellaContext.Consumer>
  );
};

export default Home;
