import React, { useState, useEffect, useContext, Fragment } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Fab,
  Divider,
  Zoom,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CloseIcon from '@material-ui/icons/Close';
import { ApellaContext } from '../../../../context/ApellaContext';
import { v4 as uuidv4 } from 'uuid';

import { useQuery, useMutation } from '@apollo/client';
import { getPoll } from '../../../../graphql/queries';
import { createPoll, deletePoll } from '../../../../graphql/mutations';
import { PollContext } from '../../../../context/PollContext';

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex' },
  title: {
    width: '100%',
  },
  /* firstAnswer: { width: '100%' },
  answer: { width: '100%' }, */
  addButton: {
    alignSelf: 'flex-end',
  },
  saveButton: {
    marginRight: 'auto',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogContent: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

const EditPoll = ({ openEditPoll, handleOpenEditPollDialog, pollIdToEdit, setPollIdToEdit }) => {
  useQuery(getPoll, {
    fetchPolicy: 'no-cache',
    variables: { id: pollIdToEdit },
    onCompleted: (data) => {
      let tempLoadedAnswers = [];
      data.getPoll.answers.forEach((answer) => {
        tempLoadedAnswers.push({ id: answer.id, answerText: answer.answerText, vote: null });
      });
      setLoadedPoll(data.getPoll);
      setLoadedAnswers(tempLoadedAnswers);
      setLoadedPollQuestion(data.getPoll.question);
      setLoadedMultipleAnswer(data.getPoll.multipleAnswer);
      setLoadedKindOfChart(data.getPoll.kindOfChart);
    },
  });
  const theme = useTheme();
  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen * 1.5,
    exit: theme.transitions.duration.leavingScreen * 1.5,
  };
  const classes = useStyles();
  const apellacontextData = useContext(ApellaContext);
  const pollContextData = useContext(PollContext);
  const [loadedPoll, setLoadedPoll] = useState(null);
  const [loadedAnswers, setLoadedAnswers] = useState(null);
  const [loadedPollQuestion, setLoadedPollQuestion] = useState(null);
  const [textfieldArray, settextfieldArray] = useState([]);
  const [loadedMultipleAnswer, setLoadedMultipleAnswer] = useState(null);
  const [loadedKindOfChart, setLoadedKindOfChart] = useState('');
  const [addPoll, resultPoll] = useMutation(createPoll); // eslint-disable-line
  const [removePoll, resultDeletePoll] = useMutation(deletePoll); // eslint-disable-line

  const handlePollInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name; //answer.id
    const id = target.id; //array index
    let tempLoadedAnswers = [...loadedAnswers];
    switch (id) {
      case 'pollQuestion':
        setLoadedPollQuestion({ id: loadedPollQuestion.id, questiontext: value });
        break;
      case target.id:
        tempLoadedAnswers[id] = {
          answerText: value,
          id: name,
          vote: null,
        };
        setLoadedAnswers(tempLoadedAnswers);
        break;
      default:
        tempLoadedAnswers.push({
          answerText: value,
          id: name,
          vote: null,
        });
        setLoadedAnswers(tempLoadedAnswers);
        break;
    }
  };

  const removeTextfield = () => {
    let tempLoadedAnswersArray = [...loadedAnswers];
    tempLoadedAnswersArray.pop();
    setLoadedAnswers(tempLoadedAnswersArray);
  };

  const addNewTextfields = () => {
    settextfieldArray([
      ...textfieldArray,

      <DialogContent>
        <TextField
          fullWidth
          id={textfieldArray.length}
          name={uuidv4()}
          label="Antwort"
          variant="outlined"
          className={classes.answer}
          onChange={handlePollInputChange}
          size="small"
        />
      </DialogContent>,
    ]);
  };

  useEffect(() => {
    let tempArray = [];
    if (loadedAnswers) {
      loadedAnswers.forEach((answer, index) => {
        tempArray.push(
          <DialogContent>
            <TextField
              fullWidth
              required
              id={index}
              name={answer.id}
              label="Antwort"
              variant="outlined"
              className={classes.firstAnswer}
              onChange={handlePollInputChange}
              size="small"
              defaultValue={answer.answerText}
            />
          </DialogContent>
        );
      });
      settextfieldArray(tempArray);
    }
  }, [loadedAnswers]); // eslint-disable-line

  const handelUpdatePoll = async () => {
    await addPoll({
      variables: {
        input: {
          ownerPollsId: apellacontextData.agentData.id,
          kindOfChart: loadedKindOfChart,
          title: 'Vielleicht später',
          multipleAnswer: loadedMultipleAnswer,
          question: { id: loadedPollQuestion.id, questionText: loadedPollQuestion.questionText },
          questionID: loadedPollQuestion.id,
          stage: apellacontextData.stage,
          answers: loadedAnswers,
        },
      },
    });
    await removePoll({ variables: { input: { id: pollIdToEdit } } });
    pollContextData.setReloadPolls(true);
    handleOpenEditPollDialog();
  };

  return (
    <Fragment>
      <Dialog open={openEditPoll} scroll="body" className={classes.dialogContent}>
        {loadedPoll ? (
          <Grid container lg={12} md={12} sm={12}>
            <Grid item lg={12} md={12} sm={12}>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => handleOpenEditPollDialog()}
              >
                <CloseIcon />
              </IconButton>
              <DialogTitle>Editieren der Umfrage</DialogTitle>
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
              <Divider />
            </Grid>

            <Grid item lg={12} md={12} sm={12}>
              <DialogContent>
                <TextField
                  required
                  id="pollQuestion"
                  name="pollQuestion"
                  label="Frage"
                  multiline
                  variant="outlined"
                  className={classes.title}
                  onChange={handlePollInputChange}
                  size="small"
                  defaultValue={loadedPoll.question.questionText}
                />
              </DialogContent>
            </Grid>

            {textfieldArray.map((element) => (
              <Grid item lg={12} md={12} sm={12}>
                {element}
              </Grid>
            ))}
            <Grid item lg={12} md={12} sm={12}>
              <DialogActions>
                <FormControlLabel
                  control={
                    <Switch
                      checked={loadedMultipleAnswer}
                      onChange={() => setLoadedMultipleAnswer((prev) => !prev)}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Mehrfachantwort möglich"
                />
              </DialogActions>
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
              <DialogActions>
                <Button
                  disableElevation
                  color="primary"
                  variant="contained"
                  className={classes.saveButton}
                  onClick={() => handelUpdatePoll()}
                >
                  Speichern
                </Button>
                {loadedAnswers && loadedAnswers.length > 0 ? (
                  <Zoom in={true} timeout={transitionDuration} unmountOnExit>
                    <Fab color="secondary" aria-label="remove" onClick={() => removeTextfield()}>
                      <RemoveIcon />
                    </Fab>
                  </Zoom>
                ) : null}

                <Zoom in={true} timeout={transitionDuration} unmountOnExit>
                  <Fab
                    color="primary"
                    aria-label="add"
                    onClick={() => {
                      addNewTextfields();
                    }}
                  >
                    <AddIcon />
                  </Fab>
                </Zoom>
              </DialogActions>
            </Grid>
          </Grid>
        ) : null}
      </Dialog>
      {console.log('loadedAnswers', loadedAnswers)}
    </Fragment>
  );
};

export default EditPoll;
