import { gql } from '@apollo/client';
export const createPoll = gql`
  mutation CreatePoll($input: CreatePollInput!) {
    createPoll(input: $input) {
      id
      ownerPollsId
    }
  }
`;

export const createOwner = gql`
  mutation CreateOwner($input: CreateOwnerInput!) {
    createOwner(input: $input) {
      id
    }
  }
`;

export const deletePoll = gql`
  mutation DeletePoll($input: DeletePollInput!) {
    deletePoll(input: $input) {
      id
    }
  }
`;

export const updatePoll = gql`
  mutation UpdatePoll($input: UpdatePollInput!) {
    updatePoll(input: $input) {
      id
    }
  }
`;

export const createRunningPoll = gql`
  mutation CreateRunningPoll($input: CreateRunningPollInput!) {
    createRunningPoll(input: $input) {
      id
      pollResultId
    }
  }
`;

export const deleteRunningPoll = gql`
  mutation DeleteRunningPoll($input: DeleteRunningPollInput!) {
    deleteRunningPoll(input: $input) {
      id
    }
  }
`;

export const createPollResult = gql`
  mutation CreatePollResult($input: CreatePollResultInput!) {
    createPollResult(input: $input) {
      id
    }
  }
`;

export const createVote = gql`
  mutation CreateVote($input: CreateVoteInput!) {
    createVote(input: $input) {
      answer {
        answerText
        id
        vote
      }
      id
      pollResultVotesId
      runningPollId
      runningPollVotesId
      updatedAt
      votePollUserId
      pollUser {
        firstName
        id
        lastName
        pollUserVoteId
        runningPollPollUsersId
      }
    }
  }
`;

export const updateVote = gql`
  mutation UpdateVote($input: UpdateVoteInput!) {
    updateVote(input: $input) {
      answer {
        answerText
        id
        vote
      }
      id
      pollResultVotesId
      runningPollId
      runningPollVotesId
      updatedAt
      votePollUserId
      pollUser {
        firstName
        id
        lastName
        pollUserVoteId
        runningPollPollUsersId
      }
    }
  }
`;

export const createPollUser = gql`
  mutation CreatePollUser($input: CreatePollUserInput!) {
    createPollUser(input: $input) {
      id
      firstName
      lastName
    }
  }
`;
