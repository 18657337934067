import React, { Fragment, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LoggingContext } from '../context/LoggingContext';
import { LoginContext } from '../context/LoginContext';
import { UrlContext } from '../context/UrlContext';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import CreateIcon from '@material-ui/icons/Create';

import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';
import Slide from '@material-ui/core/Slide';
import RemoveIcon from '@material-ui/icons/Remove';
import FindInPageIcon from '@material-ui/icons/FindInPage';

import DropzoneButton from './DropzoneButton';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  buttonContainer: {
    allignItems: 'stretch',
    alignContent: 'center',
    flexWrap: 'nowrap',
  },
  buttonItems: {
    height: '80%',
    flexGrow: 1,
  },
}));
const DocumentButtons = (props) => {
  const classes = useStyles();
  const loggingData = useContext(LoggingContext);
  const loginContext = useContext(LoginContext);
  const urlData = useContext(UrlContext);
  const [zoomInButtons, setzoomInButtons] = useState(false);
  const [addIcon, setaddIcon] = useState(true);
  const [openDropzone, setopenDropzone] = useState(false);

  const handleZoomin = () => {
    setzoomInButtons((prev) => !prev);
  };

  const handleAddIcon = () => {
    setaddIcon((prev) => !prev);
  };

  const handleDropzone = () => {
    setopenDropzone((prev) => !prev);
  };

  return (
    <Fragment>
      <Grid
        container
        spacing={1}
        xl={12}
        lg={12}
        md={12}
        justifyContent="left"
        alignContent="flex-start"
        alignItems="center"
      >
        <Grid item xl={1} lg={1} md={1}>
          <Zoom in={addIcon} unmountOnExit trans timeout={{ appear: 500, enter: 500, exit: 0 }}>
            <Fab
              color="primary"
              onClick={() => {
                handleZoomin();
                handleAddIcon();
              }}
            >
              <AddIcon />
            </Fab>
          </Zoom>

          <Zoom in={!addIcon} unmountOnExit timeout={{ appear: 500, enter: 500, exit: 0 }}>
            <Fab
              color="secondary"
              onClick={() => {
                handleZoomin();
                handleAddIcon();
              }}
            >
              <RemoveIcon />
            </Fab>
          </Zoom>
        </Grid>
        <Grid container xl={8} lg={8} md={8} className={classes.buttonContainer} spacing={1}>
          {zoomInButtons ? (
            <Fragment>
              <Grid item xl={3} lg={3} md={2}>
                <Zoom in={zoomInButtons} timeout={{ appear: 500, enter: 500, exit: 500 }}>
                  <Slide direction="right" in={zoomInButtons} mountOnEnter unmountOnExit>
                    <Button
                      className={classes.buttonItems}
                      disableElevation
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        loggingData.setSequence({ type: 'openDropzone' });
                        handleDropzone();
                      }}
                      startIcon={<AddIcon />}
                    >
                      lokales Dokument hochladen
                    </Button>
                  </Slide>
                </Zoom>
              </Grid>
              <Grid item xl={4} lg={4} md={3}>
                <Zoom
                  in={zoomInButtons}
                  style={{ transitionDelay: zoomInButtons ? '500ms' : '0ms' }}
                >
                  <Button
                    className={classes.buttonItems}
                    disableElevation
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      props.setopenPolicySystemDocumentstable(true);
                      loggingData.setSequence({
                        type: 'openPolicySystemDocumentstable_preload',
                      });
                    }}
                    startIcon={<AddIcon />}
                  >
                    <Typography variant="body2">
                      Aus {urlData.urlList.policySystem} importieren
                    </Typography>
                  </Button>
                </Zoom>
              </Grid>
            </Fragment>
          ) : null}
          {loginContext.agent && props.originSignpage ? (
            <Fragment>
              <Grid item xl={3} lg={3} md={3}>
                <Button
                  className={classes.buttonItems}
                  disableElevation
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    props.handleSign('sign');
                  }}
                  startIcon={<CreateIcon />}
                >
                  <Typography variant="body2">Dokumente unterschreiben</Typography>
                </Button>
              </Grid>
              <Grid item xl={3} lg={3} md={3}>
                <Button
                  className={classes.buttonItems}
                  disableElevation
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    props.handleSign('sessions');
                  }}
                  startIcon={<FindInPageIcon />}
                >
                  <Typography variant="body2">Vorgangsverwaltung</Typography>
                </Button>
              </Grid>
            </Fragment>
          ) : null}
        </Grid>
      </Grid>
      {openDropzone ? (
        <DropzoneButton
          send={props.send}
          openDropzone={openDropzone}
          setopenDropzone={setopenDropzone}
        />
      ) : null}
    </Fragment>
  );
};

export default DocumentButtons;
