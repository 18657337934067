import React, { Fragment, useContext, useState, useEffect, useReducer } from 'react';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { PieChart, Pie, Bar, BarChart } from 'recharts';
import { Dialog } from '@material-ui/core';
import EditPoll from './dialogues/EditPoll';
import { Tooltip } from '@material-ui/core';
import DeletePoll from '../../dialogues/DeletePoll';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { ApellaContext } from '../../../context/ApellaContext';
import { StylingContext } from '../../../context/StylingContext';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Grid, ListItem, ListItemText, List } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ShowPoll from '../../dialogues/ShowPoll';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import CreatePolls from '../CreatePolls';

/**
 * Importe für Umfragen
 */
import { useQuery, useMutation } from '@apollo/client';
import { listPolls } from '../../../graphql/queries';
import { createRunningPoll, deletePoll, deleteRunningPoll } from '../../../graphql/mutations';
import { PollContext } from '../../../context/PollContext';

const useStyles = makeStyles((theme) => ({
  pie: {
    '&:hover': { cursor: 'pointer' },
  },
  barchart: {
    '&:hover': { cursor: 'pointer' },
  },
  root: {
    maxWidth: 200,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 15,
  },
  pos: {
    marginBottom: 12,
  },
  charts: {
    width: 50,
  },
  chartcontainer: { display: 'flex', justifyContent: 'center' },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const ListPolls = ({
  origin,
  handleStartPoll,
  handleStopPoll,
  handleShowPollResults,
  stopShowPollResults,
}) => {
  const stylingData = useContext(StylingContext);
  const contextData = useContext(ApellaContext);
  const pollContextData = useContext(PollContext);
  const classes = useStyles();
  const [deletePollDialog, setDeletePollDialog] = useState(false);
  const [pollToDelete, setPollToDelete] = useState('');
  const [color, setcolor] = useState(stylingData.colors.color2); // eslint-disable-line
  const [color1, setcolor1] = useState(stylingData.colors.color2); // eslint-disable-line
  const [expanded, setExpanded] = useState([]);
  const [startVote, setStartVote] = useState(false);
  const listedPolls = useQuery(listPolls, {
    variables: {
      filter: {
        ownerPollsId: { contains: contextData.agentData.id },
        stage: { contains: contextData.stage },
      },
    },
  });
  const [removePoll, resultDeletePoll] = useMutation(deletePoll); // eslint-disable-line
  const [createTheRunningPoll, resultCreateTheRunningPoll] = useMutation(createRunningPoll); // eslint-disable-line
  const [deleteTheRunningPoll, resultDeleteTheRunningPoll] = useMutation(deleteRunningPoll); // eslint-disable-line
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0); // eslint-disable-line
  const [openShowPollDialog, setOpenShowPollDialog] = useState(false);
  const [pollIdToRun, setpollIdToRun] = useState('');
  const [pollIdToEdit, setPollIdToEdit] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [openDesignPoll, setopenDesignPoll] = useState(false);
  const [openEditPoll, setOpenEditPoll] = useState(false);
  const [pollResultId, setpollResultId] = useState(uuidv4());

  const startPoll = async (id) => {
    setStartVote(true);
    setpollIdToRun(id);
    try {
      await createTheRunningPoll({
        variables: { input: { id: id, runningPollPollId: id, pollResultId: pollResultId } },
        onCompleted: (data) => {
          handleStartPoll(data.createRunningPoll.id, data.createRunningPoll.pollResultId);
        },
      });
      setOpenShowPollDialog(true);
    } catch (error) {
      enqueueSnackbar('Ein Fehler beim Starten der Umfrage ist aufgetreten!', {
        variant: 'error',
      });
      console.log(error);
      try {
        await deleteTheRunningPoll({ variables: { input: { id: pollIdToRun } } });
      } catch (err) {
        enqueueSnackbar('Ein Fehler beim Starten der Umfrage ist aufgetreten!', {
          variant: 'error',
        });
        console.log(err);
      }
    }
  };
  const handleDesignPollDialog = () => {
    setopenDesignPoll((prev) => !prev);
  };

  useEffect(() => {
    if (pollContextData.reloadPolls) {
      listedPolls.refetch();
      pollContextData.setReloadPolls(false);
    }
    return () => {
      pollContextData.setReloadPolls(false);
    };
  }, [pollContextData.reloadPolls]); // eslint-disable-line

  const deleteThePoll = async (id) => {
    await removePoll({ variables: { input: { id: id } } });
    await listedPolls.refetch();
  };

  useEffect(() => {
    if (listedPolls?.data?.listPolls?.items) {
      let tempExpandedArray = [];
      for (let i = 0; i < listedPolls.data.listPolls.items.length; i++) {
        tempExpandedArray.push(false);
      }

      setExpanded(tempExpandedArray);
    }
    return () => {
      setExpanded([]);
    };
  }, [listedPolls?.data]);

  const calculateFakeData = (answers) => {
    let chartData = Array.from(answers).map((element, index) => ({
      name: element[1],
      value: (index + 1) * 123456,
    }));
    return chartData;
  };

  const handleExpandCardClick = (index) => {
    let tempExpandedArray = expanded;
    tempExpandedArray[index] = !tempExpandedArray[index];
    setExpanded(tempExpandedArray);
    forceUpdate();
    tempExpandedArray = [];
  };

  const handleOpenEditPollDialog = () => {
    setOpenEditPoll((prev) => !prev);
  };

  const renderBarChart = (id, color, title, answers, fakeData, index, multipleAnswer) => {
    return (
      <BarChart width={75} height={60} data={fakeData} onClick={() => {}}>
        <Bar
          dataKey="value"
          fill={color1}
          className={classes.barchart}
          onClick={() => {
            setPollIdToEdit(id);
            handleOpenEditPollDialog();
          }}
        />
      </BarChart>
    );
  };
  const renderPieChart = (id, color, title, answers, fakeData, index, multipleAnswer) => {
    return (
      <PieChart width={60} height={60} onClick={() => {}}>
        <Pie
          data={fakeData}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={30}
          fill={color}
          className={classes.pie}
          onClick={() => {
            setPollIdToEdit(id);
            handleOpenEditPollDialog();
          }}
        />
      </PieChart>
    );
  };

  const renderChart = (id, color, title, answers, fakeData, index, multipleAnswer, kindOfChart) => {
    return (
      <Card className={classes.root} variant="outlined">
        <Typography variant="h6" align="center">
          <CardHeader
            title={title}
            className={classes.title}
            disableTypography
            action={
              <Tooltip title="löschen" aria-label="loeschen">
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setPollToDelete(id);
                    setDeletePollDialog(true);
                  }}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Tooltip>
            }
          />
        </Typography>

        <CardContent className={classes.chartcontainer}>
          {kindOfChart === 'barchart'
            ? renderBarChart(id, color, title, answers, fakeData, index, multipleAnswer)
            : renderPieChart(id, color, title, answers, fakeData, index, multipleAnswer)}
        </CardContent>
        <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
          <List>
            <ListItem>
              <ListItemText>
                {multipleAnswer ? (
                  <Typography variant="caption" align="center">
                    Mehrfachantwort möglich
                  </Typography>
                ) : null}
              </ListItemText>
            </ListItem>
            {answers.map((answer) => {
              return (
                <ListItem key={answer.id}>
                  <ListItemText>
                    <Typography variant="body1">{answer.answerText}</Typography>
                  </ListItemText>
                </ListItem>
              );
            })}
            <ListItem>
              <Button
                disableElevation
                variant="contained"
                color="primary"
                onClick={() => {
                  setPollIdToEdit(id);
                  handleOpenEditPollDialog();
                }}
              >
                Abstimmung editieren
              </Button>
            </ListItem>
          </List>
        </Collapse>
        <CardActions disableSpacing>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded[index],
            })}
            onClick={() => handleExpandCardClick(index)}
            aria-expanded={expanded[index]}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        {origin === 'Preload' ? null : (
          <CardActions>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              onClick={() => {
                startPoll(id);
              }}
            >
              Abstimmung starten
            </Button>
          </CardActions>
        )}
      </Card>
    );
  };

  return (
    <Fragment>
      <Grid container spacing={2} alignItems="stretch" direction="row">
        {listedPolls?.data?.listPolls.items.map((poll, index) => {
          return (
            <Fragment>
              <Grid item>
                {renderChart(
                  poll.id,
                  color,
                  poll.question.questionText,
                  poll.answers,
                  calculateFakeData(poll.answers),
                  index,
                  poll.multipleAnswer,
                  poll.kindOfChart
                )}
              </Grid>
            </Fragment>
          );
        })}
      </Grid>
      {origin === 'Preload' ? null : (
        <Grid container spacing={2}>
          <Grid item>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              onClick={() => handleDesignPollDialog()}
            >
              neue Umfrage entwerfen
            </Button>
          </Grid>
        </Grid>
      )}
      {openDesignPoll ? (
        <Dialog open={openDesignPoll} onClose={() => handleDesignPollDialog()}>
          <CreatePolls handleDesignPollDialog={handleDesignPollDialog} />
        </Dialog>
      ) : null}
      {deletePollDialog ? (
        <DeletePoll
          deleteThePoll={deleteThePoll}
          setDeletePollDialog={setDeletePollDialog}
          deletePollDialog={deletePollDialog}
          pollToDelete={pollToDelete}
        />
      ) : null}
      {openShowPollDialog ? (
        <ShowPoll
          pollIdToRun={pollIdToRun}
          pollResultId={pollResultId}
          setpollResultId={setpollResultId}
          startVote={startVote}
          setStartVote={setStartVote}
          openShowPollDialog={openShowPollDialog}
          setOpenShowPollDialog={setOpenShowPollDialog}
          handleStopPoll={handleStopPoll}
          handleShowPollResults={handleShowPollResults}
          stopShowPollResults={stopShowPollResults}
        />
      ) : null}
      {openEditPoll ? (
        <EditPoll
          openEditPoll={openEditPoll}
          handleOpenEditPollDialog={handleOpenEditPollDialog}
          pollIdToEdit={pollIdToEdit}
          setPollIdToEdit={setPollIdToEdit}
        />
      ) : null}
    </Fragment>
  );
};

export default ListPolls;
